import * as React from "react";
import { useState } from 'react';

import * as styles from './modal.module.scss';

const ErrorModal = ({ modalErrorOpen, setErrorModalOpen }) => {

  const [fname, setFirstName] = useState("");
  const [lname, setLastName] = useState("");
  const [emailAddress, setEmail] = useState("");
  const [message, setMessage] = useState("");


  return (
    <div className={styles.modal + (modalErrorOpen ? ` ` + styles.hidden : '')}>

      <form style={{display: "none"}} id="error-form" name="error" method="POST" data-netlify="true" netlify-honeypot="bot-field">

        <p className="hidden">
          <label>
            Don’t fill this out if you’re human: <input name="bot-field" />
          </label>
        </p>

        <input type="hidden" name="subject" data-remove-prefix value="Error form entry from RMI - Green Upgrade Calculator" />

        <input name="body" />

        <input type="hidden" name="form-name" value="error" />               

        <button className="button contact-form-button" type="submit">Send</button>

      </form>

      <div className={styles.modalMain}>
        <section>
          <div className={styles.modalHeader}>
            <h2 className={styles.contactHeader}>There appears to be an error!</h2>
            <p>Please try again later or get in touch and our team will get in touch to help!</p>
            <button className={styles.modalClose} onClick={e => setErrorModalOpen(!modalErrorOpen)}>Close</button>
          </div>
          <div className={styles.modalContent}>
            <article>
              <form className={styles.modalForm} name="contact" method="POST" data-netlify="true" netlify-honeypot="bot-field">

                <p className="hidden">
                  <label>
                    Don’t fill this out if you’re human: <input name="bot-field" />
                  </label>
                </p>

                <input type="hidden" name="subject" data-remove-prefix value="Contact form entry from RMI - Green Upgrade Calculator" />
                
                <label>First Name <input type="text" name="firstname" /></label>                
                
                <label>Last Name <input type="text" name="lastname" /></label>                
                
                <label>Email Address <input type="email" name="email" /></label>                
                
                <label>Message <textarea name="message"></textarea></label> 

                <input type="hidden" name="form-name" value="contact" />               
                
                <button className="button contact-form-button" type="submit">Send</button>
                
              </form>

            </article>
          </div>
        </section>
      </div>
    </div>
  );
};

export default ErrorModal