import houseUpgrades from './../../images/home.svg';
import transportationUpgrades from './../../images/car.svg';

const GreenUpgrades = {
    pageName: 'Get Started',
    sections: {
        energyInterestedIn: {
            dropdowns: [
                {
                    title: "Home Upgrades",
                    image: houseUpgrades,
                    hideifoptions: {
                        'mode': {
                            type: 'in',
                            values: [
                                'Transportation (TEMPEST-T)'
                            ]
                        }
                    },
                    inputs: [
                        {
                            title: 'Renewable Energy',
                            subtitle: true
                        },
                        {
                            input: {
                                name: 'solar',
                                type: 'checkbox',
                                storage: 'single-value',
                                values: [
                                    {
                                        title: 'Install Rooftop Solar Panels',
                                        value: 'Yes',
                                        tippyText: 'Rooftop solar panels can power the electric home appliances and electric vehicles with renewable electricity. <a href="https://www.energy.gov/eere/solar/homeowners-guide-going-solar" target="__blank"> Learn more here </a>'
                                    }
                                ],
                                hideifoptions: {
                                    'mode': {
                                        type: 'notin',
                                        values: [
                                            'Home (TEMPEST-H)',
                                            'Home & Transportation (TEMPEST-X)'
                                        ]
                                    }
                                }
                            }
                        },
                        {
                            input: {
                                name: 'storage',
                                type: 'checkbox',
                                storage: 'single-value',
                                values: [
                                    {
                                        title: 'Install Battery Storage',
                                        value: 'Yes',
                                        tippyText: 'Battery storage systems can store excess energy produced by solar panels (i.e., when they produce more electricity than the home needs) and then discharge it when needed (e.g., during a blackout or peak hours when electricity is most expensive in locations with time-of-use pricing). <a href="https://www.solarunitedneighbors.org/learn-the-issues/solar-storage/" target="__blank"> Learn more here. </a>'
                                    }
                                ],
                                hideifoptions: {
                                    'mode': {
                                        type: 'notin',
                                        values: [
                                            'Home (TEMPEST-H)',
                                            'Home & Transportation (TEMPEST-X)'
                                        ]
                                    }
                                }
                            }
                        },
                        {
                            input: {
                                name: 'communitysolar',
                                type: 'checkbox',
                                storage: 'single-value',
                                values: [
                                    {
                                        title: 'Subscribe to Community Solar',
                                        value: 'Yes',
                                        tippyText: 'Subscribing to a local community solar project enables residents to enjoy the economic benefits from the share of the electricity the project generates. These programs are especially helpful for renters or those without a suitable roof or space for solar. <a href="https://www.solarunitedneighbors.org/learn-the-issues/community-solar/" target="__blank">Learn more here.'
                                    }
                                ],
                                hideifoptions: {
                                    'mode': {
                                        type: 'notin',
                                        values: [
                                            'Home (TEMPEST-H)',
                                            'Home & Transportation (TEMPEST-X)'
                                        ]
                                    },
                                    'active-solar-project': {
                                        type: 'false'
                                    }
                                }
                            }
                        },
                        {
                            title: 'Energy Efficiency and Electrification',
                            subtitle: true
                        },
                        {
                            input: {
                                name: 'wthz',
                                type: 'checkbox',
                                storage: 'single-value',
                                values: [
                                    {
                                        title: 'Weatherize Home',
                                        value: 'Yes',
                                        tippyText: 'Weatherizing a home makes it more efficient by reducing heating and cooling energy consumption and utility bills while enhancing occupant comfort. <a href="https://www.energy.gov/energysaver/weatherization" target="__blank">Learn more here. </a>'
                                    }
                                ],
                                hideifoptions: {
                                    'mode': {
                                        type: 'notin',
                                        values: [
                                            'Home (TEMPEST-H)',
                                            'Home & Transportation (TEMPEST-X)'
                                        ]
                                    }
                                }
                            }
                        },
                        {
                            title: 'What type of weatherization do you want to analyze? Select all that apply.',
                            child: true,
                            hidetitle: true,
                            input: {
                                name: 'wthz_new',
                                type: 'checkbox',
                                class: 'horizontal',
                                hideifoptions: {
                                    'wthz': {
                                        type: 'notin',
                                        values: [
                                            'Yes'
                                        ]
                                    }
                                },
                                values: [
                                    {
                                        title: 'Add Insulation',
                                        value: 'Adding insulation',
                                        tippyText: 'Adding or improving the insulation in the walls, ceiling, or attic of the home can reduce the heating and cooling energy costs. <a href="https://www.energy.gov/energysaver/insulation" target="__blank">Learn more here. </a>'
                                    },
                                    {
                                        title: 'Seal Air Leaks',
                                        value: 'sealing air leaks',
                                        tippyText: 'Caulking, weather stripping, and sealing other drafts can reduce air leakage from the home. <a href="https://www.energy.gov/energysaver/air-sealing-your-home" target="__blank">Learn more here.</a>'
                                    },
                                    {
                                        title: 'Replace Windows',
                                        value: 'replacing windows',
                                        tippyText: 'Replacing older windows with new, more energy-efficient ones can improve their ability to maintain comfortable indoor temperatures. <a href="https://www.energy.gov/energysaver/update-or-replace-windows"target="__blank">Learn more here.</a>'
                                    }
                                ]
                            }
                        },
                        {
                            input: {
                                name: 'ashp',
                                type: 'checkbox',
                                storage: 'single-value',
                                values: [
                                    {
                                        title: 'Install an Electric Air-Source Heat Pump', 
                                        value: 'Yes',
                                        tippyText: 'Electric air-source heat pumps are appliances that provide superefficient cooling and heating. Essentially acting as air conditioners that reverse cycle in the winter to provide heating, they can replace both the air-conditioning and heating systems. <a href="https://rmi.org/clean-energy-101-heat-pumps/" target="__blank">Learn more here.</a> '
                                    }
                                ],
                                hideifoptions: {
                                    'mode': {
                                        type: 'notin',
                                        values: [
                                            'Home (TEMPEST-H)',
                                            'Home & Transportation (TEMPEST-X)'
                                        ]
                                    }
                                }
                            }
                        },
                        {
                            title: 'What type of air-source heat pump do you want to analyze?',
                            child: true,
                            secondary: true,
                            tippyText: 'Ducted heat pumps are designed for homes with existing ducting and commonly replace an existing air conditioner and furnace. Ductless, or "mini-split," heat pumps are designed for homes without ducting and commonly replace or supplement window air conditioners and boilers or baseboard heating systems. <a href="https://www.energy.gov/energysaver/air-source-heat-pumps" target="__blank">Learn more here.</a>',
                            input: {
                                name: 'ashpducting',
                                type: 'select',
                                values: [
                                    {
                                        title: 'Ducted Central',
                                        value: 'Ducted Central'
                                    },
                                    {
                                        title: 'Ductless Mini Split',
                                        value: 'Ductless Mini Split'
                                    }
                                ],
                                hideifoptions: {
                                    'ashp': {
                                        type: 'notin',
                                        values: [
                                            'Yes'
                                        ]
                                    }
                                }
                            }
                        },
                        {
                            title: 'Do you want the heat pump to meet the home\’s full heating needs (whole-home heat pump) or partial heating needs (hybrid heat pump)?',
                            child: true,
                            secondary: true,
                            tippyText: 'A whole-home heat pump is typically sized to meet the home’s heating loads and in colder climates includes electric resistance for any supplemental heat needs. A hybrid heat pump is typically sized to meet the home’s cooling load, replacing the existing cooling system, and uses the home’s existing heating fuel source (e.g., natural gas) for any supplemental heat needs. For ducted heat pumps, the fossil fuel source is integrated within the heat pump’s air handler (negating the need for a separate furnace cost) and for ductless mini-split heat pumps the home’s existing fossil fuel heating system is used and maintained. Hybrid heat pumps are more common in colder climates with homes currently heated with natural gas.',
                            input: {
                                name: 'spaceheatspmt_cleanertech',
                                type: 'select',
                                values: [
                                    {
                                        title: 'Whole-Home Heat Pump',
                                        value: 'Whole-Home Heat Pump'
                                    },
                                    {
                                        title: 'Hybrid Heat Pump',
                                        value: 'Hybrid Heat Pump'
                                    }
                                ],
                                hideifoptions: {
                                    'ashp': {
                                        type: 'notin',
                                        values: [
                                            'Yes'
                                        ]
                                    },
                                    'zip-greater-than-four': {
                                        type: 'false'
                                    }
                                }
                            }
                        },
                        {
                            input: {
                                name: 'hpwh',
                                type: 'checkbox',
                                storage: 'single-value',
                                values: [
                                    {
                                        title: 'Install an Electric Heat Pump Water Heater',
                                        value: 'Yes',
                                        tippyText: 'An electric heat pump water heater, also known as a hybrid water heater, has two primary elements: (1) a heat pump in the top of the tank to use heat from the surrounding air to heat water, and (2) an electric resistance element in the tank for any additional water-heating needs. These systems are superefficient, typically using less than a third of the amount of energy to operate than standard water heaters, and they make basements less damp. <a href=" https://www.energy.gov/energysaver/heat-pump-water-heaters" target="__blank">Learn more here.</a>'
                                    }
                                ],
                                hideifoptions: {
                                    'mode': {
                                        type: 'notin',
                                        values: [
                                            'Home (TEMPEST-H)',
                                            'Home & Transportation (TEMPEST-X)'
                                        ]
                                    }
                                }
                            }
                        },
                        {
                            input: {
                                name: 'range',
                                type: 'checkbox',
                                storage: 'single-value',
                                values: [
                                    {
                                        title: 'Install an Electric Cooking Appliance',
                                        value: 'Yes',
                                        tippyText: 'Electric cooking appliances reduce indoor air pollution and health risks in homes (nearly 13% of childhood asthma cases in the United States are linked to residential gas stoves). Induction stovetops are an increasingly popular electric cooking option that are cleaner, safer, more efficient, and cook faster than alternatives. <a href="https://rmi.org/gas-stoves-health-climate-asthma-risk/" target="__blank"> Learn more here. </a>'
                                    }
                                ],
                                hideifoptions: {
                                    'mode': {
                                        type: 'notin',
                                        values: [
                                            'Home (TEMPEST-H)',
                                            'Home & Transportation (TEMPEST-X)'
                                        ]
                                    }
                                }
                            }
                        },
                        {
                            title: 'What type of cooking appliance do you want to analyze?',
                            child: true,
                            secondary: true,
                            input: {
                                name: 'cooking_cleaner',
                                type: 'select',
                                storage: 'single-value',
                                hideifoptions: {
                                    'range': {
                                        type: 'notin',
                                        values: [
                                            'Yes'
                                        ]
                                    }
                                },
                                values: [
                                    {
                                        title: 'Electric Induction Range',
                                        value: 'Electric Induction Range'
                                    },
                                    {
                                        title: 'Electric Induction Stovetop',
                                        value: 'Electric Induction Stovetop'
                                    },
                                    {
                                        title: 'Electric Induction Stovetop & Electric Wall Oven',
                                        value: 'Electric Induction Stovetop & Electric Wall Oven'
                                    },
                                    {
                                        title: 'Electric Resistance Range',
                                        value: 'Electric Resistance Range'
                                    },
                                    {
                                        title: 'Electric Resistance Stovetop',
                                        value: 'Electric Resistance Stovetop'
                                    },
                                    {
                                        title: 'Electric Resistance Stovetop & Electric Wall Oven',
                                        value: 'Electric Resistance Stovetop & Electric Wall Oven'
                                    },
                                    {
                                        title: 'Electric Wall Oven',
                                        value: 'Electric Wall Oven'
                                    }
                                ]
                            }
                        },
                        {
                            title: 'Other Upgrades',
                            subtitle: true
                        },
                        {
                            input: {
                                name: 'additional_home_upgrade',
                                type: 'checkbox',
                                storage: 'single-value',
                                values: [
                                    {
                                        title: 'Install an Additional Home Upgrade',
                                        value: 'Yes',
                                    }
                                ],
                            }
                        },
                        {
                            title: 'How do you want to install additional home upgrades? Select all that apply.',
                            child: true,
                            hidetitle: true,
                            input: {
                                name: 'additional_home_upgrade_new',
                                type: 'checkbox',
                                class: 'horizontal',
                                hideifoptions: {
                                    'additional_home_upgrade': {
                                        type: 'notin',
                                        values: [
                                            'Yes'
                                        ]
                                    }
                                },
                                values: [
                                    {
                                        title: 'Electrical Panel Upgrade',
                                        value: 'Electrical Panel Upgrade',
                                        tippyText: 'Electrifying home appliances or vehicles increases the peak electrical capacity that flows through the home electrical panel. If the electrical panel has a capacity of 100 amps or less, the homeowner may need to consult an electrical contractor to decide if they need to upgrade to a larger amperage panel or use a load-sharing device. <a href="https://www.energystar.gov/about/federal_tax_credits/electric_panel_upgrade" target="__blank">Learn more here.</a>'
                                    },
                                    {
                                        title: 'Roof Replacement',
                                        value: 'Roof Replacement',
                                        tippyText: 'Roof replacements are sometimes needed when installing rooftop solar panels, depending on the roof\’s condition.'
                                    },
                                    {
                                        title: 'Health and Safety Upgrade',
                                        value: 'Health and Safety Upgrade',
                                        tippyText: 'Any existing health and safety hazards, such as the presence of asbestos, lead-based paint, or mold, are typically required to be addressed if the home upgrade interacts with these hazards.'
                                    }
                                ]
                            }
                        },
                    ]
                },
                {
                    title: "Transportation Upgrades",
                    image: transportationUpgrades,
                    hideifoptions: {
                        'mode': {
                            type: 'in',
                            values: [
                                'Home (TEMPEST-H)'
                            ]
                        }
                    },
                    inputs: [
                        {
                            title: 'Select one of the following green alternatives if you would like to compare their impacts versus purchasing a new gasoline vehicle.',
                            subtitle: true
                        },
                        {
                            input: {
                                name: 'buy-electric-car',
                                type: 'checkbox',
                                storage: 'single-value',
                                values: [
                                    {
                                        title: 'Buy an Electric Vehicle',
                                        value: 'Yes',
                                        tippyText: 'A battery electric vehicle fully relies on electric power for transportation, while a plug-in hybrid vehicle utilizes both a rechargeable battery pack and an internal combustion engine. Both battery electric and plug-in hybrid vehicles typically have lower fuel costs, maintenance expenses, and associated emissions. <a href="https://afdc.energy.gov/fuels/electricity_benefits.html" target="__blank">Learn more here.</a>'
                                    }
                                ],
                                hideifoptions: {
                                    'mode': {
                                        type: 'notin',
                                        values: [
                                            'Transportation (TEMPEST-T)',
                                            'Home & Transportation (TEMPEST-X)'
                                        ]
                                    }
                                },
                                disableifoptions: {
                                    'vehicle': {
                                        type: 'in',
                                        values: [
                                            'Yes: Car-Free'
                                        ]
                                    }
                                }
                            }
                        },
                        {
                            title: 'What is the make of the new electric vehicle you want to analyze?',
                            child: true,
                            secondary: true,
                            tippyText: 'Select the specific vehicle make and model to generate assumptions around expected costs and emissions. Otherwise, select the “Average” option associated with the body type and type of electric vehicle to analyze. Learn more about specific vehicle models, including estimated vehicle range, at <a href="https://plugstar.com/" target="__blank">plugstar.com</a>.',
                            input: {
                                name: 'phevmake',
                                mirrorValue: [
                                    'bevmake'
                                ],
                                type: 'select',
                                values: [{
                                    title: 'Average PHEV Hatchback',
                                    value: 'Average PHEV Hatchback'
                                },
                                {
                                    title: 'Average BEV Hatchback',
                                    value: 'Average BEV Hatchback'
                                },
                                {
                                    title: 'Average PHEV Sedan/Coupe',
                                    value: 'Average PHEV Sedan/Coupe'
                                },
                                {
                                    title: 'Average BEV Pick-up Truck',
                                    value: 'Average BEV Pick-up Truck'
                                },
                                {
                                    title: 'Average PHEV SUV',
                                    value: 'Average PHEV SUV'
                                },
                                {
                                    title: 'Average BEV Sedan/Coupe',
                                    value: 'Average BEV Sedan/Coupe'
                                },
                                {
                                    title: 'Average BEV SUV',
                                    value: 'Average BEV SUV'
                                },
                                {
                                    title: 'Audi',
                                    value: 'Audi'
                                },
                                {
                                    title: 'Bentley',
                                    value: 'Bentley'
                                },
                                {
                                    title: 'BMW',
                                    value: 'BMW'
                                },
                                {
                                    title: 'Cadillac',
                                    value: 'Cadillac'
                                },
                                {
                                    title: 'Chevrolet',
                                    value: 'Chevrolet'
                                },
                                {
                                    title: 'Chrysler',
                                    value: 'Chrysler'
                                },
                                {
                                    title: 'Dodge',
                                    value: 'Dodge'
                                },
                                {
                                    title: 'Fisker',
                                    value: 'Fisker'
                                },
                                {
                                    title: 'Ford',
                                    value: 'Ford'
                                },
                                {
                                    title: 'Genesis',
                                    value: 'Genesis'
                                },
                                {
                                    title: 'GMC',
                                    value: 'GMC'
                                },
                                {
                                    title: 'Hyundai',
                                    value: 'Hyundai'
                                },
                                {
                                    title: 'Jaguar',
                                    value: 'Jaguar'
                                },
                                {
                                    title: 'Jeep',
                                    value: 'Jeep'
                                },
                                {
                                    title: 'Kia',
                                    value: 'Kia'
                                },
                                {
                                    title: 'Land Rover',
                                    value: 'Land Rover'
                                },
                                {
                                    title: 'Lexus',
                                    value: 'Lexus'
                                },
                                {
                                    title: 'Lincoln',
                                    value: 'Lincoln'
                                },
                                {
                                    title: 'Lordstown',
                                    value: 'Lordstown'
                                },
                                {
                                    title: 'Lucid USA Inc.',
                                    value: 'Lucid USA Inc.'
                                },
                                {
                                    title: 'Mazda',
                                    value: 'Mazda'
                                },
                                {
                                    title: 'Mercedes-Benz',
                                    value: 'Mercedes-Benz'
                                },
                                {
                                    title: 'Mini',
                                    value: 'Mini'
                                },
                                {
                                    title: 'Mitsubishi',
                                    value: 'Mitsubishi'
                                },
                                {
                                    title: 'Nissan',
                                    value: 'Nissan'
                                },
                                {
                                    title: 'Polestar Automotive USA Inc',
                                    value: 'Polestar Automotive USA Inc'
                                },
                                {
                                    title: 'Porsche',
                                    value: 'Porsche'
                                },
                                {
                                    title: 'Rivian',
                                    value: 'Rivian'
                                },
                                {
                                    title: 'Subaru',
                                    value: 'Subaru'
                                },
                                {
                                    title: 'Tesla',
                                    value: 'Tesla'
                                },
                                {
                                    title: 'Toyota',
                                    value: 'Toyota'
                                },
                                {
                                    title: 'Vinfast',
                                    value: 'Vinfast'
                                },
                                {
                                    title: 'Volkswagen',
                                    value: 'Volkswagen'
                                },
                                {
                                    title: 'Volvo',
                                    value: 'Volvo'
                                },
                                ],
                                hideifoptions: {
                                    'buy-electric-car': {
                                        type: 'notin',
                                        values: [
                                            'Yes'
                                        ]
                                    }
                                }
                            }
                        },
                        {
                            title: 'What is the model of the new electric vehicle you want to analyze?',
                            child: true,
                            secondary: true,
                            input: {
                                name: 'phevmodel',
                                type: 'select',
                                mirrorValue: [
                                    'bevmodel'
                                ],
                                hideifoptions: {
                                    'phevmake': {
                                        type: 'notin',
                                        values: [
                                            'Audi',
                                            'Bentley',
                                            'BMW',
                                            'Chrysler',
                                            'Cadillac',
                                            'Dodge',
                                            'Fisker',
                                            'Ford',
                                            'Hyundai',
                                            'Chevrolet',
                                            'Jeep',
                                            'Genesis',
                                            'GMC',
                                            'Kia',
                                            'Hyundai',
                                            'Land Rover',
                                            'Jaguar',
                                            'Lexus',
                                            'Lincoln',
                                            'Lordstown',
                                            'Lucid USA Inc.',
                                            'Mini',
                                            'Mazda',
                                            'Mitsubishi',
                                            'Mercedes-Benz',
                                            'Porsche',
                                            'Toyota',
                                            'Nissan',
                                            'Volvo',
                                            'Polestar Automotive USA Inc',
                                            'Rivian',
                                            'Subaru',
                                            'Tesla',
                                            'Vinfast',
                                            'Volkswagen'
                                        ]
                                    },
                                    'buy-electric-car': {
                                        type: 'notin',
                                        values: [
                                            'Yes'
                                        ]
                                    }
                                },
                                values: (formData) => {
                                    if (!formData || !formData?.phevmake)
                                        return [];
                                    switch (formData?.phevmake) {
                                        case 'Average PHEV Hatchback': 
                                            return [
                                                {
                                                    title: 'Plug-in Hybrid Electric Vehicles'
                                                },
                                                {
                                                    title: 'Average PHEV Hatchback',
                                                    value: 'Average PHEV Hatchback'
                                                }
                                            ]
                                        case 'Average BEV Hatchback': 
                                            return [
                                                {
                                                    title: 'Battery Electric Vehicle'
                                                },
                                                {
                                                    title: 'Average BEV Hatchback',
                                                    value: 'Average BEV Hatchback'
                                                }
                                            ]
                                        case 'Average PHEV Sedan/Coupe': 
                                            return [
                                                {
                                                    title: 'Plug-in Hybrid Electric Vehicles'
                                                },
                                                {
                                                    title: 'Average PHEV Sedan/Coupe',
                                                    value: 'Average PHEV Sedan/Coupe'
                                                }
                                            ]
                                        case 'Average BEV Pick-up Truck': 
                                            return [
                                                {
                                                    title: 'Battery Electric Vehicle'
                                                },
                                                {
                                                    title: 'Average BEV Pick-up Truck',
                                                    value: 'Average BEV Pick-up Truck'
                                                }
                                            ]
                                        case 'Average PHEV SUV': 
                                            return [
                                                {
                                                    title: 'Plug-in Hybrid Electric Vehicles'
                                                },
                                                {
                                                    title: 'Average PHEV SUV',
                                                    value: 'Average PHEV SUV'
                                                },
                                                {
                                                    title: 'Battery Electric Vehicle'
                                                }
                                            ]
                                        case 'Average BEV Sedan/Coupe': 
                                            return [
                                                {
                                                    title: 'Battery Electric Vehicle'
                                                },
                                                {
                                                    title: 'Average BEV Sedan/Coupe',
                                                    value: 'Average BEV Sedan/Coupe'
                                                }
                                            ]
                                        case 'Average BEV SUV': 
                                            return [
                                                {
                                                    title: 'Battery Electric Vehicle'
                                                },
                                                {
                                                    title: 'Average BEV SUV',
                                                    value: 'Average BEV SUV'
                                                }
                                            ]
                                        case "Audi":
                                            return [
                                                {
                                                    title: 'Plug-in Hybrid Electric Vehicles'
                                                },
                                                {
                                                    title: 'A7 TFSI e quattro',
                                                    value: 'A7 TFSI e quattro'
                                                },
                                                {
                                                    title: 'Q5 TFSI e quattro',
                                                    value: 'Q5 TFSI e quattro'
                                                },
                                                {
                                                    title: 'Battery Electric Vehicle'
                                                },
                                                {
                                                    title: 'e-tron GT',
                                                    value: 'e-tron GT'
                                                },
                                                {
                                                    title: 'Q4 e-tron quattro',
                                                    value: 'Q4 e-tron quattro'
                                                },
                                                {
                                                    title: 'Q8 e-tron quattro',
                                                    value: 'Q8 e-tron quattro'
                                                },
                                                {
                                                    title: 'e-tron S',
                                                    value: 'e-tron S'
                                                },
                                                {
                                                    title: 'e-tron Sportback quattro',
                                                    value: 'e-tron Sportback quattro'
                                                },
                                                {
                                                    title: 'RS e-tron GT',
                                                    value: 'RS e-tron GT'
                                                },
                                                {
                                                    title: 'Q4 e-tron',
                                                    value: 'Q4 e-tron'
                                                }
                                            ]
                                        case 'Bentley': 
                                            return [
                                                {
                                                    title: 'Plug-in Hybrid Electric Vehicles'
                                                },
                                                {
                                                    title: 'Flying Spur Hybrid',
                                                    value: 'Flying Spur Hybrid'
                                                }
                                            ]
                                        case 'BMW': 
                                            return [
                                                {
                                                    title: 'Plug-in Hybrid Electric Vehicles'
                                                },
                                                {
                                                    title: '330e Sedan',
                                                    value: '330e Sedan'
                                                },
                                                {
                                                    title: '530e Sedan',
                                                    value: '530e Sedan'
                                                },
                                                {
                                                    title: '745e xDrive',
                                                    value: '745e xDrive'
                                                },
                                                {
                                                    title: 'X5 xDrive45e',
                                                    value: 'X5 xDrive45e'
                                                },
                                                {
                                                    title: 'XM',
                                                    value: 'XM'
                                                },
                                                {
                                                    title: 'Battery Electric Vehicle'
                                                },
                                                {
                                                    title: 'i4 M50 Gran Coupe',
                                                    value: 'i4 M50 Gran Coupe'
                                                },
                                                {
                                                    title: 'i4eDrive40 Gran Coupe',
                                                    value: 'i4eDrive40 Gran Coupe'
                                                },
                                                {
                                                    title: 'iX xDrive50',
                                                    value: 'iX xDrive50'
                                                },
                                                {
                                                    title: 'i4 eDrive35 Gran Coupe',
                                                    value: 'i4 eDrive35 Gran Coupe'
                                                },
                                                {
                                                    title: 'i7 xDrive60 Sedan',
                                                    value: 'i7 xDrive60 Sedan'
                                                },
                                                {
                                                    title: 'iX M60',
                                                    value: 'iX M60'
                                                },
                                            ]
                                        case 'Cadillac': 
                                            return [
                                                {
                                                    title: 'Battery Electric Vehicle'
                                                },
                                                {
                                                    title: 'Lyriq',
                                                    value: 'Lyriq'
                                                },
                                                {
                                                    title: 'Lyric AWD',
                                                    value: 'Lyric AWD'
                                                },
                                            ]
                                        case 'Chevrolet': 
                                            return [
                                                {
                                                    title: 'Battery Electric Vehicle'
                                                },
                                                {
                                                    title: 'Bolt',
                                                    value: 'Bolt'
                                                },
                                                {
                                                    title: 'EUV',
                                                    value: 'EUV'
                                                },
                                                {
                                                    title: 'Blazer EV',
                                                    value: 'Blazer EV'
                                                },
                                                {
                                                    title: 'Silverado EV',
                                                    value: 'Silverado EV'
                                                },
                                            ]
                                        case 'Chrysler': 
                                            return [
                                                {
                                                    title: 'Plug-in Hybrid Electric Vehicles'
                                                },
                                                {
                                                    title: 'Pacifica Hybrid',
                                                    value: 'Pacifica Hybrid'
                                                }
                                            ]
                                        case 'Dodge': 
                                            return [
                                                {
                                                    title: 'Plug-in Hybrid Electric Vehicles'
                                                },
                                                {
                                                    title: 'Hornet',
                                                    value: 'Hornet'
                                                }
                                            ]
                                        case 'Fisker': 
                                            return [
                                                {
                                                    title: 'Battery Electric Vehicle'
                                                },
                                                {
                                                    title: 'Ocean Extreme',
                                                    value: 'Ocean Extreme'
                                                }
                                            ]
                                        case 'Ford': 
                                            return [
                                                {
                                                    title: 'Plug-in Hybrid Electric Vehicles'
                                                },
                                                {
                                                    title: 'Escape FWD PHEV',
                                                    value: 'Escape FWD PHEV'
                                                },
                                                {
                                                    title: 'Battery Electric Vehicle'
                                                },
                                                {
                                                    title: 'F150 Lightning Platinum 4WD',
                                                    value: 'F150 Lightning Platinum 4WD'
                                                },
                                                {
                                                    title: 'F150 Pickup Lightning 4WD',
                                                    value: 'F150 Pickup Lightning 4WD'
                                                },
                                                {
                                                    title: 'Mustang Mach-E AWD',
                                                    value: 'Mustang Mach-E AWD'
                                                },
                                                {
                                                    title: 'F150 Pickup Lightning 4WD Extended Range',
                                                    value: 'F150 Pickup Lightning 4WD Extended Range'
                                                },
                                                {
                                                    title: 'Mustang Mach-E GT',
                                                    value: 'Mustang Mach-E GT'
                                                },
                                                {
                                                    title: 'Mustang Mach-E AWD Extended Range',
                                                    value: 'Mustang Mach-E AWD Extended Range'
                                                }
                                            ]
                                        case 'Hyundai': 
                                            return [
                                                {
                                                    title: 'Battery Electric Vehicle'
                                                },
                                                {
                                                    title: 'Kona Electric',
                                                    value: 'Kona Electric',
                                                },
                                                {
                                                    title: 'Ioniq 5 AWD Long Range',
                                                    value: 'Ioniq 5 AWD Long Range',
                                                },
                                                {
                                                    title: 'Ioniq 5 RWD Standard Range',
                                                    value: 'Ioniq 5 RWD Standard Range',
                                                },
                                                {
                                                    title: 'Ioniq 6 AWD Long Range',
                                                    value: 'Ioniq 6 AWD Long Range',
                                                },
                                                {
                                                    title: 'Ioniq 6 RWD Standard Range',
                                                    value: 'Ioniq 6 RWD Standard Range',
                                                },
                                                {
                                                    title: 'Plug-in Hybrid Electric Vehicles'
                                                },
                                                {
                                                    title: 'Ioniq Plug-in Hybrid',
                                                    value: 'Ioniq Plug-in Hybrid'
                                                },
                                                {
                                                    title: 'Santa Fe Plug-in Hybrid',
                                                    value: 'Santa Fe Plug-in Hybrid'
                                                },
                                                {
                                                    title: 'Tucson Plug-in Hybrid',
                                                    value: 'Tucson Plug-in Hybrid'
                                                }
                                            ]
                                        case 'Jeep': 
                                            return [
                                                {
                                                    title: 'Plug-in Hybrid Electric Vehicles'
                                                },
                                                {
                                                    title: 'Grand Cherokee 4xe',
                                                    value: 'Grand Cherokee 4xe'
                                                },
                                                {
                                                    title: 'Wrangler  4xe',
                                                    value: 'Wrangler  4xe'
                                                }
                                            ]
                                        case 'Genesis': 
                                            return [
                                                {
                                                    title: 'Battery Electric Vehicle'
                                                },
                                                {
                                                    title: 'GV60 Performance',
                                                    value: 'GV60 Performance'
                                                },
                                                {
                                                    title: 'Electrified G80',
                                                    value: 'Electrified G80'
                                                },
                                                {
                                                    title: 'GV60 Advanced',
                                                    value: 'GV60 Advanced'
                                                },
                                                {
                                                    title: 'Electrified GV70',
                                                    value: 'Electrified GV70'
                                                },
                                            ]
                                        case 'GMC': 
                                            return [
                                                {
                                                    title: 'Battery Electric Vehicle'
                                                },
                                                {
                                                    title: 'Hummer EV Pickup',
                                                    value: 'Hummer EV Pickup'
                                                },
                                                {
                                                    title: 'Hummer EV SUV',
                                                    value: 'Hummer EV SUV'
                                                }
                                            ]
                                        case 'Jaguar': 
                                            return [
                                                {
                                                    title: 'Battery Electric Vehicle'
                                                },
                                                {
                                                    title: 'I-Pace EV 400',
                                                    value: 'I-Pace EV 400'
                                                }
                                            ]
                                        case 'Kia': 
                                            return [
                                                {
                                                    title: 'Plug-in Hybrid Electric Vehicles'
                                                },
                                                {
                                                    title: 'Niro Plug-in Hybrid',
                                                    value: 'Niro Plug-in Hybrid'
                                                },
                                                {
                                                    title: 'Sorento Plug-in Hybrid',
                                                    value: 'Sorento Plug-in Hybrid'
                                                },
                                                {
                                                    title: 'Battery Electric Vehicle'
                                                },
                                                {
                                                    title: 'EV6 AWD GT',
                                                    value: 'EV6 AWD GT'
                                                },
                                                {
                                                    title: 'EV6 RWD Long Range',
                                                    value: 'EV6 RWD Long Range'
                                                },
                                                {
                                                    title: 'EV6 RWD Standard Range',
                                                    value: 'EV6 RWD Standard Range'
                                                },
                                                {
                                                    title: 'Niro Electric',
                                                    value: 'Niro Electric'
                                                },
                                                {
                                                    title: 'EV9 Long Range RWD',
                                                    value: 'EV9 Long Range RWD'
                                                },
                                                {
                                                    title: 'EV9 Standard Range RWD',
                                                    value: 'EV9 Standard Range RWD'
                                                },
                                                {
                                                    title: 'EV9 Long Range AWD',
                                                    value: 'EV9 Long Range AWD'
                                                }
                                            ]
                                        case 'Land Rover': 
                                            return [
                                                {
                                                    title: 'Plug-in Hybrid Electric Vehicles'
                                                },
                                                {
                                                    title: 'Range Rover Sport PHEV',
                                                    value: 'Range Rover Sport PHEV'
                                                }
                                            ]
                                        case 'Lexus': 
                                            return [
                                                {
                                                    title: 'Plug-in Hybrid Electric Vehicles'
                                                },
                                                {
                                                    title: 'NX 450h+ AWD',
                                                    value: 'NX 450h+ AWD'
                                                },
                                                {
                                                    title: 'Battery Electric Vehicle'
                                                },
                                                {
                                                    title: 'RZ',
                                                    value: 'RZ'
                                                }
                                            ]
                                        case 'Lincoln': 
                                            return [
                                                {
                                                    title: 'Plug-in Hybrid Electric Vehicles'
                                                },
                                                {
                                                    title: 'Aviator PHEV',
                                                    value: 'Aviator PHEV'
                                                },
                                                {
                                                    title: 'Corsair PHEV',
                                                    value: 'Corsair PHEV'
                                                }
                                            ]
                                        case 'Lordstown': 
                                            return [
                                                {
                                                    title: 'Battery Electric Vehicle'
                                                },
                                                {
                                                    title: 'Endurance',
                                                    value: 'Endurance'
                                                }
                                            ]
                                        case 'Lucid USA Inc.': 
                                            return [
                                                {
                                                    title: 'Battery Electric Vehicle'
                                                },
                                                {
                                                    title: 'Air Dream P AWD w/19" wheels',
                                                    value: 'Air Dream P AWD w/19" wheels'
                                                },
                                                {
                                                    title: 'Air Dream R AWD',
                                                    value: 'Air Dream R AWD'
                                                },
                                                {
                                                    title: 'Air Grand Touring AWD',
                                                    value: 'Air Grand Touring AWD'
                                                },
                                                {
                                                    title: 'Air Pure AWD',
                                                    value: 'Air Pure AWD'
                                                },
                                                {
                                                    title: 'Air Touring AWD',
                                                    value: 'Air Touring AWD'
                                                },
                                            ]
                                        case 'Mazda': 
                                            return [
                                                {
                                                    title: 'Battery Electric Vehicle'
                                                },
                                                {
                                                    title: 'MX-30',
                                                    value: 'MX-30'
                                                }
                                            ]
                                        case 'Mercedes-Benz': 
                                            return [
                                                {
                                                    title: 'Battery Electric Vehicle'
                                                },
                                                {
                                                    title: 'EQS 450+',
                                                    value: 'EQS 450+'
                                                },
                                                {
                                                    title: 'EQB 300 4Matic',
                                                    value: 'EQB 300 4Matic'
                                                },
                                                {
                                                    title: 'EQB 350 4Matic',
                                                    value: 'EQB 350 4Matic'
                                                },
                                                {
                                                    title: 'EQE 350',
                                                    value: 'EQE 350'
                                                }
                                            ]
                                        case 'Mini': 
                                            return [
                                                {
                                                    title: 'Plug-in Hybrid Electric Vehicles'
                                                },
                                                {
                                                    title: 'Cooper SE Countryman All4',
                                                    value: 'Cooper SE Countryman All4'
                                                },
                                                {
                                                    title: 'Battery Electric Vehicle'
                                                },
                                                {
                                                    title: 'Cooper SE Hardtop 2 Door',
                                                    value: 'Cooper SE Hardtop 2 Door'
                                                }
                                            ]
                                        case 'Mitsubishi': 
                                            return [
                                                {
                                                    title: 'Plug-in Hybrid Electric Vehicles'
                                                },
                                                {
                                                    title: 'Outlander PHEV',
                                                    value: 'Outlander PHEV'
                                                }
                                            ]
                                        case 'Porsche': 
                                            return [
                                                {
                                                    title: 'Plug-in Hybrid Electric Vehicles'
                                                },
                                                {
                                                    title: 'Cayenne E-Hybrid',
                                                    value: 'Cayenne E-Hybrid'
                                                },
                                                {
                                                    title: 'Panamera 4 E-Hybrid',
                                                    value: 'Panamera 4 E-Hybrid'
                                                },
                                                {
                                                    title: 'Battery Electric Vehicle'
                                                },
                                                {
                                                    title: 'Taycan 4S Perf Battery',
                                                    value: 'Taycan 4S Perf Battery'
                                                },
                                                {
                                                    title: 'Taycan 4 Cross Turismo',
                                                    value: 'Taycan 4 Cross Turismo'
                                                },
                                                {
                                                    title: 'Taycan GTS',
                                                    value: 'Taycan GTS'
                                                },
                                                {
                                                    title: 'Taycan Turbo',
                                                    value: 'Taycan Turbo'
                                                }
                                            ]
                                        case 'Toyota': 
                                            return [
                                                {
                                                    title: 'Plug-in Hybrid Electric Vehicles'
                                                },
                                                {
                                                    title: 'Prius Prime',
                                                    value: 'Prius Prime'
                                                },
                                                {
                                                    title: 'RAV4 Prime',
                                                    value: 'RAV4 Prime'
                                                },
                                                {
                                                    title: 'Battery Electric Vehicle'
                                                },
                                                {
                                                    title: 'bZ4X AWD',
                                                    value: 'bZ4X AWD'
                                                },
                                                {
                                                    title: 'bZ4X Limited AWD',
                                                    value: 'bZ4X Limited AWD'
                                                }
                                            ]
                                        case 'Nissan': 
                                            return [
                                                {
                                                    title: 'Battery Electric Vehicle'
                                                },
                                                {
                                                    title: 'Leaf',
                                                    value: 'Leaf'
                                                },
                                                {
                                                    title: 'Ariya Engage',
                                                    value: 'Ariya Engage'
                                                },
                                                {
                                                    title: 'Ariya EVO+',
                                                    value: 'Ariya EVO+'
                                                },
                                                {
                                                    title: 'Ariya Venture',
                                                    value: 'Ariya Venture'
                                                },
                                                {
                                                    title: 'Leaf SV/SL',
                                                    value: 'Leaf SV/SL'
                                                },
                                            ]
                                        case 'Volvo': 
                                            return [
                                                {
                                                    title: 'Plug-in Hybrid Electric Vehicles'
                                                },
                                                {
                                                    title: 'S60 T8 AWD Recharge',
                                                    value: 'S60 T8 AWD Recharge'
                                                },
                                                {
                                                    title: 'S90 T8 AWD Recharge',
                                                    value: 'S90 T8 AWD Recharge'
                                                },
                                                {
                                                    title: 'V60 T8 AWD Recharge',
                                                    value: 'V60 T8 AWD Recharge'
                                                },
                                                {
                                                    title: 'XC60 T8 AWD Recharge',
                                                    value: 'XC60 T8 AWD Recharge'
                                                },
                                                {
                                                    title: 'XC90 T8 AWD Recharge',
                                                    value: 'XC90 T8 AWD Recharge'
                                                },
                                                {
                                                    title: 'Battery Electric Vehicle'
                                                },
                                                {
                                                    title: 'XC40 Recharge twin',
                                                    value: 'XC40 Recharge twin'
                                                },
                                                {
                                                    title: 'C40 Recharge twin',
                                                    value: 'C40 Recharge twin'
                                                }
                                            ]
                                        case 'Polestar Automotive USA Inc': 
                                            return [
                                                {
                                                    title: 'Battery Electric Vehicle'
                                                },
                                                {
                                                    title: 'Polestar 2 Single Motor',
                                                    value: 'Polestar 2 Single Motor'
                                                },
                                                {
                                                    title: 'Polestar 2 Dual Motor',
                                                    value: 'Polestar 2 Dual Motor'
                                                }
                                            ]
                                        case 'Rivian': 
                                            return [
                                                {
                                                    title: 'Battery Electric Vehicle'
                                                },
                                                {
                                                    title: 'R1S',
                                                    value: 'R1S'
                                                },
                                                {
                                                    title: 'R1T',
                                                    value: 'R1T'
                                                },
                                            ]
                                        case 'Subaru': 
                                            return [
                                                {
                                                    title: 'Battery Electric Vehicle'
                                                },
                                                {
                                                    title: 'Solterra',
                                                    value: 'Solterra'
                                                }
                                            ]
                                        case 'Tesla': 
                                            return [
                                                {
                                                    title: 'Battery Electric Vehicle'
                                                },
                                                {
                                                    title: 'Model 3 RWD',
                                                    value: 'Model 3 RWD'
                                                },
                                                {
                                                    title: 'Cybertruck AWD',
                                                    value: 'Cybertruck AWD'
                                                },
                                                {
                                                    title: 'Model 3 Performance',
                                                    value: 'Model 3 Performance'
                                                },
                                                {
                                                    title: 'Model 3 Long Range AWD',
                                                    value: 'Model 3 Long Range AWD'
                                                },
                                                {
                                                    title: 'Model S',
                                                    value: 'Model S'
                                                },
                                                {
                                                    title: 'Model Y RWD',
                                                    value: 'Model Y RWD'
                                                },
                                                {
                                                    title: 'Model Y Long Range AWD',
                                                    value: 'Model Y Long Range AWD'
                                                },
                                                {
                                                    title: 'Model X',
                                                    value: 'Model X'
                                                }
                                            ]
                                        case 'Vinfast': 
                                            return [
                                                {
                                                    title: 'Battery Electric Vehicle'
                                                },
                                                {
                                                    title: 'VF 9 Eco',
                                                    value: 'VF 9 Eco'
                                                },
                                                {
                                                    title: 'VF 9 Plus',
                                                    value: 'VF 9 Plus'
                                                }
                                            ]
                                        case 'Volkswagen': 
                                            return [
                                                {
                                                    title: 'Battery Electric Vehicle'
                                                },
                                                {
                                                    title: 'ID.4 Standard',
                                                    value: 'ID.4 Standard'
                                                },
                                                {
                                                    title: 'ID.4 AWD Pro S',
                                                    value: 'ID.4 AWD Pro S'
                                                },
                                                {
                                                    title: 'ID.4 AWD Pro S Plus',
                                                    value: 'ID.4 AWD Pro S Plus'
                                                },
                                                {
                                                    title: 'ID.4 Pro',
                                                    value: 'ID.4 Pro'
                                                },
                                                {
                                                    title: 'ID.4 Pro S',
                                                    value: 'ID.4 Pro S'
                                                },
                                                {
                                                    title: 'ID.4 Pro S Plus',
                                                    value: 'ID.4 Pro S Plus'
                                                },
                                                {
                                                    title: 'ID.4 S',
                                                    value: 'ID.4 S'
                                                },
                                                {
                                                    title: 'ID.4 AWD Pro',
                                                    value: 'ID.4 AWD Pro'
                                                },
                                                {
                                                    title: 'ID.4 Pro RWD',
                                                    value: 'ID.4 Pro RWD'
                                                }
                                            ]
                                    }
                                }
                            }
                        },
                        {
                            title: 'How will the electric vehicle be charged?',
                            child: true,
                            secondary: true,
                            tippyText: 'A Level 1 electric vehicle charger adds about 5 miles of driving range per hour, while a Level 2 electric vehicle charger can add 20 miles per hour. If driving less than 50 miles per day, a Level 1 charger can likely meet typical driving needs.',
                            input: {
                                name: 'chargeraccess',
                                type: 'select',
                                mirrorValue: [
                                    'charger'
                                ],
                                referenceText: {
                                    text: `For reference, overnight charging with a level 1 wall outlet is estimated to add [evrangelevel1] miles to your vehicle range.`,
                                    modifiedVariables: {
                                        "evrangelevel1": {
                                            rounding: 1,
                                        }
                                    },
                                    condition: (formData) => {
                                        return formData?.chargeraccess;
                                    }
                                },
                                hideifoptions: {
                                    'buy-electric-car': {
                                        type: 'notin',
                                        values: [
                                            'Yes'
                                        ]
                                    }
                                },
                                values: [
                                    {
                                        title: 'Using an Existing Level-1 Charger',
                                        value: 'Using an Existing Level-1 Charger'
                                    },
                                    {
                                        title: 'Using an Existing Level-2 Charger',
                                        value: 'Using an Existing Level-2 Charger'
                                    },
                                    {
                                        title: 'Installing a New Level-2 Charger',
                                        value: 'Installing a New Level-2 Charger'
                                    }
                                ]
                            }
                        },
                        {
                            input: {
                                name: 'ebike',
                                type: 'checkbox',
                                storage: 'single-value',
                                values: [
                                    {
                                        title: 'Buy an Electric Bike',
                                        value: 'Yes',
                                        tippyText: 'An electric bike has an electric motor to make it easier to go uphill and carry cargo. Electric bikes are an increasingly popular transportation option for shorter trips, <a href="https://elpc.org/blog/whats-the-deal-with-e-bikes/#:~:text=As%20of%202021%2C%20an%20American,million%20bikes%20sold%20in%202022." target="__blank">outselling electric vehicles 2-to-1 in 2021</a>, for economic, environmental, and health benefits.'
                                    }
                                ],
                                hideifoptions: {
                                    'mode': {
                                        type: 'notin',
                                        values: [
                                            'Transportation (TEMPEST-T)',
                                            'Home & Transportation (TEMPEST-X)'
                                        ]
                                    }
                                }
                            }
                        },
                        {
                            title: 'Select one of the following green alternatives if you would like to compare their impacts versus operating your existing vehicle or operating a new gasoline vehicle if Buy an Electric Vehicle is selected.',
                            subtitle: true,
                            hidetitleIf: {
                                'mode': {
                                    type: 'notin',
                                    values: [
                                        'Transportation (TEMPEST-T)',
                                        'Home & Transportation (TEMPEST-X)'
                                    ]
                                },
                                'rural-zip': {
                                    type: 'true'
                                }
                            }
                        },
                        {
                            input: {
                                name: 'publictransit',
                                type: 'checkbox',
                                storage: 'single-value',
                                values: [
                                    {
                                        title: 'Take More Public Transit',
                                        value: 'Yes',
                                        tippyText: 'Shifting a portion of a resident\’s driving mileage to public transit can reduce their transportation costs and emissions.'
                                    }
                                ],
                                hideifoptions: {
                                    'mode': {
                                        type: 'notin',
                                        values: [
                                            'Transportation (TEMPEST-T)',
                                            'Home & Transportation (TEMPEST-X)'
                                        ]
                                    },
                                    'rural-zip': {
                                        type: 'true'
                                    }
                                }
                            }
                        },
                        {
                            input: {
                                name: 'vehicle',
                                type: 'checkbox',
                                storage: 'single-value',
                                values: [
                                    {
                                        title: 'Go Car Free',
                                        value: 'Yes: Car-Free',
                                        tippyText: 'Going car-free and utilizing a mix of public transit, an electric bike, ride-hailing, carsharing, and walking/bicycling can be a great way to meet daily transportation needs while reducing emissions and costs.'
                                    }
                                ],
                                hideifoptions: {
                                    'rural-zip': {
                                        type: 'true',
                                    }
                                },
                                disableifoptions: {
                                    'buy-electric-car': {
                                        type: 'in',
                                        values: [
                                            'Yes'
                                        ]
                                    }
                                }
                            }
                        },
                        {
                            title: 'What will be your alternative transportation options? Select all that apply.',
                            child: true,
                            secondary: true,
                            input: {
                                name: 'finalise-transport',
                                type: 'checkbox',
                                class: 'horizontal',
                                hideifoptions: {
                                    'vehicle': {
                                        type: 'notin',
                                        values: [
                                            'Yes: Car-Free'
                                        ]
                                    }
                                },
                                values: [
                                    {
                                        title: 'Public Transit',
                                        value: 'Public Transit'
                                    },
                                    {
                                        title: 'Ride-hailing',
                                        value: 'Ridehailing',
                                        tippyText: 'Ride-hailing services, such as Uber and Lyft, match passengers with hired personal drivers via a mobile app. Ride-hailing can connect individuals to transit but can be costly if taking lengthier rides.'
                                    },
                                    {
                                        title: 'Carsharing/Rental Car',
                                        value: 'Carsharing/Rental Car',
                                        tippyText: 'Carsharing or car rentals provide an option for individuals to rent a vehicle for a short period of time. This can be helpful for longer trips where transit is not available.'
                                    },
                                    {
                                        title: 'Electric Bike',
                                        value: 'Electric Bike'
                                    },
                                    {
                                        title: 'Walking/Biking',
                                        value: 'Walking/Biking'
                                    }
                                ]
                            }
                        }
                    ]
                }
            ]
        }
    }
};

export default GreenUpgrades;