import { useContext } from 'react';

import { HideIfNoContext } from './HideIfNoContext';
import FormDataContext from '../FormDataContext';

/**
 * Over the top complicated logic to tell react if a field should be hidden or not
 * 
 * How to use: 
 * Pass an object through as options
 * Example object:
 * {
        'mode': {
            type: 'in',
            values: [
                'Transportation (TEMPEST-T)'
            ]
        }
    }
 * 
 * In the example `mode` is the field value we are comparing against
 * this then is an object which accepts type and value. 
 * 
 * This function will then loop through all the options, and return whether the
 * the field should be shown or hidden.
 * 
 * Comparisons avaliable
 * - in - Supoported only for string currently (Need to add array support)
 * - notin - supported - Supoported only for string currently (Need to add array support)
 * - novalue - supported - Checks to see if there is a value
 * - true - supported - if value is true or not present
 * - false - supported - if value is false or not present
 * - equal - unsupported
 * - notequal - unspported - Not decided if this should be a thing
 * 
 * Grouping for or statements
 * This is a little hacked in and were too late to change. (Classic)
 * So the idea is that you have a parent hide if, which can then have a group of optional children.
 * These children / parent will be ripped out again and ran through this function. 
 * if one is true then it will all return true. 
 * 
 * hideifoptions: {
        'ashp': {
            type: 'notin',
            values: [
                'Yes'
            ],
            or: {
                'field': {
                    type: 'notin',
                    values: [
                        'Yes'
                    ],
                }
            }
        },
        'spaceheatspmt_cleanertech': {
            type: 'notin',
                values: [
                    'None'
                ]
        }
    }
 * 
 * @param object options 
 * @returns bool
 */
const HideIf = (options) => {
    let { formData } = useContext(FormDataContext);

    return HideIfNoContext(options,formData);
};

export default HideIf;