import * as React from "react"

import * as styles from './modal.module.scss';

import Arcadia from '../../images/partners/arcadia-logo.png';
import Abode from '../../images/partners/abode-logo.png';
import EC from '../../images/partners/ec-logo.jpg';
import NBI from '../../images/partners/nbi-logo.png';
import Noop from '../../images/partners/noop-logo.png';
import Pacific from '../../images/partners/pacific-logo.jpg';
import Solar from '../../images/partners/solar-logo.png';

const PartnersModal = ({ modalPartnersOpen, setPartnersModalOpen }) => {

  return (
    <div className={styles.modal + (modalPartnersOpen ? ` ` + styles.hidden : '')}>
      <div className={styles.modalMain}>
        <section>
          <div className={styles.modalHeader}>
            <h2 className={styles.partnersHeader}>Our Partners</h2>
            <button className={styles.modalClose} onClick={e => setPartnersModalOpen(!modalPartnersOpen)}>Close</button>
          </div>
          <div className={styles.modalContent}>
            <article>
              <p>RMI collaborated with the following partner organizations to leverage their expertise and insights to refine the accuracy and robustness of the calculator. We are thankful for their continued collaboration to maximize its impact.</p>

              <div className={styles.partnersImages}>
                <div>
                  <a href="https://abodeem.com/" target="__blank">
                    <img src={Abode} alt='Abode Energy Management' />
                  </a>
                </div>
                <div>
                  <a href="https://www.arcadia.com/" target="__blank">
                    <img src={Arcadia} alt='Arcadia' />
                  </a>
                </div>
                <div>
                  <a href="https://electrificationcoalition.org/" target="__blank">
                    <img src={EC} alt='Electrification Coalition (EC)' />
                  </a>
                </div>
              </div>

              <div className={styles.partnersImagesFour}>
                <div>
                  <a href="https://newbuildings.org/" target="__blank">
                    <img src={NBI} alt='New Buildings Institute (NBI)' />
                  </a>
                </div>
                <div className={styles.partnersImagesFourNoop}>
                  <a href="https://neep.org/" target="__blank">
                    <img src={Noop} alt='Northeast Energy Efficiency Partnerships (NEEP)' />
                  </a>
                </div>
                <div>
                  <a href="https://www.pnnl.gov/" target="__blank">
                    <img src={Pacific} alt='Pacific Northwest National Laboratory (PNNL)' />
                  </a>
                </div>
                <div>
                  <a href="https://www.solarunitedneighbors.org/helpdesk" target="__blank">
                    <img src={Solar} alt='Solar United Neighbors (SUN)' />
                  </a>
                </div>
              </div>

            </article>
          </div>
        </section>
      </div>
    </div>
  );
};

export default PartnersModal