export const atLeastOneHomeUpgrade = {
    'solar': {
        type: 'novalue',
        or: {
            storage: {
                type: 'novalue'
            },
            communitysolar: {
                type: 'novalue'
            },
            wthz: {
                type: 'novalue'
            },
            ashp: {
                type: 'novalue'
            },
            hpwh: {
                type: 'novalue'
            },
            range: {
                type: 'novalue'
            },
            panel: {
                type: 'novalue'
            },
            roof: {
                type: 'novalue'
            },
            hs: {
                type: 'novalue'
            }
        }
    }
};

export const atLeastOneTransportUpgrade =  {
    'atLeastOneUpgrade': {
        type: 'false'
    }
};

export const atLeastOneNaturalGas = {
    "spaceheat_current": {
        type: 'notin',
        values: [
            "Natural Gas Furnace",
            "Natural Gas Boiler"
        ],
        or: {
            "wh_current": {
                type: 'notin',
                values: [
                    "Tank Natural Gas Water Heater",
                    "Tankless Natural Gas Water Heater"
                ]
            },
            "cooking_current": {
                type: 'notin',
                values: [
                    "Natural Gas Range",
                    "Natural Gas Stovetop & Natural Gas Wall Oven",
                    "Natural Gas Stovetop & Electric Wall Oven",
                    "Electric Resistance Stovetop & Natural Gas Wall Oven"
                ]
            }
        }
    }
}

export const atLeastOneFuelOil = {
    "spaceheat_current": {
        type: 'notin',
        values: [
            "Fuel Oil Furnace",
            "Fuel Oil Boiler"
        ],
        or: {
            "wh_current": {
                type: 'notin',
                values: [
                    "Tank Fuel Oil Water Heater"
                ]
            }
        }
    }
}

export const atLeastOnePropane = {
    "spaceheat_current": {
        type: 'notin',
        values: [
            "Propane Boiler",
            "Propane Furnace"
        ],
        or: {
            "wh_current": {
                type: 'notin',
                values: [
                    "Tank Propane Water Heater"
                ]
            },
            "cooking_current": {
                type: 'notin',
                values: [
                    "Propane Range",
                    "Propane Stovetop & Propane Wall Oven",
                    "Propane Stovetop & Electric Wall Oven"
                ]
            }
        }
    }
}