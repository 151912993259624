const ResultsFive = () => (
    //TEMPEST Live Version_s2.xlsm
    {
        "atLeastOneUpgrade":false,
        "mode":"Home (TEMPEST-H)",
        "basic-information":true,
        "zipcode":"85001",
        "zipData":{
            "Zip Code":85001,
            "Official USPS State Code":"AZ",
            "Density":308.6,
            "Urbanity":"Suburban",
            "Primary Official County Name":"Maricopa",
            "State_County":"AZ_Maricopa",
            "IECC Climate Zone":2,
            "IECC Moisture Regime":"B",
            "BA Climate Zone":"Hot-Dry",
            "state":"AZ",
            "homeareadefaults":{
                "Single-Family Detached Home":"2449",
                "Townhome":"1494",
                "Apartment":"828",
                "Mobile Home":"1181"
            }
        },
        "rural-zip":false,
        "zip-greater-than-four":true,
        "hometype":"Single-Family Detached Home",
        "homearea":"2750",
        "homeyr":"pre-1980",
        "green-upgrades":true,
        "solar":"Yes",
        "traditional-systems":true,
        "ac_current":"Ducted Central AC",
        "spaceheat_current":"Natural Gas Furnace",
        "wh_current":"Tank Natural Gas Water Heater",
        "cooking_current":"Natural Gas Range",
        "solarfinance":"No: Cash Purchase"
    }
);

export default ResultsFive;