exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-basic-information-js": () => import("./../../../src/pages/basic-information.js" /* webpackChunkName: "component---src-pages-basic-information-js" */),
  "component---src-pages-climate-impact-js": () => import("./../../../src/pages/climate-impact.js" /* webpackChunkName: "component---src-pages-climate-impact-js" */),
  "component---src-pages-cost-impact-js": () => import("./../../../src/pages/cost-impact.js" /* webpackChunkName: "component---src-pages-cost-impact-js" */),
  "component---src-pages-financing-js": () => import("./../../../src/pages/financing.js" /* webpackChunkName: "component---src-pages-financing-js" */),
  "component---src-pages-green-upgrades-js": () => import("./../../../src/pages/green-upgrades.js" /* webpackChunkName: "component---src-pages-green-upgrades-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-operation-and-maintenance-js": () => import("./../../../src/pages/operation-and-maintenance.js" /* webpackChunkName: "component---src-pages-operation-and-maintenance-js" */),
  "component---src-pages-share-js": () => import("./../../../src/pages/share.js" /* webpackChunkName: "component---src-pages-share-js" */),
  "component---src-pages-take-action-js": () => import("./../../../src/pages/take-action.js" /* webpackChunkName: "component---src-pages-take-action-js" */),
  "component---src-pages-technical-details-js": () => import("./../../../src/pages/technical-details.js" /* webpackChunkName: "component---src-pages-technical-details-js" */),
  "component---src-pages-traditional-systems-js": () => import("./../../../src/pages/traditional-systems.js" /* webpackChunkName: "component---src-pages-traditional-systems-js" */),
  "component---src-pages-upfront-costs-and-incentives-js": () => import("./../../../src/pages/upfront-costs-and-incentives.js" /* webpackChunkName: "component---src-pages-upfront-costs-and-incentives-js" */)
}

