import solarBatteryStorage from './../../images/optional_steps_rooftop_solar.png';
import weatherization from './../../images/optional_steps_weatherization.png';
import spaceCoolingHeating from './../../images/optional_steps_space_cooling_and_heating.png';
import waterHeating from './../../images/optional_steps_water_heating.png';
import cookingAppliance from './../../images/optional_steps_cooking_appliance.png';
import otherHomeUpgrades from './../../images/capital-costs-other-home-upgrades.png';
import vehicle from './../../images/technical-details-motor vehicle.png';
import electricBike from './../../images/optional_steps_ebike.png';
import electricVehicleCharger from './../../images/option_steps_level_2_ev_charger.png';
import taxCreditFinancing from './../../images/financing-tax-credit-financing.png';

const finacingInfo = {
    pageName: "Financing",
    sections: [
        {
            title: "Solar and Battery Storage",
            image: solarBatteryStorage,
            inputs: [
                {
                    title: "Do you want to include financing for the solar and battery storage up-front cost?",
                    tippyText: 'Solar and battery storage systems can be financed through a solar loan or third-party ownership model, such as a solar lease or power purchase agreement (PPA). In a solar lease, the resident pays the solar company for the solar panels through a monthly lease payment. In a PPA, the resident pays the solar company for the monthly solar power produced. Some states restrict rooftop solar third-party ownership. If a PPA or lease is not listed as an option for you, the state likely does not allow for this type of third-party ownership.',
                    input: {
                        name: 'solarfinance',
                        type: 'select',
                        title: "Do you want to include financing for the solar and battery storage up-front cost?",
                        values: (formData) => {
                            
                            const statePPAArray = [
                                "IA",
                                "AZ",
                                "CA",
                                "CO",
                                "CT",
                                "DC",
                                "DE",
                                "GA",
                                "HI",
                                "IL",
                                "MA",
                                "MD",
                                "ME",
                                "MI",
                                "NH",
                                "NJ",
                                "NM",
                                "NV",
                                "NY",
                                "OH",
                                "OK",
                                "OR",
                                "PA",
                                "RI",
                                "TX",
                                "UT",
                                "VA",
                                "VT",
                                "WV"
                            ];

                            const stateLeaseArray = statePPAArray.concat([
                                "FL",
                                "LA",
                                "MS",
                                "NC",
                                "SC"
                            ]);

                            const solarFinanceArray = [
                                {
                                    title: "No: Cash Purchase",
                                    value: 'No: Cash Purchase'
                                },
                                {
                                    title: "Yes: Solar Loan",
                                    value: 'Yes: Solar Loan'
                                }
                            ];
                            
                            if(stateLeaseArray.includes(formData?.zipData?.state)) {
                                solarFinanceArray.push(
                                    {
                                        title: "Yes: Solar Lease",
                                        value: 'Yes: Solar Lease'
                                    }
                                );
                            }

                            if(statePPAArray.includes(formData?.zipData?.state)) {
                                solarFinanceArray.push(
                                    {
                                        title: "Yes: Solar PPA",
                                        value: 'Yes: Solar PPA'
                                    }
                                );
                            }

                            return solarFinanceArray;
                        },
                        hideifoptions: {
                            solar: {
                                type: 'notin',
                                values: [
                                    'Yes'
                                ],
                                or: {
                                    storage: {
                                        type: 'notin',
                                        values: [
                                            'Yes'
                                        ]
                                    }
                                }
                            }
                        }
                    }
                },
                {
                    title: "Do you want to include a separate short-term loan to offset the timing in receiving tax benefits?",
                    tippyText: 'With larger tax credit incentives, some individuals opt for an additional short-term loan equal to the tax credit value to offset timing before they receive the tax credit in the following tax filing season.',
                    input: {
                        name: 'tax-credit-financing',
                        type: 'select',
                        values: [
                            {
                                title: 'Yes',
                                value: 'Yes'
                            },
                            {
                                title: 'No',
                                value: 'No'
                            }
                        ],
                        title: "Do you want to include a separate short-term loan to offset the timing in receiving tax benefits?",
                        hideifoptions: {
                            "solarfinance": {
                                type: 'notin',
                                values: [
                                    'Yes: Solar Loan'
                                ]
                            }
                        }
                    }
                }
            ],
            dropdowns: [
                {
                    title: "Show Advanced Inputs",
                    inputs: [
                        {
                            title: "Third-Party Internal Rate of Return (%)",
                            tippyText: 'This is a financial metric third-party solar companies use to estimate the profitability of their third-party ownership contract. The default of 12% is based on common residential solar company internal rates of return.',
                            input: {
                                name: 'solartpo_irr',
                                type: 'number',
                                rounding: 2,
                                min: 0,
                                max: 25,
                                step: 0.1,
                                multiplier: 100,
                                title: "Third-Party Internal Rate of Return (%)",
                                hideifoptions: {
                                    'solarfinance': {
                                        type: 'notin',
                                        values: [
                                            'Yes: Solar Lease'
                                        ]
                                    }
                                }
                            }
                        },
                        {
                            title: "Lease Length (years)",
                            tippyText: 'This is the length of the solar and, if selected, battery storage lease. The default value is 20 years, a common solar lease length.',
                            input: {
                                name: 'solartpo_yrs',
                                type: 'number',
                                rounding: 0,
                                min: 10,
                                max: 25,
                                title: "Lease Length (years)",
                                hideifoptions: {
                                    'solarfinance': {
                                        type: 'notin',
                                        values: [
                                            'Yes: Solar Lease'
                                        ]
                                    }
                                }
                            }
                        },
                        {
                            title: "Lease Escalator (%/year)",
                            tippyText: 'This is the annual percent increase in the lease payment over the contract. The default value is 2% based on industry norms.',
                            input: {
                                name: 'solartpo_escalator',
                                type: 'number',
                                rounding: 2,
                                min: 0,
                                max: 5,
                                step: 0.1,
                                multiplier: 100,
                                title: "Lease Escalator (%/year)",
                                hideifoptions: {
                                    'solarfinance': {
                                        type: 'notin',
                                        values: [
                                            'Yes: Solar Lease'
                                        ]
                                    }
                                }
                            }
                        },
                        {
                            title: "Lease Payment ($/month)",
                            tippyText: 'This is the monthly solar lease payment in the first year. The default value is the calculated first-year monthly payment required for the third-party entity to achieve the specified internal rate of return.',
                            input: {
                                name: 'solartpo_yronerate',
                                type: 'number',
                                rounding: 0,
                                min: 0,
                                max: 2000,
                                step: 1,
                                title: "Lease Payment ($/month)",
                                hideifoptions: {
                                    'solarfinance': {
                                        type: 'notin',
                                        values: [
                                            'Yes: Solar Lease'
                                        ]
                                    }
                                }
                            }
                        },
                        {
                            title: "Will the homeowner own the solar panel system after the contract ends?",
                            tippyText: 'If the homeowner will own the solar panels and, if selected, battery storage system after the contract ends, as opposed to the third-party removing the system, select “Yes.”',
                            input: {
                                name: 'solartpo_ownership',
                                type: 'select',
                                title: "Will the homeowner own the solar panel system after the contract ends?",
                                values: [
                                    {
                                        title: 'Yes',
                                        value: 'Yes'
                                    },
                                    {
                                        title: 'No',
                                        value: 'No'
                                    }
                                ],
                                hideifoptions: {
                                    'solarfinance': {
                                        type: 'notin',
                                        values: [
                                            'Yes: Solar Lease'
                                        ]
                                    }
                                }
                            }
                        },
                        {
                            title: "Third-Party Internal Rate of Return (%)",
                            input: {
                                name: 'solartpo_irr',
                                type: 'number',
                                rounding: 2,
                                min: 0,
                                max: 25,
                                step: 0.1,
                                multiplier: 100,
                                title: "Third-Party Internal Rate of Return (%)",
                                hideifoptions: {
                                    'solarfinance': {
                                        type: 'notin',
                                        values: [
                                            'Yes: Solar PPA'
                                        ]
                                    }
                                }
                            }
                        },
                        {
                            title: "Power Purchase Agreement Length (years)",
                            tippyText: 'This is the length of the solar and, if selected, battery storage power purchase agreement. The default value is 20 years, a common solar power purchase agreement length.',
                            input: {
                                name: 'solartpo_yrs',
                                type: 'number',
                                rounding: 0,
                                min: 10,
                                max: 25,
                                title: "Power Purchase Agreement Length (years)",
                                hideifoptions: {
                                    'solarfinance': {
                                        type: 'notin',
                                        values: [
                                            'Yes: Solar PPA'
                                        ]
                                    }
                                }
                            }
                        },
                        {
                            title: "Power Purchase Agreement Rate Escalator (%/year)",
                            tippyText: 'This is the annual percent increase in the power purchase agreement rate over the life of the contract. The default value is 2% based on common solar power purchase agreement contracts.',
                            input: {
                                name: 'solartpo_escalator',
                                type: 'number',
                                rounding: 2,
                                min: 0,
                                max: 5,
                                step: 0.1,
                                multiplier: 100,
                                title: "Power Purchase Agreement Rate Escalator (%/year)",
                                hideifoptions: {
                                    'solarfinance': {
                                        type: 'notin',
                                        values: [
                                            'Yes: Solar PPA'
                                        ]
                                    }
                                }
                            }
                        },
                        {
                            title: "Power Purchase Agreement Rate ($/kWh in year 1)",
                            tippyText: 'This is the monthly rate for the solar electricity produced in the first year. The default value is the first-year rate required for the third-party entity to achieve the specified internal rate of return.',
                            input: {
                                name: 'solartpo_yronerate',
                                type: 'number',
                                rounding: 3,
                                min: 0,
                                max: 0.75,
                                step: 0.01,
                                title: "Power Purchase Agreement Rate ($/kWh in year 1)",
                                hideifoptions: {
                                    'solarfinance': {
                                        type: 'notin',
                                        values: [
                                            'Yes: Solar PPA'
                                        ]
                                    }
                                }
                            }
                        },
                        {
                            title: "Will the homeowner own the solar panel system after the contract ends?",
                            tippyText: 'If the homeowner will own the solar panels and, if selected, battery storage system after the contract ends, as opposed to the third-party removing the system, select “Yes.”',
                            input: {
                                name: 'solartpo_ownership',
                                type: 'select',
                                title: "Will the homeowner own the solar panel system after the contract ends?",
                                values: [
                                    {
                                        title: 'Yes',
                                        value: 'Yes'
                                    },
                                    {
                                        title: 'No',
                                        value: 'No'
                                    }
                                ],
                                hideifoptions: {
                                    'solarfinance': {
                                        type: 'notin',
                                        values: [
                                            'Yes: Solar PPA'
                                        ]
                                    }
                                }
                            }
                        },
                        {
                            title: "Solar Loan",
                            secondary: true,
                            subtitle: true,
                        },
                        {
                            title: "Money Down (%)",
                            tippyText: 'This is the loan down payment, expressed as a percentage of the rooftop solar and, if selected, battery storage up-front cost. The default assumes a 0% down payment.',
                            input: {
                                name: 'solarloan_moneydown',
                                type: 'number',
                                rounding: 0,
                                min: 0,
                                max: 95,
                                multiplier: 100,
                                title: "Money Down (%)",
                                hideifoptions: {
                                    'solarfinance': {
                                        type: 'notin',
                                        values: [
                                            'Yes: Solar Loan'
                                        ]
                                    }
                                }
                            }
                        },
                        {
                            title: "Loan Amount ($)",
                            tippyText: 'This is the loan amount for the solar and, if selected, battery storage system. The default is the total up-front cost minus any incentives.',
                            input: {
                                name: 'solarloan_amount',
                                type: 'number',
                                rounding: 0,
                                min: 0,
                                disabled: 'disabled',
                                partialDisable: true,
                                maxfnc: (formData) => {
                                    return formData?.solarcapco + formData?.storagecapco ?? ''
                                },
                                title: "Loan Amount ($)",
                                hideifoptions: {
                                    'solarfinance': {
                                        type: 'notin',
                                        values: [
                                            'Yes: Solar Loan'
                                        ]
                                    }
                                }
                            }
                        },
                        {
                            title: "Loan Length (years)",
                            tippyText: 'This is the length of the solar and, if selected, battery storage loan. The default value is 20 years, a common solar loan length.',
                            input: {
                                name: 'solarloan_yrs',
                                type: 'number',
                                rounding: 0,
                                min: 0,
                                max: 25,
                                title: "Loan Length (years)",
                                hideifoptions: {
                                    'solarfinance': {
                                        type: 'notin',
                                        values: [
                                            'Yes: Solar Loan'
                                        ]
                                    }
                                }
                            }
                        },
                        {
                            title: "Loan Interest Rate (%)",
                            tippyText: 'This is the interest rate for the solar and, if selected, battery storage loan. The default value is based on solar interest rates and loan lengths sourced from the <a href="https://www.cleanenergycu.org/personal/solar-electric-systems-loans/" target="__blank">Clean Energy Credit Union</a> in January 2024.',
                            input: {
                                name: 'solarloan_interestrate',
                                type: 'number',
                                rounding: 2,
                                min: 0,
                                max: 25,
                                step: 0.1,
                                multiplier: 100,
                                title: "Loan Interest Rate (%)",
                                hideifoptions: {
                                    'solarfinance': {
                                        type: 'notin',
                                        values: [
                                            'Yes: Solar Loan'
                                        ]
                                    }
                                }
                            }
                        },
                        {
                            title: "Solar Tax Credit Financing",
                            secondary: true,
                            subtitle: true,
                        },
                        {
                            title: "Loan Amount ($)",
                            tippyText: 'This is the short-term loan amount. The default is equal to the total tax credit value of the green upgrades selected.',
                            input: {
                                name: 'taxcreditloan_amount',
                                type: 'number',
                                rounding: 0,
                                min: 0,
                                disabled: 'disabled',
                                partialDisable: true,
                                maxfnc: (formData) => {
                                    return formData?.solarincent_fed ?? ''
                                },
                                title: "Loan Amount ($)",
                                hideifoptions: {
                                    'tax-credit-financing': {
                                        type: 'notin',
                                        values: [
                                            'Yes'
                                        ]
                                    }   
                                }
                            }
                        },
                        {
                            title: "Loan Length (years)",
                            tippyText: 'This is the length of the short-term loan. The default value is the number of tax filing years needed to receive the full tax credit value.',
                            input: {
                                name: 'taxcreditloan_yrs',
                                type: 'number',
                                min: 1,
                                max: 3,
                                title: "Loan Length (years)",
                                hideifoptions: {
                                    'tax-credit-financing': {
                                        type: 'notin',
                                        values: [
                                            'Yes'
                                        ]
                                    }   
                                }
                            }
                        },
                        {
                            title: "Loan Interest Rate (%)",
                            tippyText: 'This is the interest rate for the short-term loan. The default value is based on a common short-term loan interest rate.',
                            input: {
                                name: 'taxcreditloan_interest',
                                type: 'number',
                                rounding: 2,
                                min: 0,
                                max: 25,
                                step: 0.1,
                                multiplier: 100,
                                title: "Loan Interest Rate (%)",
                                hideifoptions: {
                                    'tax-credit-financing': {
                                        type: 'notin',
                                        values: [
                                            'Yes'
                                        ]
                                    }   
                                }
                            }
                        }
                    ]
                }
            ]
        },
        {
            title: "Weatherization",
            image: weatherization,
            inputs: [
                {
                    title: "Do you want to include financing for the weatherization up-front cost?",
                    input: {
                        name: 'wthzfinance',
                        type: 'select',
                        values: [
                            {
                                title: 'Yes',
                                value: 'Yes'
                            },
                            {
                                title: 'No',
                                value: 'No'
                            }
                        ],
                        title: "Do you want to finance the weatherization up-front cost?",
                        hideifoptions: {
                            'wthz': {
                                type: 'notin',
                                values: [
                                    'Yes'
                                ]
                            }
                        }
                    }
                }
            ],
            dropdowns: [
                {
                    title: "Show Advanced Inputs",
                    inputs: [
                        {
                            title: "Money Down (%)",
                            tippyText: 'This is the loan down payment, expressed as a percentage of the weatherization up-front cost. The default value is 0%.',
                            input: {
                                name: 'wthzfinance_down',
                                type: 'number',
                                rounding: 0,
                                min: 0,
                                max: 95,
                                multiplier: 100,
                                title: "Money Down (%)",
                                hideifoptions: {
                                    'wthzfinance': {
                                        type: 'notin',
                                        values: [
                                            'Yes'
                                        ]
                                    }
                                }
                            }
                        },
                        {
                            title: "Loan Length (years)",
                            tippyText: 'This is the length of the weatherization loan. The default value is 10 years, a common home energy improvement loan length.',
                            input: {
                                name: 'wthzfinance_yrs',
                                type: 'number',
                                min: 0,
                                maxfnc: (formData) => {
                                    return formData?.timeframe_h ?? ''
                                },
                                title: "Loan Length (years)",
                                hideifoptions: {
                                    'wthzfinance': {
                                        type: 'notin',
                                        values: [
                                            'Yes'
                                        ]
                                    }
                                }
                            }
                        },
                        {
                            title: "Loan Interest Rate (%)",
                            tippyText: 'This is the interest rate for the weatherization loan. The default value is based on home energy improvement interest rates and loan lengths sourced from the <a href="https://www.cleanenergycu.org/personal/green-home-improvement-loans/" target="__blank">Clean Energy Credit Union</a> in January 2024.',
                            input: {
                                name: 'wthzfinance_interest',
                                type: 'number',
                                rounding: 2,
                                min: 0,
                                max: 25,
                                step: 0.1,
                                multiplier: 100,
                                title: "Loan Interest Rate (%)",
                                hideifoptions: {
                                    'wthzfinance': {
                                        type: 'notin',
                                        values: [
                                            'Yes'
                                        ]
                                    }
                                }
                            }
                        }
                    ]
                }
            ]
        },
        {
            title: "Space Cooling and Heating",
            image: spaceCoolingHeating,
            inputs: [
                {
                    title: "Do you want to include financing for the space-heating and space-cooling up-front cost?",
                    input: {
                        name: 'spaceheatcoolfinance',
                        type: 'select',
                        values: [
                            {
                                title: 'Yes',
                                value: 'Yes'
                            },
                            {
                                title: 'No',
                                value: 'No'
                            }
                        ],
                        title: "Do you want to include financing for the space-heating and space-cooling up-front cost?",
                        hideifoptions: {
                            'ashp': {
                                type: 'notin',
                                values: [
                                    'Yes'
                                ]
                            }
                        }
                    }
                }
            ],
            dropdowns: [
                {
                    title: "Show Advanced Inputs",
                    inputs: [
                        {
                            title: "Money Down (%)",
                            tippyText: 'This is the loan down payment, expressed as a percentage of the air-source heat pump or traditional replacement up-front cost. The default value is 0%.',
                            input: {
                                name: 'spaceheatcoolfinance_down',
                                type: 'number',
                                rounding: 0,
                                min: 0,
                                max: 95,
                                multiplier: 100,
                                title: "Money Down (%)",
                                hideifoptions: {
                                    'spaceheatcoolfinance': {
                                        type: 'notin',
                                        values: [
                                            'Yes'
                                        ]
                                    }
                                }
                            }
                        },
                        {
                            title: "Loan Length (years)",
                            tippyText: 'This is the length of the air-source heat pump, or its traditional replacement, loan. The default value is 10 years, a common home energy improvement loan length.',
                            input: {
                                name: 'spaceheatcoolfinance_yrs',
                                type: 'number',
                                min: 0,
                                maxfnc: (formData) => {
                                    return formData?.timeframe_h ?? ''
                                },
                                title: "Loan Length (years)",
                                hideifoptions: {
                                    'spaceheatcoolfinance': {
                                        type: 'notin',
                                        values: [
                                            'Yes'
                                        ]
                                    }
                                }
                            }
                        },
                        {
                            title: "Loan Interest Rate for Financing an Air-Source Heat Pump (%)",
                            tippyText: 'This is the interest rate for the air-source heat pump loan. The default value is based on home energy improvement interest rates and loan lengths sourced from the <a href="https://www.cleanenergycu.org/personal/green-home-improvement-loans/" target="__blank">Clean Energy Credit Union</a> in January 2024.',
                            input: {
                                name: 'spaceheatcoolfinance_cleanerinterest',
                                type: 'number',
                                rounding: 2,
                                min: 0,
                                max: 25,
                                step: 0.1,
                                multiplier: 100,
                                title: "Loan Interest Rate for Financing an Air-Source Heat Pump (%)",
                                hideifoptions: {
                                    'spaceheatcoolfinance': {
                                        type: 'notin',
                                        values: [
                                            'Yes'
                                        ]
                                    }
                                }
                            }
                        },
                        {
                            title: "Loan Interest Rate for Financing a [ac_current] or [spaceheat_current] (%)",
                            tippyText: 'This is the interest rate for the traditional replacement loan for space-cooling and -heating needs. The default value is based on home energy improvement interest rates and loan lengths sourced from the<a href="https://www.cleanenergycu.org/personal/green-home-improvement-loans/" target="__blank">Clean Energy Credit Union</a> in January 2024.',
                            input: {
                                name: 'spaceheatcoolfinance_bauinterest',
                                type: 'number',
                                rounding: 2,
                                min: 0,
                                max: 25,
                                step: 0.1,
                                multiplier: 100,
                                title: "Loan Interest Rate for Financing a [ac_current] or [spaceheat_current] (%)",
                                hideifoptions: {
                                    'spaceheatcoolfinance': {
                                        type: 'notin',
                                        values: [
                                            'Yes'
                                        ]
                                    }
                                }
                            }
                        }
                    ]
                }
            ]
        },
        {
            title: "Water Heating",
            image: waterHeating,
            inputs: [
                {
                    title: "Do you want to include financing for the water heater up-front cost?",
                    input: {
                        name: 'whfinance',
                        type: 'select',
                        values: [
                            {
                                title: 'Yes',
                                value: 'Yes'
                            },
                            {
                                title: 'No',
                                value: 'No'
                            }
                        ],
                        title: "Do you want to include financing for the water heater up-front cost?",
                        hideifoptions: {
                            'hpwh': {
                                type: 'notin',
                                values: [
                                    'Yes'
                                ]
                            }
                        }
                    }
                }
            ],
            dropdowns: [
                {
                    title: "Show Advanced Inputs",
                    inputs: [
                        {
                            title: "Money Down (%)",
                            tippyText: 'This is the loan down payment, expressed as a percentage of the water heater up-front cost. The default value is 0%.',
                            input: {
                                name: 'whfinance_down',
                                type: 'number',
                                rounding: 0,
                                min: 0,
                                max: 95,
                                multiplier: 100,
                                title: "Money Down (%)",
                                hideifoptions: {
                                    'whfinance': {
                                        type: 'notin',
                                        values: [
                                            'Yes'
                                        ]
                                    }
                                }
                            }
                        },
                        {
                            title: "Loan Length (years)",
                            tippyText: 'This is the length of the heat pump water heater, or its traditional replacement, loan. The default value is 10 years, a common home energy improvement loan length.',
                            input: {
                                name: 'whfinance_yrs',
                                type: 'number',
                                min: 0,
                                maxfnc: (formData) => {
                                    return formData?.timeframe_h ?? ''
                                },
                                title: "Loan Length (years)",
                                hideifoptions: {
                                    'whfinance': {
                                        type: 'notin',
                                        values: [
                                            'Yes'
                                        ]
                                    }
                                }
                            }
                        },
                        {
                            title: "Loan Interest Rate for Financing a Heat Pump Water Heater (%)",
                            tippyText: 'This is the interest rate for the heat pump water heater loan. The default value is based on home energy improvement interest rates and loan lengths sourced from the <a href="https://www.cleanenergycu.org/personal/green-home-improvement-loans/" target="__blank">Clean Energy Credit Union</a> in January 2024.',
                            input: {
                                name: 'whfinance_interest',
                                type: 'number',
                                rounding: 2,
                                min: 0,
                                max: 25,
                                step: 0.1,
                                multiplier: 100,
                                title: "Loan Interest Rate for Financing a Hybrid Water Heater (%)",
                                hideifoptions: {
                                    'whfinance': {
                                        type: 'notin',
                                        values: [
                                            'Yes'
                                        ]
                                    }
                                }
                            }
                        },
                        {
                            title: "Loan Interest Rate for Financing a [wh_current] (%)",
                            tippyText: 'This is the interest rate for the traditional water heater replacement loan. The default value is based on home energy improvement interest rates and loan lengths sourced from the <a href="https://www.cleanenergycu.org/personal/green-home-improvement-loans/" target="__blank">Clean Energy Credit Union</a> in January 2024.',
                            input: {
                                name: 'whfinance_bauinterest',
                                type: 'number',
                                rounding: 2,
                                min: 0,
                                max: 25,
                                step: 0.1,
                                multiplier: 100,
                                title: "Loan Interest Rate for Financing a [wh_current] (%)",
                                hideifoptions: {
                                    'whfinance': {
                                        type: 'notin',
                                        values: [
                                            'Yes'
                                        ]
                                    }
                                }
                            }
                        }
                    ]
                }
            ]
        },
        {
            title: "Cooking Appliance",
            image: cookingAppliance,
            inputs: [
                {
                    title: "Do you want to include financing for the cooking appliance up-front cost?",
                    input: {
                        name: 'rangefinance',
                        type: 'select',
                        values: [
                            {
                                title: 'Yes',
                                value: 'Yes'
                            },
                            {
                                title: 'No',
                                value: 'No'
                            }
                        ],
                        title: "Do you want to include financing for the cooking appliance up-front cost?",
                        hideifoptions: {
                            "range": {
                                type: 'notin',
                                values: [
                                    'Yes'
                                ]
                            }
                        }
                    }
                }
            ],
            dropdowns: [
                {
                    title: "Show Advanced Inputs",
                    inputs: [
                        {
                            title: "Money Down (%)",
                            tippyText: 'This is the loan down payment, expressed as a percentage of the cooking appliance up-front cost. The default value is 0%.',
                            input: {
                                name: 'rangefinance_down',
                                type: 'number',
                                rounding: 0,
                                min: 0,
                                max: 95,
                                multiplier: 100,
                                title: "Money Down (%)",
                                hideifoptions: {
                                    "rangefinance": {
                                        type: 'notin',
                                        values: [
                                            'Yes'
                                        ]
                                    }
                                }
                            }
                        },
                        {
                            title: "Loan Length (years)",
                            tippyText: 'This is the length of the electric cooking appliance, or its traditional replacement, loan. The default value is 10 years, a common home energy improvement loan length.',
                            input: {
                                name: 'rangefinance_yrs',
                                type: 'number',
                                min: 0,
                                maxfnc: (formData) => {
                                    return formData?.timeframe_h ?? ''
                                },
                                title: "Loan Length (years)",
                                hideifoptions: {
                                    "rangefinance": {
                                        type: 'notin',
                                        values: [
                                            'Yes'
                                        ]
                                    }
                                }
                            }
                        },
                        {
                            title: "Loan Interest Rate for Financing a [cooking_cleaner] (%)",
                            tippyText: 'This is the interest rate for the traditional cooking appliance replacement loan. The default value is based on home energy improvement interest rates and loan lengths sourced from the <a href="https://www.cleanenergycu.org/personal/green-home-improvement-loans/" target="__blank">Clean Energy Credit Union</a> in January 2024.',                            
                            input: {
                                name: 'rangefinance_interest',
                                type: 'number',
                                rounding: 2,
                                min: 0,
                                max: 25,
                                step: 0.1,
                                multiplier: 100,
                                title: "Loan Interest Rate for Financing an [cooking_cleaner] (%)",
                                hideifoptions: {
                                    "rangefinance": {
                                        type: 'notin',
                                        values: [
                                            'Yes'
                                        ]
                                    }
                                }
                            }
                        },
                        {
                            title: "Loan Interest Rate for Financing a [cooking_current] (%)",
                            tippyText: 'This is the interest rate for the traditional cooking appliance replacement loan. The default value is based on home energy improvement interest rates and loan lengths sourced from the <a href="https://www.cleanenergycu.org/personal/green-home-improvement-loans/" target="__blank">Clean Energy Credit Union</a> in January 2024.',
                            input: {
                                name: 'rangefinance_bauinterest',
                                type: 'number',
                                rounding: 2,
                                min: 0,
                                max: 25,
                                step: 0.1,
                                multiplier: 100,
                                title: "Loan Interest Rate for Financing a [cooking_current] (%)",
                                hideifoptions: {
                                    "rangefinance": {
                                        type: 'notin',
                                        values: [
                                            'Yes'
                                        ]
                                    },
                                    "cooking_current": {
                                        type: 'in',
                                        values: [
                                            "I would not upgrade my stove"
                                        ]
                                    }
                                }
                            }
                        }
                    ]
                }
            ]
        },
        {
            title: "Other Home Upgrades",
            image: otherHomeUpgrades,
            inputs: [
                {
                    title: "Do you want to include financing for the additional home upgrades?",
                    input: {
                        name: 'upgradesfinance',
                        type: 'select',
                        values: [
                            {
                                title: 'Yes',
                                value: 'Yes'
                            },
                            {
                                title: 'No',
                                value: 'No'
                            }
                        ],
                        title: "Do you want to include financing for the additional home upgrades?",
                        hideifoptions: {
                            "additional_home_upgrade": {
                                type: 'notin',
                                values: [
                                    'Yes'
                                ]
                            }
                        }
                    }
                }
            ],
            dropdowns: [
                {
                    title: "Show Advanced Inputs",
                    inputs: [
                        {
                            title: "Money Down (%)",
                            tippyText: 'This is the loan down payment, expressed as a percentage of the up-front cost for the additional upgrades. The default value is 0%.',
                            input: {
                                name: 'upgradesfinance_down',
                                type: 'number',
                                rounding: 0,
                                min: 0,
                                max: 95,
                                multiplier: 100,
                                title: "Money Down (%)",
                                hideifoptions: {
                                    "upgradesfinance": {
                                        type: 'notin',
                                        values: [
                                            'Yes'
                                        ]
                                    }
                                }
                            }
                        },
                        {
                            title: "Loan Length (years)",
                            tippyText:'This is the length of the loan for any additional upgrades. The default value is 10 years, a common home energy improvement loan length.',
                            input: {
                                name: 'upgradesfinance_yrs',
                                type: 'number',
                                rounding: 0,
                                min: 0,
                                maxfnc: (formData) => {
                                    return formData?.timeframe_h ?? ''
                                },
                                title: "Loan Length (years)",
                                hideifoptions: {
                                    "upgradesfinance": {
                                        type: 'notin',
                                        values: [
                                            'Yes'
                                        ]
                                    }
                                }
                            }
                        },
                        {
                            title: "Loan Interest Rate (%)",
                            tippyText: 'This is the interest rate for the additional home upgrade loan. The default value is based on home energy improvement interest rates for various loan lengths sourced from <a href="https://www.lightstream.com/home-improvement-loan" target="__blank">LightStream</a> in January 2024.',
                            input: {
                                name: 'upgradesfinance_interest',
                                type: 'number',
                                rounding: 2,
                                min: 0,
                                max: 25,
                                step: 0.1,
                                multiplier: 100,
                                title: "Loan Interest Rate (%)",
                                hideifoptions: {
                                    "upgradesfinance": {
                                        type: 'notin',
                                        values: [
                                            'Yes'
                                        ]
                                    }
                                }
                            }
                        }
                    ]
                }
            ]
        },
        {
            title: "Motor Vehicle",
            image: vehicle,
            inputs: [
                {
                    title: "Do you want to include financing for the vehicle up-front cost?",
                    input: {
                        name: 'vehiclefinance',
                        type: 'select',
                        values: [
                            {
                                title: 'No: Cash Purchase',
                                value: 'No: Cash Purchase'
                            },
                            {
                                title: 'Yes: Loan',
                                value: 'Yes: Loan'
                            },
                            {
                                title: 'Yes: Lease',
                                value: 'Yes: Lease'
                            }
                        ],
                        title: "Do you want to include financing for the vehicle up-front cost?",
                        hideifoptions: {
                            "buy-electric-car": {
                                type: 'notin',
                                values: [
                                    'Yes'
                                ]
                            }
                        }
                    }
                }
            ],
            dropdowns: [
                {
                    title: "Show Advanced Inputs",
                    inputs: [
                        {
                            title: "Money Down (%)",
                            tippyText: 'This is the loan down payment, expressed as a percentage of the up-front cost of the vehicle. The default value is 10% and sourced from <a href="https://www.kbb.com/car-news/what-is-the-best-down-payment/">Kelley Blue Book.</a>',
                            input: {
                                name: 'vehiclefinance_down',
                                type: 'number',
                                rounding: 0,
                                min: 0,
                                max: 95,
                                multiplier: 100,
                                title: "Money Down (%)",
                                hideifoptions: {
                                    "vehiclefinance": {
                                        type: 'notin',
                                        values: [
                                            'Yes: Loan'
                                        ]
                                    }
                                }
                            }
                        },
                        {
                            title: "Loan Length (years)",
                            tippyText: 'This is the length of the electric vehicle loan. The default value is five years, a common vehicle loan length.',
                            input: {
                                name: 'vehiclefinance_yrs',
                                type: 'number',
                                min: 1,
                                max: 10,
                                title: "Loan Length (years)",
                                hideifoptions: {
                                    "vehiclefinance": {
                                        type: 'notin',
                                        values: [
                                            'Yes: Loan'
                                        ]
                                    }
                                }
                            }
                        },
                        {
                            title: "Loan Interest Rate for Financing a Gasoline Vehicle (%)",
                            tippyText: 'This is the interest rate for financing a gasoline vehicle. The default value is based on the length of the loan and sourced from <a href="https://www.lightstream.com/new-car-loan" target"__blank">LightStream</a> in January 2024.',
                            input: {
                                name: 'ffvloan_interest',
                                type: 'number',
                                rounding: 2,
                                min: 0,
                                max: 25,
                                step: 0.1,
                                multiplier: 100,
                                title: "Loan Interest Rate for Financing a Gasoline Vehicle (%)",
                                hideifoptions: {
                                    "vehiclefinance": {
                                        type: 'notin',
                                        values: [
                                            'Yes: Loan'
                                        ]
                                    }
                                }
                            }
                        },
                        {
                            title: "Loan Interest Rate for Financing an Electric Vehicle (%)",
                            tippyText: 'This is the interest rate for the electric vehicle loan. The default value is based on the length of the loan and sourced from the <a href="https://www.cleanenergycu.org/personal/clean-energy-vehicle-loans/#rates" target="__blank">Clean Energy Credit Union</a> in January 2024.',
                            input: {
                                name: 'evloan_interest',
                                type: 'number',
                                rounding: 2,
                                min: 0,
                                max: 25,
                                step: 0.1,
                                multiplier: 100,
                                title: "Loan Interest Rate for Financing an Electric Vehicle (%)",
                                hideifoptions: {
                                    "vehiclefinance": {
                                        type: 'notin',
                                        values: [
                                            'Yes: Loan'
                                        ]
                                    }
                                }
                            }
                        },
                        {
                            title: "Lease Interest Rate for Financing a Gasoline Vehicle (%)",
                            tippyText: 'This is the interest rate for leasing a gasoline vehicle. The default value is sourced from <a href="https://www.nerdwallet.com/article/loans/auto-loans/nerdwallet-lease-calculator" target="__blank">NerdWallet.</a>',
                            input: {
                                name: 'ffvlease_interest',
                                type: 'number',
                                rounding: 2,
                                min: 0,
                                max: 25,
                                step: 0.1,
                                multiplier: 100,
                                title: "Lease Interest Rate for Financing a Gasoline Vehicle (%)",
                                hideifoptions: {
                                    "vehiclefinance": {
                                        type: 'notin',
                                        values: [
                                            'Yes: Lease'
                                        ]
                                    }
                                }
                            }
                        },
                        {
                            title: "Lease Interest Rate for Financing an Electric Vehicle (%)",
                            tippyText: 'This is the interest rate for leasing an electric vehicle. The default value is sourced from <a href="https://www.nerdwallet.com/article/loans/auto-loans/nerdwallet-lease-calculator" target="__blank">NerdWallet.</a>',
                            input: {
                                name: 'evlease_interest',
                                type: 'number',
                                rounding: 2,
                                min: 0,
                                max: 25,
                                step: 0.1,
                                multiplier: 100,
                                title: "Lease Interest Rate for Financing an Electric Vehicle (%)",
                                hideifoptions: {
                                    "vehiclefinance": {
                                        type: 'notin',
                                        values: [
                                            'Yes: Lease'
                                        ]
                                    }
                                }
                            }
                        },
                        {
                            title: "Lease Length (years)",
                            tippyText: 'This is the length of the lease. The default value is three years, a common vehicle lease length.',
                            input: {
                                name: 'vehiclelease_yrs',
                                type: 'number',
                                min: 1,
                                max: 10,
                                title: "Lease Length (years)",
                                hideifoptions: {
                                    "vehiclefinance": {
                                        type: 'notin',
                                        values: [
                                            'Yes: Lease'
                                        ]
                                    }
                                }
                            }
                        },
                        {
                            title: "Lease Acquisition Fee ($)",
                            tippyText: 'This is the lease acquisition fee, sometimes referred to as an assignment or origination fee, which is paid when leasing a vehicle. The default value is sourced from <a href="https://plugstar.com/" target="__blank">PlugStar</a>',
                            input: {
                                name: 'vehiclelease_fee',
                                type: 'number',
                                min: 0,
                                max: 2000,
                                title: "Lease Acquisition Fee ($)",
                                hideifoptions: {
                                    "vehiclefinance": {
                                        type: 'notin',
                                        values: [
                                            'Yes: Lease'
                                        ]
                                    }
                                }
                            }
                        },
                        {
                            title: "Lease Capitalized Incentives Passed to Lessee (%)",
                            tippyText: 'The lease capitalized incentives are the fraction of incentives passed to reduce the cost of the lease. The default value is 100% based on changes made under the Inflation Reduction Act.',
                            input: {
                                name: 'vehiclelease_incent',
                                type: 'number',
                                rounding: 0,
                                multiplier: 100,
                                min: 0,
                                max: 100,
                                title: "Lease Capitalized Incentives Passed to Lessee (%)",
                                hideifoptions: {
                                    "vehiclefinance": {
                                        type: 'notin',
                                        values: [
                                            'Yes: Lease'
                                        ]
                                    }
                                }
                            }
                        },
                        {
                            title: "Lease Residual Percentage (%)",
                            tippyText: 'The lease residual value is an estimate of how much the vehicle will be worth at the end of the lease contract. The default value is sourced from <a href="https://plugstar.com/" target="__blank">PlugStar.</a>',
                            input: {
                                name: 'vehiclelease_residual',
                                type: 'number',
                                rounding: 0,
                                multiplier: 100,
                                min: 0,
                                max: 100,
                                title: "Lease Residual Percentage (%)",
                                hideifoptions: {
                                    "vehiclefinance": {
                                        type: 'notin',
                                        values: [
                                            'Yes: Lease'
                                        ]
                                    }
                                }
                            }
                        }
                    ]
                }
            ]
        },
        {
            title: "Electric Bike",
            image: electricBike,
            inputs: [
                {
                    title: "Do you want to include financing for the electric bike up-front cost?",
                    input: {
                        name: 'ebikefinance',
                        type: 'select',
                        values: [
                            {
                                title: 'Yes',
                                value: 'Yes'
                            },
                            {
                                title: 'No',
                                value: 'No'
                            }
                        ],
                        title: "Do you want to include financing for the electric bike up-front cost?",
                        hideifoptions: {
                            'ebike': {
                                'type': 'notin',
                                'values': [ 
                                    'Yes'
                                ]
                            }
                        }
                    }
                }
            ],
            dropdowns: [
                {
                    title: "Show Advanced Inputs",
                    inputs: [
                        {
                            title: "Money Down (%)",
                            tippyText: 'This is the percentage of the capital cost that is paid up front as a down payment. The default value is 10%.',
                            input: {
                                name: 'ebikefinance_down',
                                type: 'number',
                                rounding: 0,
                                min: 0,
                                max: 95,
                                multiplier: 100,
                                title: "Money Down (%)",
                                hideifoptions: {
                                    'ebikefinance': {
                                        'type': 'notin',
                                        'values': [ 
                                            'Yes'
                                        ]
                                    }
                                }
                            }
                        },
                        {
                            title: "Loan Length (years)",
                            tippyText: 'This is the length of the loan. The default value is five years, a common electric bike loan length.',
                            input: {
                                name: 'ebikeloan_yrs',
                                type: 'number',
                                min: 1,
                                maxfnc: (formData) => {
                                    return formData?.timeframe_t ?? ''
                                },
                                title: "Loan Length (years)",
                                hideifoptions: {
                                    'ebikefinance': {
                                        'type': 'notin',
                                        'values': [ 
                                            'Yes'
                                        ]
                                    }
                                }
                            }
                        },
                        {
                            title: "Loan Interest Rate (%)",
                            tippyText: 'This is the interest rate for financing the electric bike. The default value is based on the length of the loan and sourced from the <a href="https://www.cleanenergycu.org/personal/clean-energy-vehicle-loans/#rates" target="__blank">Clean Energy Credit Union</a> in January 2024.',
                            input: {
                                name: 'ebikeloan_interest',
                                type: 'number',
                                rounding: 2,
                                min: 0,
                                max: 25,
                                step: 0.1,
                                multiplier: 100,
                                title: "Loan Interest Rate (%)",
                                hideifoptions: {
                                    'ebikefinance': {
                                        'type': 'notin',
                                        'values': [ 
                                            'Yes'
                                        ]
                                    }
                                }
                            }
                        }
                    ]
                }
            ]
        },
        {
            title: "Level 2 Electric Vehicle Charger",
            image: electricVehicleCharger,
            inputs: [
                {
                    title: "Do you want to include financing for the Level 2 charger capital cost?",
                    input: {
                        name: 'chargerfinance',
                        type: 'select',
                        values: [
                            {
                                title: 'Yes',
                                value: 'Yes: Loan'
                            },
                            {
                                title: 'No',
                                value: 'No: Cash Purchase'
                            }
                        ],
                        title: "Do you want to include financing for the Level 2 charger capital cost?",
                        hideifoptions: {
                            'chargeraccess': {
                                type: 'notin',
                                values: [
                                    'Installing a New Level-2 Charger'
                                ]
                            }
                        }
                    }
                }
            ],
            dropdowns: [
                {
                    title: "Show Advanced Inputs",
                    inputs: [
                        {
                            title: "Money Down (%)",
                            tippyText: 'This is the percentage of the capital cost that is paid up-front as a down payment. The default value is 10%. ',
                            input: {
                                name: 'chargerfinance_down',
                                type: 'number',
                                rounding: 0,
                                min: 0,
                                max: 95,
                                step: 1,
                                multiplier: 100,
                                title: "Money Down (%)",
                                hideifoptions: {
                                    'chargerfinance': {
                                        type: 'notin',
                                        values: [
                                            'Yes: Loan'
                                        ]
                                    }
                                }
                            }
                        },
                        {
                            title: "Loan Length (years)",
                            tippyText: 'This is the length of the loan for the electric vehicle charger. The default value is two years, a common electric vehicle charger loan length.',
                            input: {
                                name: 'chargerfinance_yrs',
                                type: 'number',
                                min: 1,
                                maxfnc: (formData) => {
                                    return formData?.timeframe_t ?? ''
                                },
                                title: "Loan Length (years)",
                                hideifoptions: {
                                    'chargerfinance': {
                                        type: 'notin',
                                        values: [
                                            'Yes: Loan'
                                        ]
                                    }
                                }
                            }
                        },
                        {
                            title: "Loan Interest Rate (%)",
                            tippyText: 'This is the interest rate for financing an electric vehicle charger. The default value is based on the length of the loan and sourced from the <a href="https://www.cleanenergycu.org/personal/green-home-improvement-loans/" target="__blank">Clean Energy Credit Union</a> in January 2024.',
                            input: {
                                name: 'chargerfinance_interest',
                                type: 'number',
                                rounding: 2,
                                min: 0,
                                max: 25,
                                step: 0.1,
                                multiplier: 100,
                                title: "Loan Interest Rate (%)",
                                hideifoptions: {
                                    'chargerfinance': {
                                        type: 'notin',
                                        values: [
                                            'Yes: Loan'
                                        ]
                                    }
                                }
                            }
                        }
                    ]
                }
            ]
        },
    ]
}

export default finacingInfo;