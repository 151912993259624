import * as React from "react";
import { useState } from 'react';

import * as styles from './modal.module.scss';

const ApiModal = ({ modalApiOpen, setApiModalOpen }) => {
  return (
    <div className={styles.modal + (modalApiOpen ? ` ` + styles.hidden : '')}>
      <div className={styles.modalMain}>
        <section>
          <div className={styles.modalHeader}>
            <h2 className={styles.contactHeader}>Application Programming Interface</h2>
            <button className={styles.modalClose} onClick={e => setApiModalOpen(!modalApiOpen)}>Close</button>
          </div>
          <div className={styles.modalContent}>
           <article>
            <p><strong>Overview:</strong> This service returns the green upgrade and traditional replacement upfront, operating, and lifetime costs and greenhouse gas emissions results corresponding to required and optional inputs provided.</p>
            <p><strong>Format:</strong> The type of HTTP requests are POST and inputs are JSON. Users must <a href='https://rmi.org/download/41017/' target="_blank" title="Create an API account">create an account</a> and use an API key to access this service. Rate limits apply. Please use the “Contact Us” feature in the toolbar to inquire about setting up an account and acquiring an API key.</p>
           </article>
          </div>
        </section>
      </div>
    </div>
  );
};

export default ApiModal