// useResults.js
import { useState, useEffect } from 'react';

import defaultFallbacks from './fields/defaultFallbacks';

import { getFields } from './FormFields';

import __ from 'lodash';

let keysToSkip = [
    'basic-information',
    'green-upgrades',
    'traditional-systems',
    'zipData',
    'rural-zip',
    'zip-greater-than-four',
    'active-solar-project',
    'atLeastOneUpgrade',
    'mirrorValues',
    'mode',
    'lsesData',
    'tariffsDataBau',
    'tariffsDataClean',
    'costData',
    'state',
    'debug_mode',
    "timeframe_h",
    "timeframe_t"
  ];

const useResults = () => {

    var GATSBY_API_URL = process.env.GATSBY_API_URL;
    
    const [results, setResults] = useState(() => {
        const storedData = localStorage.getItem('results_data');
        return storedData ? JSON.parse(storedData) : {};
    });
    const [previousState, setPreviousState] = useState(() => {
        const storedData = localStorage.getItem('results_data_form_data');
        return storedData ? JSON.parse(storedData) : {};
    });

    useEffect(() => {
        localStorage.setItem('results_data', JSON.stringify(results));
    }, [results]);

    useEffect(() => {
        localStorage.setItem('results_data_form_data', JSON.stringify(results));
    }, [previousState]);

    const filterDataByInputted = (newData) => {
        let filteredData = window.inputtedValues.reduce((acc, key) => {
          if (typeof newData[key] !== 'undefined') {
            acc[key] = newData[key];
          }
          return acc;
        }, {});
    
        keysToSkip.forEach(k => {
          if (newData[k])
            filteredData[k] = newData[k];
        })
    
        return filteredData;
    }

    const getResults = (newData, callback = false) => {

        if (__.isEqual(newData, previousState)) {
            let el = document.querySelectorAll('.loading-div');
            if (el)
                el.forEach((e) => {
                    e.classList.add('active');
                });
            return;
        }

        setPreviousState(newData);

        let el = document.querySelectorAll('.loading-div');
        if (el)
            el.forEach((e) => {
                e.classList.remove('loading-div');
                e.classList.add('loading-pending');
                e.style.display = 'none';
            });

        let formData = filterDataByInputted(newData);

        //Turns percentage inputs into decimals
        const dividedArray = [];
        Object.keys(formData).forEach((key) => {
        const fieldsObject = getFields(key);
            fieldsObject.forEach((field) => {
                if(field.multiplier && !dividedArray.includes(field.name)) {
                    formData[field.name] = parseFloat(formData[key]) / 100;
                    dividedArray.push(field.name);
                }
            })
        })

        // Create loading message element
        const loadingMessageElement = document.createElement('div');
        loadingMessageElement.innerText = 'Generating results... This may take up to a minute.';
        loadingMessageElement.classList.add('loading-message');

        // Append loading message element to the body
        document.body.appendChild(loadingMessageElement);

        
        document.body.classList.add('loading');
        formData = {
            ...defaultFallbacks(formData),
            ...formData,
            ...formData.mirrorValues
        }

        const headers = new Headers();
    
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', "application/json")
    
        fetch(`${GATSBY_API_URL}/tempest/v1`, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(formData)
        })
        .then(response => {
            if (response.status != 200) {
                document.body.removeChild(loadingMessageElement);
                document.body.classList.remove('loading');
                window.defaultsStatus = 'error';
                window.dispatchEvent(new Event('defaultsStatus'));
                return null;
            } else {
                return response.json();
            }
        })
        .then(data => {
            if (data == null)
                return;
            document.body.removeChild(loadingMessageElement);
            setResults(data)
            document.body.classList.remove('loading');
            let el = document.querySelectorAll('.loading-pending');
            if (el)
                el.forEach((e) => {
                    e.classList.add('loading-div');
                    e.classList.add('active');
                    e.style.display = '';
                });
            // navigate('/economic-impact/');
            if (callback && typeof callback === 'function') {
                callback();
            }
        })
        .catch(error => {
            window.defaultsStatus = 'error';
            window.dispatchEvent(new Event('defaultsStatus'));
        });
    }

    return { results, getResults };
};

export default useResults;
