import React, { useState, useEffect, useContext } from "react";
import AniLink from 'gatsby-plugin-transition-link/AniLink';
import DropDown from "../drop-downs";
import HideIf from "../../context/functions/HideIf";
import sideMenu from "../../context/fields/sideMenus";

import FormDataContext from '../../context/FormDataContext';

const MenuLinks = () => {
    const { formData, addOrUpdateFormData } = useContext(FormDataContext);
    const [ open, setOpen ] = useState("");

    return (
        <div>
            <section>
                <div>
                    <NavLink url="/" text="Get Started" />
                </div>
            </section>
            {sideMenu.map((menuItem) => {
                let isDisabled;

                if(menuItem.title === "Required Steps") {
                    isDisabled = !(formData?.mode);
                }else if(menuItem.title === "Optional Steps" || menuItem.title === "Results") {
                    isDisabled = !(formData?.["basic-information"] && formData?.["green-upgrades"] && formData?.["traditional-systems"]);
                }

                return(
                    <div key={menuItem.title} >
                        <hr />
                        <NavLinksDropDown
                            title={menuItem.title}
                            urls={menuItem.urls}
                            disabled={isDisabled}
                            open={open}
                            setOpen={setOpen}
                        />
                    </div>
                )
            })}
        </div>
    )
}

export default MenuLinks;


const NavLinksDropDown = ({
    urls,
    disabled,
    title,
    onClick,
    open,
    setOpen
  }) => {

    useEffect(() => {
        if(typeof window !== 'undefined') {
            for (const url of urls) {
                if(url.url + "/" === window.location.pathname) {
                    setOpen(title);
                    break;
                }
            }
        }
    }, [typeof window !== 'undefined' ? window?.location.pathname : null]);

    const handleClick = (e) => {
        if(onClick) {
            onClick();
        }

        if(verifyOpen(open)) {
            setOpen(e.target.name);
        }else{
            setOpen(title);
        }
    }

    const verifyOpen = (open) => {
        return open === title;
    }

    return (
        <section className={`dropdown ${disabled ? "disabled" : ""} ${ verifyOpen(open) ? "open" : "closed"}`}>
            <div>
                <header className={`${ verifyOpen(open) ? 'open' : ''}`} onClick={ handleClick } name={title}>{title}</header>
            </div>
            <article
                style={{
                    display: (verifyOpen(open)) ? '' : 'none'
                }}
            >
                <ul>
                    {urls.map(url => <li key={`${title}-nav-link-${url.url}`}><NavLink {...url} /></li> )}
                </ul>
            </article>
        </section>
    )
  }
  
  const closeMenu = () => document.querySelector(".closeMenu").click()
  
  const NavLink = ({
    url,
    text,
    disabled
  }) => {
    let hideOptions = {};

    hideOptions[disabled] = {
        type: 'false'
    };

    return (
        <AniLink
            fade="true"
            activeClassName="nav-active"
            className={(
                disabled &&
                disabled.length) > 0
                && HideIf(hideOptions) ? 'disabled' : ''
            }
            to={url}
            onClick={closeMenu} >{text}</AniLink>
    );
}
  