import rooftopSolarPanels from './../../images/optional_steps_rooftop_solar.png';
import communitySolar from './../../images/technical-details-community-solar.png';
import weatherization from './../../images/technical-details-home-design.png';
import spaceCoolingHeating from './../../images/optional_steps_space_cooling_and_heating.png';
import waterHeating from './../../images/optional_steps_water_heating.png';
import cookingAppliance from './../../images/optional_steps_cooking_appliance.png';
import motorVehicle from './../../images/technical-details-motor vehicle.png';
import electricBike from './../../images/optional_steps_ebike.png';
import ridehalingRentalCars from './../../images/ridehaling-rental-cars.svg';
import analysisTimeframeGridEmissions from './../../images/technical-details-grid-emissions.png';

import { atLeastOneTransportUpgrade } from './commonHides'

const TechnicalDetails = {
    pageName: 'Current Technologies',
    sections: [
        {
            title: 'Solar and Battery Storage',
            image: rooftopSolarPanels,
            inputs: [
                {
                    title: 'Solar PV System Size (kW)',
                    tippyText: "This is the installation size, in kW-DC, of the rooftop solar installation. Consider using Google\'s <a href='https://sunroof.withgoogle.com/' target='__blank'>Project Sunroof</a> to estimate the maximum solar photovoltaic size for the roof, and then update this value upon receiving quotes from solar companies. The default value is determined using an estimated roof size (based on the conditioned area and number of stories), total annual electricity demand post-electrification, and the net metering policy in the state.",
                    input: {
                        name: 'solarkw',
                        type: 'number',
                        rounding: 2,
                        min: 0.3,
                        step: 0.1,
                        max: 50,
                        title: "Solar PV System Size (kW-DC)",
                        hideifoptions: {
                            'solar': {
                                type: 'notin',
                                values: [
                                    'Yes'
                                ]
                            }
                        },
                        referenceText: {
                            text: `For reference, this solar panel system size at the referenced tilt and azimuth would generate an estimated [solargen] kWh per year. This is equal to [solargenpercentannualdemand]% of the home's annual electricity demand after installing the selected green upgrades and [solargenpercentannualexported]% of the solar generation is estimated to be exported to the grid. `,
                            modifiedVariables: {
                                "solargenpercentannualdemand": {
                                    rounding: 0,
                                    multiplier: 100,
                                },
                                "solargenpercentannualexported": {
                                    rounding: 0,
                                    multiplier: 100,
                                },
                                "solargen": {
                                    rounding: 0,
                                },
                            },
                            condition: (formData) => {
                                return formData["solarkw"];
                            }
                        },
                    }
                },
                {
                    title: 'Battery Storage Size (kW:kWh)',
                    tippyText: 'This is the size of the battery storage system, with kW corresponding to its peak capacity and kWh for the number of hours it can produce its rated kW capacity (e.g., 5 kW: 13.5 kWh). The default size is selected based on the solar photovoltaic system size.',
                    input: {
                        name: 'storagesize',
                        type: 'select',
                        title: "Battery Storage Size (kW:kWh)",
                        values: [
                            {
                                title: '5:13.5',
                                value: '5:13.5'
                            },
                            {
                                title: '10:27',
                                value: '10:27'
                            },
                            {
                                title: '15:40.5',
                                value: '15:40.5'
                            },
                            {
                                title: '20:54',
                                value: '20:54'
                            }
                        ],
                        hideifoptions: {
                            'storage': {
                                type: 'notin',
                                values: [
                                    'Yes'
                                ]
                            }
                        }
                    }
                },
            ],
            dropdowns: [
                {
                    title: "Show Advanced Inputs",
                    inputs: [
                        {
                            title: 'Array Tilt (degrees)',
                            tippyText: 'This is the angle from horizontal of the solar panels in the array. If the solar panels will be flush against the roof, enter the roof angle (e.g., a roof with a 4/12 pitch would enter 18.4 degrees). The default value is 20 degrees based on the default in the National Renewable Energy Laboratory’s <a href="https://pvwatts.nrel.gov/pvwatts.php" target="__blank">PVWatts</a>.',
                            input: {
                                name: 'solardirection',
                                title: 'Array Tilt (degrees)',
                                type: 'number',
                                min: 0,
                                max: 90,
                                hideifoptions: {
                                    'solar': {
                                        type: 'notin',
                                        values: [
                                            'Yes'
                                        ]
                                    }
                                },
                            }
                        },
                        {
                            title: 'Array Azimuth (degrees)',
                            tippyText: 'This is the angle clockwise from true north describing the direction the array faces. An azimuth angle of 180 degrees, which is the default value, describes a south-facing array.',
                            input: {
                                name: 'solarazimuth',
                                title: 'Array Azimuth (degrees)',
                                type: 'number',
                                min: 0,
                                max: 360,
                                hideifoptions: {
                                    'solar': {
                                        type: 'notin',
                                        values: [
                                            'Yes'
                                        ]
                                    }
                                },
                            }
                        },
                        {
                            title: 'Annual Panel Degradation Factor (%/year)',
                            tippyText: 'This is the amount that the solar panels gradually degrade over time, becoming less efficient at making electricity. The default value is 0.5% per year based on <a href="https://www.nrel.gov/docs/fy15osti/65040.pdf" target="__blank">National Renewable Energy Laboratory research</a> for long-term solar panel degradation. Degradation rates of most common panels can be found <a href="https://www.energysage.com/solar-panels/" target="__blank">here.</a> ',
                            input: {
                                name: 'solardegradation',
                                type: 'number',
                                rounding: 1,
                                min: 0,
                                step: 0.1,
                                max: 3,
                                multiplier: 100,
                                title: "Annual Panel Degradation Factor (%/year)",
                                hideifoptions: {
                                    'solar': {
                                        type: 'notin',
                                        values: [
                                            'Yes'
                                        ]
                                    }
                                },
                            }
                        },
                        {
                            title: 'Battery Storage Useful Life (years)',
                            tippyText: 'This is the number of years the battery storage system will last. The default value is 15 years based on common lifespans for residential battery storage systems. If the battery storage life is less than the selected home analysis period, the replacement costs for the system are factored into the analysis.',
                            input: {
                                name: 'storageuselife',
                                type: 'number',
                                min: 10,
                                max: 20,
                                rounding: 0,
                                title: "Battery Storage Useful Life (years)",
                                hideifoptions: {
                                    'storage': {
                                        type: 'notin',
                                        values: [
                                            'Yes'
                                        ]
                                    }
                                }
                            }
                        }
                    ]
                }
            ]
        },
        {
            title: 'Community Solar',
            image: communitySolar,
            inputs: [
                {
                    title: 'Electricity Bill Cost Savings from a Community Solar Subscription (%/year)',
                    tippyText: 'This is the expected monthly electricity bill savings from a community solar subscription. The default value is 20% if “<80% area median income” is selected in the financing section, and 10% otherwise, based on typical projects reported in <a href="https://www.energysage.com/shop/community-solar/" target="__blank">energysage.com.</a>',
                    input: {
                        name: 'communitysaving',
                        type: 'number',
                        rounding: 0,
                        min: 0,
                        max: 90,
                        multiplier: 100,
                        title: "Electricity Bill Cost Savings from a Community Solar Subscription (%/year)",
                        hideifoptions: {
                            'communitysolar': {
                                type: 'notin',
                                values: [
                                    'Yes'
                                ]
                            }
                        }
                    }
                }
            ]
        },
        {
            title: 'Home Design',
            image: weatherization,
            inputs: [
                {
                    title: 'Home Conditioned Stories',
                    tippyText: 'This is the number of stories in the home with conditioned space. The default value is 2 for single-family detached and townhomes, and 1 for mobile homes.',
                    input: {
                        name: 'homestories',
                        title: 'Home Conditioned Stories',
                        type: 'number',
                        rounding: 0,
                        min: 0,
                        max: 9999999,
                        hideifoptions: {
                            'wthz': {
                                type: 'notin',
                                values: [
                                    'Yes'
                                ],
                                or: {
                                    ashp: {
                                        type: 'notin',
                                        values: [
                                            'Yes'
                                        ]
                                    }
                                }
                            }
                        },
                    }
                },
                {
                    title: 'Which home weatherization improvements have already been added to the home?',
                    tippyText: 'Identify any home weatherization improvements, including adding insulation, sealing leaks, and/or replacing windows, that have been added to the home since it was built. The default value is "None".',
                    input: {
                        name: 'wthz_current',
                        title: 'Which home weatherization improvements have already been added to the home?',
                        type: 'checkbox',
                        class: 'horizontal',
                        hideifoptions: {
                            'wthz': {
                                type: 'notin',
                                values: [
                                    'Yes'
                                ],
                                or: {
                                    ashp: {
                                        type: 'notin',
                                        values: [
                                            'Yes'
                                        ]
                                    }
                                }
                            }
                        },
                        values: [
                            {
                                title: 'Added Insulation',
                                value: 'Added Insulation',
                                tippyText: 'Adding or improving the insulation in the walls, ceiling, or attic of your home can reduce your heating and cooling energy costs. <a href="https://www.energy.gov/energysaver/insulation" target="__blank">Learn more here. </a>'
                            },
                            {
                                title: 'Sealed Air Leaks',
                                value: 'Sealed Air Leaks',
                                tippyText: 'Caulking, weather stripping, and sealing other drafts can reduce air leakage from your home. <a href="https://www.energy.gov/energysaver/air-sealing-your-home" target="__blank">Learn more here.</a>'
                            },
                            {
                                title: 'Replaced Windows',
                                value: 'Replaced Windows',
                                tippyText: 'Replacing older windows with new, more energyefficient ones can improve their ability to maintain comfortable indoor temperatures. <a href="https://www.energy.gov/energysaver/update-or-replace-windows"target="__blank">Learn more here.</a>'
                            }
                        ]
                    }
                }
            ],
            dropdowns: [
                {
                    title: 'Show Advanced Inputs',
                    inputs: [
                        {
                            title: 'Current Wall R-Value (F-ft\u00B2-hr/BTU)',
                            tippyText: 'This is a measure of the ability of the walls in the home to resist heat flow into or out of the home. The default is estimated based on a percent reduction, given the age of the home, from the current state energy code for wall insulation, sourced from the <a href="https://insulationinstitute.org/im-a-building-or-facility-professional/residential/codes-standards/" target="__blank">Insulation Institute.</a>',
                            input: {
                                name: 'rvalwall_current',
                                type: 'number',
                                rounding: 1,
                                min: 1,
                                max: 50,
                                title: "Current Wall R-Value (F-ft\u00B2-hr/BTU)",
                                hideifoptions: {
                                    'wthz': {
                                        type: 'notin',
                                        values: [
                                            'Yes'
                                        ],
                                        or: {
                                            ashp: {
                                                type: 'notin',
                                                values: [
                                                    'Yes'
                                                ]
                                            }
                                        }
                                    }
                                }
                            }
                        },
                        {
                            title: 'Current Attic or Ceiling R-Value (F-ft\u00B2-hr/BTU)',
                            tippyText: 'This is a measure of the ability of the attic, or ceiling if the home does not have an attic, to resist heat flow into or out of the home. The default is estimated based on a percent reduction, given the age of the home, from the current state energy code for ceiling insulation, sourced from the <a href="https://insulationinstitute.org/im-a-building-or-facility-professional/residential/codes-standards/" target="__blank">Insulation Institute.</a>',
                            input: {
                                name: 'rvalroof_current',
                                type: 'number',
                                rounding: 1,
                                min: 1,
                                max: 50,
                                title: "Current Attic or Ceiling R-Value (F-ft\u00B2-hr/BTU)",
                                hideifoptions: {
                                    'wthz': {
                                        type: 'notin',
                                        values: [
                                            'Yes'
                                        ],
                                        or: {
                                            ashp: {
                                                type: 'notin',
                                                values: [
                                                    'Yes'
                                                ]
                                            }
                                        }
                                    }
                                }
                            }
                        },
                        {
                            title: 'Current Window R-Value (F-ft\u00B2-hr/BTU)',
                            tippyText: 'This is a measure of the ability of the windows to resist heat flow into or out of the home. The default is estimated based on a percent reduction, given the age of the home, from the current state energy code for fenestration, sourced from the <a href="https://insulationinstitute.org/im-a-building-or-facility-professional/residential/codes-standards/" target="__blank">Insulation Institute.</a>',
                            input: {
                                name: 'rvalwindow_current',
                                type: 'number',
                                rounding: 1,
                                min: 1,
                                max: 5,
                                title: "Current Window R-Value (F-ft\u00B2-hr/BTU)",
                                hideifoptions: {
                                    'wthz': {
                                        type: 'notin',
                                        values: [
                                            'Yes'
                                        ],
                                        or: {
                                            ashp: {
                                                type: 'notin',
                                                values: [
                                                    'Yes'
                                                ]
                                            }
                                        }
                                    }
                                }
                            }
                        },
                        {
                            title: 'Current Home ACH50 ',
                            tippyText: 'This is the air changes per hour the home experiences when subjected to a 50 pascal pressure difference compared with the outside. This is a common industry reference point to measure the home’s air leakage using a "blower door" test, with a lower number indicating a more tightly sealed home. The default is estimated based on a percent reduction, given the age of the home, from the current state energy code for air leakage, sourced from the <a href="https://insulationinstitute.org/im-a-building-or-facility-professional/residential/codes-standards/" target="__blank">Insulation Institute.</a>',
                            input: {
                                name: 'ach50_current',
                                type: 'number',
                                rounding: 1,
                                min: 1,
                                max: 30,
                                title: "Current Home ACH50 ",
                                hideifoptions: {
                                    'wthz': {
                                        type: 'notin',
                                        values: [
                                            'Yes'
                                        ],
                                        or: {
                                            ashp: {
                                                type: 'notin',
                                                values: [
                                                    'Yes'
                                                ]
                                            }
                                        }
                                    }
                                }
                            }
                        },
                        {
                            title: 'New Wall R-Value (F-ft\u00B2-hr/BTU)',
                            tippyText: 'This is a measure of the ability of the walls in the home to resist heat flow into or out of the home after increasing insulation. The default is estimated based on adding blown-in insulation that has an R-Value corresponding to current state energy code for wall insulation, sourced from the <a href="https://insulationinstitute.org/im-a-building-or-facility-professional/residential/codes-standards/" target="__blank">Insulation Institute.</a>',
                            input: {
                                name: 'rvalwall_new',
                                type: 'number',
                                rounding: 1,
                                min: 3.927766667,
                                max: 50,
                                title: "New Wall R-Value (F-ft\u00B2-hr/BTU)",
                                hideifoptions: {
                                    'wthz': {
                                        type: 'notin',
                                        values: [
                                            'Yes'
                                        ]
                                    }
                                }
                            }
                        },
                        {
                            title: 'New Attic or Ceiling R-Value (F-ft\u00B2-hr/BTU)',
                            tippyText: 'This is a measure of the ability of the attic, or ceiling if the home does not have an attic, to resist heat flow into or out of the home after increasing insulation. The default is estimated based on adding blown-in insulation that has an R-Value corresponding to current state energy code for ceiling insulation, sourced from the <a href="https://insulationinstitute.org/im-a-building-or-facility-professional/residential/codes-standards/" target="__blank">Insulation Institute.</a>',
                            input: {
                                name: 'rvalroof_new',
                                type: 'number',
                                rounding: 1,
                                min: 9.808325,
                                max: 50,
                                title: "New Attic or Ceiling R-Value (F-ft\u00B2-hr/BTU)",
                                hideifoptions: {
                                    'wthz': {
                                        type: 'notin',
                                        values: [
                                            'Yes'
                                        ]
                                    }
                                }
                            }
                        },
                        {
                            title: 'New Window R-Value (F-ft\u00B2-hr/BTU)',
                            tippyText: 'This is a measure of the ability of the windows to resist heat flow into or out of the home after being replaced. The default is estimated based on the current state energy code for fenestration, sourced from the <a href="https://insulationinstitute.org/im-a-building-or-facility-professional/residential/codes-standards/" target="__blank">Insulation Institute.</a>',
                            input: {
                                name: 'rvalwindow_new',
                                type: 'number',
                                rounding: 1,
                                min: 1,
                                max: 5,
                                title: "New Window R-Value (F-ft\u00B2-hr/BTU)",
                                hideifoptions: {
                                    'wthz': {
                                        type: 'notin',
                                        values: [
                                            'Yes'
                                        ]
                                    }
                                }
                            }
                        },
                        {
                            title: 'New ACH50',
                            tippyText: 'This is the air changes per hour the home experiences when subjected to a 50 pascal pressure difference compared with the outside after air sealing the home. This is a common industry reference point to measure the home\’s air leakage using a "blower door" test, with a lower number indicating a more tightly sealed home. The default is estimated based on a percent reduction in air leakage from the current ACH50.',
                            input: {
                                name: 'ach50_new',
                                type: 'number',
                                rounding: 1,
                                min: 1,
                                maxfnc: (formData) => {
                                   return formData?.ach50_current ?? ''
                                },
                                title: "New ACH50",
                                hideifoptions: {
                                    'wthz': {
                                        type: 'notin',
                                        values: [
                                            'Yes'
                                        ]
                                    }
                                }
                            }
                        }
                    ]
                }
            ]
        },
        {
            title: 'Space Cooling and Heating',
            image: spaceCoolingHeating,
            inputs: [
                {
                    title: 'Green Upgrade - Air-Source Heat Pump',
                    textStart: true,
                    secondary: true,
                    subtitle: true,
                },
                {
                    title: 'Number of Outdoor Units',
                    tippyText: 'This is the number of outdoor condensing units needed to heat and cool the home. Most homes will need only one outdoor unit. The default value is calculated based on the home\’s peak cooling load and, if a whole-home heat pump, design heating load, explained further in the FAQ section.',
                    input: {
                        name: 'ashpoutdoorunits',
                        type: 'select',
                        title: "Number of Outdoor Units",
                        hideifoptions: {
                            'ashp': {
                                type: 'notin',
                                values: [
                                    'Yes'
                                ]
                            }
                        },

                        values: [
                            { title: '1', value: '1' },
                            { title: '2', value: '2' },
                        ]
                    }
                },
                {
                    title: 'Size per Outdoor Unit (Tons)',
                    tippyText: 'This is the size of the outdoor condensing unit(s) needed to heat and cool the home. The default value is calculated based on the home\’s peak cooling load and, if a whole-home heat pump, design heating load, explained further in the FAQ section.',
                    input: {
                        name: 'ashpsize',
                        type: 'select',
                        title: "Size per Outdoor Unit (Tons)",
                        hideifoptions: {
                            'ashp': {
                                type: 'notin',
                                values: [
                                    'Yes'
                                ]
                            }
                        },
                        referenceText: {
                            text: `For reference, this heat pump size is estimated to cover [ashp_calculated_heatloadservedwswitchoff]% of your home's annual heating load, with the rest covered by a backup heating element.`,
                            modifiedVariables: {
                                "ashp_calculated_heatloadservedwswitchoff": {
                                    rounding: 0,
                                    multiplier: 100,
                                }
                            },
                            condition: (formData) => {
                                return formData["zip-greater-than-four"];
                            }
                        },
                        values: [
                            { title: '1.0', value: '1' },
                            { title: '1.5', value: '1.5' },
                            { title: '2.0', value: '2' },
                            { title: '2.5', value: '2.5' },
                            { title: '3.0', value: '3' },
                            { title: '3.5', value: '3.5' },
                            { title: '4.0', value: '4' },
                            { title: '4.5', value: '4.5' },
                            { title: '5.0', value: '5' },
                        ]
                    }
                },
                {
                    title: 'Number of Indoor Mini-Split Units',
                    tippyText: 'This is the number of window air conditioning units used in the home. The default value is estimated based on the peak cooling load and size per window unit.',
                    input: {
                        name: 'ashpindoorunits',
                        type: 'select',
                        title: "Number of Indoor Mini-Split Units",
                        hideifoptions: {
                            'ashp': {
                                type: 'notin',
                                values: [
                                    'Yes'
                                ]
                            },
                            'ashpducting': {
                                type: 'notin',
                                values: [
                                    'Ductless Mini Split'
                                ]
                            }
                        },

                        values: (formData) => {
                            if (!formData || !formData?.ashpsize)
                                return [
                                    { title: '2', value: '2' },
                                    { title: '3', value: '3' },
                                    { title: '4', value: '4' },
                                    { title: '5', value: '5' },
                                ];

                            switch (formData?.ashpsize) {

                                case "1":
                                    return [
                                        { title: '1', value: '1' },
                                    ];
                                default:
                                    return [
                                        { title: '2', value: '2' },
                                        { title: '3', value: '3' },
                                        { title: '4', value: '4' },
                                        { title: '5', value: '5' },
                                    ];
                            }
                        },
                    }
                },
                {
                    title: 'Listed HSPF2',
                    tippyText: 'This is the manufacturer-reported heating seasonal performance factor, reflecting the efficiency of the unit over a representative heating season. HSPF2 includes an updated testing procedure, effective January 1, 2023, to better reflect field conditions. The default value is 8 for ducted systems and 8.5 for ductless systems in warmer climates and 9 and 9.5, respectively, in colder climates, based on typical systems.',
                    input: {
                        name: 'ashp_HSPF',
                        type: 'number',
                        rounding: 1,
                        min: 5,
                        max: 20,
                        title: "Listed HSPF2",
                        hideifoptions: {
                            'ashp': {
                                type: 'notin',
                                values: [
                                    'Yes'
                                ]
                            }
                        }
                    }
                },
                {
                    title: 'Listed SEER2',
                    tippyText: 'This is the manufacturer-reported seasonal energy efficiency ratio, reflecting the efficiency of the unit over a representative cooling season. SEER2 includes an updated testing procedure, effective January 1, 2023, to better reflect field conditions. The default value is 17 for ducted systems and 19 for ductless systems in warmer climates and 16 and 18, respectively, in colder climates, based on typical systems.',
                    input: {
                        name: 'ashp_SEER',
                        type: 'number',
                        rounding: 1,
                        min: 10,
                        max: 40,
                        title: "Listed SEER2",
                        hideifoptions: {
                            'ashp': {
                                type: 'notin',
                                values: [
                                    'Yes'
                                ]
                            }
                        }
                    }
                },
                {
                    title: 'Heat Pump Switch Off Temperature Setting (°F)',
                    tippyText: "This is the temperature at which the air-source heat pump will turn off completely, leaving the supplemental heating unit to heat the home by itself. This is not the air-source heat pump's minimum functional operating temperature, but rather an optional air-source heat pump setting preference to turn off the unit during very cold hours when the heat pump is less efficient. The default value does not include a switch-off temperature setting for a whole-home heat pump but does include a default setting of 20°F for a hybrid heat pump.",
                    input: {
                        name: 'ashpswitchtemp',
                        type: 'number',
                        rounding: 0,
                        min: -30,
                        max: 45,
                        title: 'Heat Pump Switch Off Temperature Setting (°F)',
                        hideifoptions: {
                            "spaceheatspmt_cleanertech": {
                                type: 'notin',
                                values: [
                                    "Hybrid Heat Pump"
                                ]
                            }
                        }
                    }
                },
                {
                    title: 'Traditional Replacement - [ac_current]',
                    textStart: true,
                    secondary: true,
                    subtitle: true,
                },
                {
                    title: 'Number of Outdoor Units',
                    tippyText: 'This is the number of outdoor condensing units needed to cool the home. Most homes will need only one outdoor unit. The default value is calculated based on the home\’s peak cooling load, explained further in the FAQ section.',
                    input: {
                        name: 'acbau_units',
                        type: 'select',
                        title: "Number of Outdoor Units",
                        hideifoptions: {
                            'ashp': {
                                type: 'notin',
                                values: [
                                    'Yes'
                                ]
                            },
                            'ac_current': {
                                type: 'in',
                                values: [
                                    'No Mechanical Cooling',
                                    'Window AC'
                                ]
                            }
                        },

                        values: [
                            { title: '1', value: '1' },
                            { title: '2', value: '2' },
                        ]
                    }
                },
                {
                    title: 'Size per Window Unit (Tons)',
                    tippyText: 'This is the size of the window cooling unit(s) needed to cool the home. The default value is calculated based on the home’s peak cooling load and number of window units, explained further in the FAQ section.',
                    input: {
                        name: 'acbau_size',
                        type: 'select',
                        title: "Size per Window Unit (Tons)",
                        hideifoptions: {
                            'ashp': {
                                type: 'notin',
                                values: [
                                    'Yes'
                                ]
                            },
                            'ac_current': {
                                type: 'notin',
                                values: [
                                    'Window AC'
                                ]
                            }
                        },

                        values: [
                            { title: '1.0', value: '1' },
                            { title: '1.5', value: '1.5' },
                            { title: '2.0', value: '2' },
                            { title: '2.5', value: '2.5' },
                            { title: '3.0', value: '3' },
                            { title: '3.5', value: '3.5' },
                            { title: '4.0', value: '4' },
                            { title: '4.5', value: '4.5' },
                            { title: '5.0', value: '5' },
                        ]
                    }
                },
                {
                    title: 'Size per Outdoor Unit (Tons)',
                    tippyText: 'This is the size of the outdoor condensing unit(s) needed to cool the home. The default value is calculated based on the home\’s peak cooling load, explained further in the FAQ section.',
                    input: {
                        name: 'acbau_size',
                        type: 'select',
                        title: "Size per Outdoor Unit (Tons)",
                        hideifoptions: {
                            'ashp': {
                                type: 'notin',
                                values: [
                                    'Yes'
                                ]
                            },
                            'ac_current': {
                                type: 'in',
                                values: [
                                    'No Mechanical Cooling',
                                    'Window AC'
                                ]
                            }
                        },

                        values: [
                            { title: '1.0', value: '1' },
                            { title: '1.5', value: '1.5' },
                            { title: '2.0', value: '2' },
                            { title: '2.5', value: '2.5' },
                            { title: '3.0', value: '3' },
                            { title: '3.5', value: '3.5' },
                            { title: '4.0', value: '4' },
                            { title: '4.5', value: '4.5' },
                            { title: '5.0', value: '5' },
                        ]
                    }
                },
                {
                    title: 'Number of Window Units',
                    tippyText: 'This is the number of window air conditioning units used in the home. The default value is estimated based on the peak cooling load and size per window unit.',
                    input: {
                        name: 'acbau_indoorunits',
                        type: 'select',
                        title: "Number of Window Units",
                        hideifoptions: {
                            'ashp': {
                                type: 'notin',
                                values: [
                                    'Yes'
                                ]
                            },
                            'ac_current': {
                                type: 'notin',
                                values: [
                                    'Window AC'
                                ]
                            }
                        },

                        values: (formData) => {
                            if (!formData || !formData?.acbau_size)
                                return [
                                    { title: '2', value: '2' },
                                    { title: '3', value: '3' },
                                    { title: '4', value: '4' },
                                    { title: '5', value: '5' },
                                ];

                            switch (formData?.acbau_size) {

                                case "1":
                                    return [
                                        { title: '1', value: '1' },
                                    ];
                                default:
                                    return [
                                        { title: '2', value: '2' },
                                        { title: '3', value: '3' },
                                        { title: '4', value: '4' },
                                        { title: '5', value: '5' },
                                    ];
                            }
                        },
                    }
                },
                {
                    title: 'Listed SEER2',
                    tippyText: 'This is the manufacturer-reported seasonal energy efficiency ratio, reflecting the efficiency of the unit over a representative cooling season. SEER2 includes an updated testing procedure, effective January 1, 2023, to better reflect field conditions. The default value is the same as the air-source heat pump assumption: 17 for ducted systems and 19 for ductless systems in warmer climates and 16 and 18, respectively, in colder climates, based on typical systems.',
                    input: {
                        name: 'acbau_SEER',
                        type: 'number',
                        rounding: 1,
                        min: 10,
                        max: 40,
                        title: 'Listed SEER2',
                        hideifoptions: {
                            'ashp': {
                                type: 'notin',
                                values: [
                                    'Yes'
                                ]
                            },
                            'ac_current': {
                                type: 'in',
                                values: [
                                    'No Mechanical Cooling',
                                    'Ductless Mini Split Heat Pump',
                                    'Ducted Central Heat Pump'
                                ]
                            }
                        }
                    }
                },
                {
                    title: 'Traditional Replacement - [spaceheat_current]',
                    textStart: true,
                    secondary: true,
                    subtitle: true,
                },
                {
                    title: '[spaceheat_current] Size (Tons)',
                    tippyText: 'This is the maximum heating capacity of the space-heating equipment. The default value is based on the user\’s maximum heating load and typical space-heating equipment sizes.',
                    input: {
                        name: 'spaceheatbausize',
                        type: 'number',
                        rounding: 1,
                        min: 0,
                        max: 50,
                        title: "[spaceheat_current] Size (Tons)",
                        hideifoptions: {
                            'ashp': {
                                type: 'notin',
                                values: [
                                    'Yes'
                                ]
                            }
                        }
                    }
                },
                {
                    title: 'Listed AFUE',
                    tippyText: 'This is the manufacturer-reported annual fuel utilization efficiency for the traditional replacement for space heating, expressed as a percentage to allow for easier comparison with the efficiency of other appliances. The default value is based on the value needed for the selected equipment to meet Energy Star requirements.',
                    input: {
                        title: 'Listed AFUE',
                        name: 'spaceheatbau_afue',
                        type: 'number',
                        rounding: 2,
                        min: 0.7,
                        max: 1,
                        step: 0.1,
                        hideifoptions: {
                            'ashp': {
                                type: 'notin',
                                values: [
                                    'Yes'
                                ]
                            }
                        }
                    }
                },
            ],
            dropdowns: [
                {
                    title: "Show Advanced Inputs",
                    inputs: [
                        {
                            title: 'Green Upgrade - Air-Source Heat Pump',
                            secondary: true,
                            subtitle: true,
                        },
                        {
                            title: 'Max Cooling Capacity at 95°F (Tons)',
                            tippyText: 'This is the maximum cooling capacity of the air-source heat pump at the commonly reported temperature of 95°F. The default value is based on an average value for a set of common air-source heat pumps in Northeast Energy Efficiency Partnerships\’ <a href="https://ashp.neep.org/" target="__blank">Air Source Heat Pump database</a> corresponding to the size of the outdoor unit and ducting type selected.',
                            input: {
                                name: 'ashp_maxcap95F',
                                type: 'number',
                                rounding: 1,
                                min: 0,
                                max: 6,
                                title: "Max Cooling Capacity at 95°F (Tons)",
                                hideifoptions: {
                                    'ashp': {
                                        type: 'notin',
                                        values: [
                                            'Yes'
                                        ]
                                    }
                                }
                            }
                        },
                        {
                            title: 'Max Cooling Capacity at 82°F (Tons)',
                            tippyText: 'This is the maximum cooling capacity of the air-source heat pump at the commonly reported temperature of 82°F. The default value is based on an average value for a set of common air-source heat pumps in Northeast Energy Efficiency Partnerships\’ <a href="https://ashp.neep.org/" target="__blank">Air Source Heat Pump database</a> corresponding to the size of the outdoor unit and ducting type selected.',
                            input: {
                                name: 'ashp_maxcap82F',
                                type: 'number',
                                rounding: 1,
                                min: 0,
                                max: 6,
                                title: "Max Cooling Capacity at 82°F (Tons)",
                                hideifoptions: {
                                    'ashp': {
                                        type: 'notin',
                                        values: [
                                            'Yes'
                                        ]
                                    }
                                }
                            }
                        },
                        {
                            title: 'Max Heating Capacity at 47°F (Tons)',
                            tippyText: 'This is the maximum heating capacity of the air-source heat pump at the commonly reported temperature of 47°F. The default value is based on an average value for a set of common air-source heat pumps in Northeast Energy Efficiency Partnerships\’ <a href="https://ashp.neep.org/" target="__blank">Air Source Heat Pump database</a> corresponding to the size of the outdoor unit and ducting type selected.',                     input: {
                                name: 'ashp_maxcap47F',
                                type: 'number',
                                rounding: 1,
                                min: 0,
                                max: 6,
                                title: "Max Heating Capacity at 47°F (Tons)",
                                hideifoptions: {
                                    'ashp': {
                                        type: 'notin',
                                        values: [
                                            'Yes'
                                        ]
                                    }
                                }
                            }
                        },
                        {
                            title: 'Max Heating Capacity at 17°F (Tons)',
                            tippyText: 'This is the maximum heating capacity of the air-source heat pump at the commonly reported temperature of 17°F. The default value is based on an average value for a set of common air-source heat pumps in Northeast Energy Efficiency Partnerships\’ <a href="https://ashp.neep.org/" target="__blank">Air Source Heat Pump database</a> corresponding to the size of the outdoor unit and ducting type selected.',
                            input: {
                                name: 'ashp_maxcap17F',
                                type: 'number',
                                rounding: 1,
                                min: 0,
                                max: 6,
                                title: "Max Heating Capacity at 17°F (Tons)",
                                hideifoptions: {
                                    'ashp': {
                                        type: 'notin',
                                        values: [
                                            'Yes'
                                        ]
                                    }
                                }
                            }
                        },
                        {
                            title: 'Max Heating Capacity at 5°F (Tons)',
                            tippyText: 'This is the maximum heating capacity of the air-source heat pump at the commonly reported temperature of 5°F. The default value is based on an average value for a set of common air-source heat pumps in Northeast Energy Efficiency Partnerships\’ <a href="https://ashp.neep.org/" target="__blank">Air Source Heat Pump database</a> corresponding to the size of the outdoor unit and ducting type selected.',
                            input: {
                                name: 'ashp_maxcap5F',
                                type: 'number',
                                rounding: 1,
                                min: 0,
                                max: 6,
                                title: "Max Heating Capacity at 5°F (Tons)",
                                hideifoptions: {
                                    'ashp': {
                                        type: 'notin',
                                        values: [
                                            'Yes'
                                        ]
                                    }
                                }
                            }
                        },
                        {
                            title: 'Effective Heating Efficiency (%)',
                            tippyText: 'This is the air-source heat pump’s calculated load-weighted average efficiency across the heating season, expressed as a percentage to allow for easier comparison with the efficiency of other appliances. Hourly efficiency is based on reported air-source heat pump efficiency performance at the specified temperatures. See the FAQ for more detail on how this default value is calculated.',
                            input: {
                                name: 'ashpheatingeff',
                                type: 'number',
                                rounding: 0,
                                min: 100,
                                max: 1000,
                                multiplier: 100,
                                title: 'Effective Heating Efficiency (%)',
                                hideifoptions: {
                                    'ashp': {
                                        type: 'notin',
                                        values: [
                                            'Yes'
                                        ]
                                    }
                                }
                            }
                        },
                        {
                            title: 'Effective Cooling Efficiency (%)',
                            tippyText: 'This is the air-source heat pump\’s calculated load-weighted average efficiency across the cooling season, expressed as a percentage to allow for easier comparison with the efficiency of other appliances. Hourly efficiency is based on reported air-source heat pump efficiency performance at the specified temperatures. See the FAQ for more detail on the hourly efficiency calculation.',
                            input: {
                                name: 'ashpcoolingeff',
                                type: 'number',
                                rounding: 0,
                                min: 100,
                                max: 1000,
                                multiplier: 100,
                                title: 'Effective Cooling Efficiency (%)',
                                hideifoptions: {
                                    'ashp': {
                                        type: 'notin',
                                        values: [
                                            'Yes'
                                        ]
                                    }
                                }
                            }
                        },
                        {
                            title: 'Green Upgrade - Supplemental Heating',
                            textStart: true,
                            secondary: true,
                            subtitle: true,
                        },
                        {
                            title: 'Listed AFUE',
                            tippyText: 'This is the manufacturer-reported annual fuel utilization efficiency for the supplemental heating system to the air-source heat pump, expressed as a percentage to allow for easier comparison with the efficiency of other appliances. The default value is based on the value needed for the selected equipment to meet Energy Star requirements.',
                            input: {
                                name: 'spaceheatspmt_cleaner_afue',
                                type: 'number',
                                rounding: 2,
                                min: 0.7,
                                max: 1,
                                step: 0.1,
                                title: 'Listed AFUE',
                                hideifoptions: {
                                    'ashp': {
                                        type: 'notin',
                                        values: [
                                            'Yes'
                                        ]
                                    },
                                    'spaceheatspmt_cleanertech': {
                                        type: 'false'
                                    }
                                }
                            }
                        },
                        {
                            title: 'Effective Heating Efficiency (%)',
                            tippyText: 'This is the calculated effective efficiency of the space-heating equipment after factoring in system losses. See the FAQ for more detail on how this default value is calculated.',
                            input: {
                                name: 'spaceheatspmt_cleaner_eff',
                                type: 'number',
                                rounding: 0,
                                min: 0.5,
                                max: 100,
                                step: 0.1,
                                multiplier: 100,
                                title: 'Effective Efficiency (%)',
                                
                                hideifoptions: {
                                    'ashp': {
                                        type: 'notin',
                                        values: [
                                            'Yes'
                                        ]
                                    },
                                    'spaceheatspmt_cleanertech': {
                                        type: 'false'
                                    }
                                }
                            }
                        },
                        {
                            title: 'Traditional Replacement - [ac_current]',
                            textStart: true,
                            secondary: true,
                            subtitle: true,
                        },
                        {
                            title: 'Max Cooling Capacity at 95°F (Tons)',
                            tippyText: 'This is the maximum cooling capacity of the air conditioner at 95°F. The default value is based on an average value for air-source heat pumps in Northeast Energy Efficiency Partnerships\’ <a href="https://ashp.neep.org/" target="__blank">Air Source Heat Pump database</a> corresponding to the size of the outdoor unit and ducting type selected. Air-Source heat pump values are used so the default traditional replacement and green upgrade efficiencies are the same for cooling load.',
                            input: {
                                name: 'acbau_maxcap95F',
                                type: 'number',
                                rounding: 1,
                                min: 0,
                                max: 6,
                                title: 'Max Cooling Capacity at 95°F (Tons)',
                                hideifoptions: {
                                    'ashp': {
                                        type: 'notin',
                                        values: [
                                            'Yes'
                                        ]
                                    },
                                    'ac_current': {
                                        type: 'in',
                                        values: [
                                            'No Mechanical Cooling',
                                            'Ductless Mini Split Heat Pump',
                                            'Ducted Central Heat Pump'
                                        ]
                                    }
                                }
                            }
                        },
                        {
                            title: 'Max Cooling Capacity at 82°F (Tons)',
                            tippyText: 'This is the maximum cooling capacity of the air conditioner at 82°F. The default value is based on an average value for air-source heat pumps in Northeast Energy Efficiency Partnerships\’  <a href="https://ashp.neep.org/" target="__blank">Air Source Heat Pump database</a> corresponding to the size of the outdoor unit and ducting type selected. Air-Source heat pump values are used so the default traditional replacement and green upgrade efficiencies are the same for cooling load.',
                            input: {
                                name: 'acbau_maxcap82F',
                                type: 'number',
                                rounding: 1,
                                min: 0,
                                max: 6,
                                title: 'Max Cooling Capacity at 82°F (Tons)',
                                hideifoptions: {
                                    'ashp': {
                                        type: 'notin',
                                        values: [
                                            'Yes'
                                        ]
                                    },
                                    'ac_current': {
                                        type: 'in',
                                        values: [
                                            'No Mechanical Cooling',
                                            'Ductless Mini Split Heat Pump',
                                            'Ducted Central Heat Pump'
                                        ]
                                    }
                                }
                            }
                        },
                        {
                            title: 'Max Heating Capacity at 47°F (Tons)',
                            tippyText: 'This is the maximum heating capacity of the air-source heat pump at the commonly reported temperature of 47° F. The default value is based on an average value for a set of common air-source heat pumps in Northeast Energy Efficiency Partnerships’ <a href="https://ashp.neep.org/" target="__blank">ASHP database</a> corresponding to the size of the outdoor unit and ducting type selected. ',
                            input: {
                                name: 'ashpbau_maxcap47F',
                                type: 'number',
                                min: 0,
                                max: 6,
                                title: 'Max Heating Capacity at 47°F (Tons)',
                                hideifoptions: {
                                    'ashp': {
                                        type: 'notin',
                                        values: [
                                            'Yes'
                                        ]
                                    },
                                    'ac_current': {
                                        type: 'notin',
                                        values: [
                                            'Ductless Mini Split Heat Pump',
                                            'Ducted Central Heat Pump'
                                        ]
                                    }
                                }
                            }
                        },
                        {
                            title: 'Max Heating Capacity at 17°F (Tons)',
                            tippyText: 'This is the maximum heating capacity of the air-source heat pump at the commonly reported temperature of 17°F. The default value is based on an average value for a set of common air-source heat pumps in Northeast Energy Efficiency Partnerships’ <a href="https://ashp.neep.org/" target="__blank">Air Source Heat Pump database</a> corresponding to the size of the outdoor unit and ducting type selected.',
                            input: {
                                name: 'ashpbau_maxcap17F',
                                type: 'number',
                                min: 0,
                                max: 6,
                                title: 'Max Heating Capacity at 17°F (Tons)',
                                hideifoptions: {
                                    'ashp': {
                                        type: 'notin',
                                        values: [
                                            'Yes'
                                        ]
                                    },
                                    'ac_current': {
                                        type: 'notin',
                                        values: [
                                            'Ductless Mini Split Heat Pump',
                                            'Ducted Central Heat Pump'
                                        ]
                                    }
                                }
                            }
                        },
                        {
                            title: 'Max Heating Capacity at 5°F (Tons)',
                            tippyText: 'This is the maximum heating capacity of the air-source heat pump at the commonly reported temperature of 5° F. The default value is based on an average value for a set of common air-source heat pumps in Northeast Energy Efficiency Partnerships’ <a href="https://ashp.neep.org/" target="__blank">ASHP database</a> corresponding to the size of the outdoor unit and ducting type selected.',
                            input: {
                                name: 'ashpbau_maxcap5F',
                                type: 'number',
                                min: 0,
                                max: 6,
                                rounding: 1,
                                title: 'Max Heating Capacity at 5°F (Tons)',
                                hideifoptions: {
                                    'ashp': {
                                        type: 'notin',
                                        values: [
                                            'Yes'
                                        ]
                                    },
                                    'ac_current': {
                                        type: 'notin',
                                        values: [
                                            'Ductless Mini Split Heat Pump',
                                            'Ducted Central Heat Pump'
                                        ]
                                    }
                                }
                            }
                        },
                        {
                            title: 'Effective Cooling Efficiency (%)',
                            tippyText: 'This is the air conditioner\’s calculated load-weighted average efficiency across the cooling season, expressed as a percentage to allow for easier comparison with the efficiency of other appliances. Hourly efficiency is based on reported air-source heat pump efficiency performance at the specified temperatures. See the FAQ for more detail on the hourly efficiency calculation.',
                            input: {
                                name: 'acbau_eff',
                                type: 'number',
                                rounding: 0,
                                min: 100,
                                max: 1000,
                                multiplier: 100,
                                title: 'Effective Cooling Efficiency (%)',
                                hideifoptions: {
                                    'ashp': {
                                        type: 'notin',
                                        values: [
                                            'Yes'
                                        ]
                                    },
                                    'ac_current': {
                                        type: 'in',
                                        values: [
                                            'No Mechanical Cooling',
                                            'Ductless Mini Split Heat Pump',
                                            'Ducted Central Heat Pump'
                                        ]
                                    }
                                }
                            }
                        },
                        {
                            title: 'Listed HSPF2',
                            tippyText: 'This is the manufacturer-reported heating seasonal performance factor, reflecting the efficiency of the unit over a representative heating season. HSPF2 includes an updated testing procedure, effective January 1, 2023, to better reflect field conditions. The default value is based on an average HSPF2 value from a set of common air-source heat pumps in Northeast Energy Efficiency Partnerships’ <a href="https://ashp.neep.org/" target="__blank">Air Source Heat Pump database</a> corresponding to the ducting type selected. If only HSPF is reported, the calculator integrates estimated HSPF2 values as needed.',
                            input: {
                                name: 'ashpbau_hspf',
                                type: 'number',
                                rounding: 1,
                                min: 5,
                                max: 20,
                                title: 'Listed HSPF2',
                                hideifoptions: {
                                    'ashp': {
                                        type: 'notin',
                                        values: [
                                            'Yes'
                                        ]
                                    },
                                    'ac_current': {
                                        type: 'notin',
                                        values: [
                                            'Ductless Mini Split Heat Pump',
                                            'Ducted Central Heat Pump'
                                        ]
                                    }
                                }
                            }
                        },
                        {
                            title: 'Effective Heating Efficiency (%)',
                            input: {
                                name: 'ashpbau_heatingeff',
                                type: 'number',
                                rounding: 0,
                                min: 1,
                                max: 6,
                                multiplier: 100,
                                title: 'Effective Heating Efficiency (%)',
                                hideifoptions: {
                                    'ashp': {
                                        type: 'notin',
                                        values: [
                                            'Yes'
                                        ]
                                    },
                                    'ac_current': {
                                        type: 'notin',
                                        values: [
                                            'Ductless Mini Split Heat Pump',
                                            'Ducted Central Heat Pump'
                                        ]
                                    }
                                }
                            }
                        },
                        {
                            title: 'Heat Pump Switch Off Temperature Setting (°F)',
                            tippyText: 'This is the temperature at which the air-source heat pump will turn off completely, leaving the supplemental heating unit to heat the home by itself. This is not the air-source heat pump`s minimum functional operating temperature, rather an optional air-source heat pump setting preference to turn off the unit during very cold hours when the heat pump is less efficient. The default value does not include a switch off temperature setting, and thus the air-source heat pump operates down to its minimum functional temperature.',
                            input: {
                                name: 'ashpbau_switchtemp',
                                type: 'number',
                                rounding: 0,
                                min: -30,
                                max: 45,
                                title: 'Heat Pump Switch Off Temperature Setting (°F)',
                                hideifoptions: {
                                    'ashp': {
                                        type: 'notin',
                                        values: [
                                            'Yes'
                                        ]
                                    },
                                    'ac_current': {
                                        type: 'notin',
                                        values: [
                                            'Ductless Mini Split Heat Pump',
                                            'Ducted Central Heat Pump'
                                        ]
                                    }
                                }
                            }
                        },
                        {
                            title: 'Traditional Replacement - [spaceheat_current]',
                            textStart: true,
                            secondary: true,
                            subtitle: true,
                        },
                        {
                            title: 'Effective Heating Efficiency (%)',
                            tippyText: 'This is the calculated effective efficiency of the space-heating equipment after factoring in system losses. See the FAQ for more detail on how this default value is calculated.',
                            input: {
                                title: 'Effective Heating Efficiency (%)',
                                name: 'spaceheatbau_eff',
                                type: 'number',
                                rounding: 0,
                                min: 50,
                                max: 100,
                                multiplier: 100,
                                hideifoptions: {
                                    'ashp': {
                                        type: 'notin',
                                        values: [
                                            'Yes'
                                        ]
                                    }
                                }
                            }
                        },
                    ]
                }
            ]
        },
        {
            title: 'Water Heating',
            image: waterHeating,
            inputs: [
                {
                    title: "Green Upgrade - Electric Heat Pump Water Heater",
                    secondary: true,
                    subtitle: true,
                },
                {
                    title: 'Tank Size (Gallons)',
                    tippyText: 'This is the size, in gallons, of the electric heat pump water heater selected. The default value is one size up from the tank size of the traditional water heater selected, or 50 gallons if the traditional water heater is tankless.',
                    input: {
                        name: 'hpwhsize',
                        type: 'select',
                        values: [
                            {
                                title: '50',
                                value: 50
                            },
                            {
                                title: '65',
                                value: 65
                            },
                            {
                                title: '80',
                                value: 80
                            }
                        ],
                        title: "Tank Size (Gallons)",
                        hideifoptions: {
                            'hpwh': {
                                type: 'notin',
                                values: [
                                    'Yes'
                                ]
                            }
                        }
                    }
                },
                {
                    title: 'Listed UEF',
                    tippyText: 'This is the uniform energy factor for the electric heat pump water heater, expressed as a percentage to better compare with the efficiency of other appliances. The default value of 3.7 is based on common heat pump water heaters in the market today, with values ranging from 3.5 to 4.0.',
                    input: {
                        name: 'hpwhuef',
                        type: 'number',
                        rounding: 2,
                        min: 2,
                        max: 5,
                        step: 0.1,
                        title: "Listed UEF",
                        hideifoptions: {
                            'hpwh': {
                                type: 'notin',
                                values: [
                                    'Yes'
                                ]
                            }
                        }
                    }
                },
                {
                    title: 'Operating Mode',
                    tippyText: 'This is the selected operating mode of the electric heat pump water heater. “Efficiency” mode is recommended as it heats the water using solely the heat pump compressor for the most efficient operation. “Hybrid” mode uses electric resistance to heat the water during times when the heat pump compressor does not keep the hot water at its temperature set point.',
                    input: {
                        name: 'hpwhmode',
                        type: 'select',
                        values: [
                            {
                                title: 'Efficiency',
                                value: 'Efficiency'
                            },
                            {
                                title: 'Hybrid',
                                value: 'Hybrid'
                            }
                        ],
                        title: "Operating Mode",
                        hideifoptions: {
                            'hpwh': {
                                type: 'notin',
                                values: [
                                    'Yes'
                                ]
                            }
                        }
                    }
                },
                {
                    title: "Traditional Replacement - [wh_current]",
                    textStart: true,
                    secondary: true,
                    subtitle: true,
                },
                {
                    title: 'Tank Size (Gallons)',
                    tippyText: 'This is the size, in gallons, of the traditional water heater replacement selected. The default value is based on the conditioned home area.',
                    input: {
                        name: 'whbausize',
                        type: 'select',
                        title: 'Tank Size (Gallons)',
                        hideifoptions: {
                            'hpwh': {
                                type: 'notin',
                                values: [
                                    'Yes'
                                ]
                            }, 
                            "wh_current": {
                                type: 'in',
                                values: [
                                    "Tankless Natural Gas Water Heater",
                                    "Tankless Electric Water Heater"
                                ]
                            }
                        },
                        
                        values: (formData) => {
                            if (!formData || !formData?.wh_current)
                                return [];

                            switch (formData?.wh_current) {

                                case "Tankless Natural Gas Water Heater":
                                    return [
                                        {
                                            title: "Tankless",
                                            value: 'Tankless'
                                        },
                                    ];
                                case "Tankless Electric Water Heater":
                                    return [
                                        {
                                            title: "Tankless",
                                            value: 'Tankless'
                                        },
                                    ];
                                default:
                                    return [
                                        {
                                            title: "40",
                                            value: '40'
                                        },
                                        {
                                            title: "50",
                                            value: '50'
                                        },
                                        {
                                            title: "80",
                                            value: '80'
                                        }
                                    ];
                            }
                        },
                    }
                },
                {
                    title: 'Listed UEF',
                    tippyText: 'This is the uniform energy factor for the traditional replacement water heater selected, expressed as a percentage to better compare with the efficiency of other appliances. The default value is based on the value needed for the selected equipment to meet Energy Star requirements.',
                    input: {
                        name: 'whbauuef',
                        type: 'number',
                        rounding: 2,
                        min: 0.5,
                        max: 5,
                        step: 0.1,
                        title: "Listed UEF",
                        hideifoptions: {
                            'hpwh': {
                                type: 'notin',
                                values: [
                                    'Yes'
                                ]
                            }
                        }
                    }
                },
            ],
            dropdowns: [
                {
                    title: "Show Advanced Inputs",
                    inputs: [
                        {
                            title: 'Water Heater Location',
                            tippyText: 'This is the location of the water heater in the home. Garage location options are not available in climate zones where the average annual temperature is lower than 50°F and outside locations are not available in climate zones where the minimum temperature is lower than 32°F. The default value is the most common water heater location in the state, sourced from the <a href="https://www.eia.gov/consumption/residential/data/2020/index.php?view=microdata" target="__blank">Energy Information Administration.</a>',
                            input: {
                                name: 'whlocation',
                                type: 'select',
                                title: "Water Heater Location",
                                values: (formData) => {
        
                                    const heaterLocationArray = [
                                        {
                                            title: "Living Area",
                                            value: "Living Area"
                                        },
                                        {
                                            title: "Basement",
                                            value: "Basement"
                                        }
                                    ];
                                    
                                    if(formData?.zipData?.oatAverage >= 50) {
                                        heaterLocationArray.push(
                                            {
                                                title: "Garage",
                                                value: "Garage"
                                            }
                                        );
                                    }
        
                                    if(formData?.zipData?.oatMin > 32) {
                                        heaterLocationArray.push(
                                            {
                                                title: "Outside",
                                                value: "Outside"
                                            }
                                        );
                                    }
        
                                    return heaterLocationArray;
                                },
                                hideifoptions: {
                                    'hpwh': {
                                        type: 'notin',
                                        values: [
                                            'Yes'
                                        ]
                                    }
                                }
                            }
                        },
                        {
                            title: 'Green Upgrade - Electric Heat Pump Water Heater',
                            textStart: true,
                            secondary: true,
                            subtitle: true,
                        },
                        {
                            title: 'Effective Efficiency (%)',
                            tippyText: 'This is the real-world annual load-weighted average efficiency of the electric heat pump water heater. The default value is determined based on the hourly efficiency calculations described further in the FAQ section.',
                            input: {
                                name: 'hpwheff',
                                type: 'number',
                                rounding: 0,
                                min: 200,
                                max: 500,
                                multiplier: 100,
                                title: "Effective Efficiency (%)",
                                hideifoptions: {
                                    'hpwh': {
                                        type: 'notin',
                                        values: [
                                            'Yes'
                                        ]
                                    }
                                }
                            }
                        },
                        {
                            title: 'Traditional Replacement - [wh_current]',
                            textStart: true,
                            secondary: true,
                            subtitle: true,
                        },
                        {
                            title: 'Effective Efficiency (%)',
                            tippyText: 'This is the real-world annual load-weighted average efficiency of the selected traditional replacement water heater. The default value applies a 10% reduction to the listed UEF for tank systems and 5% for tankless systems.',
                            input: {
                                name: 'whbaueff',
                                type: 'number',
                                rounding: 0,
                                min: 50,
                                max: 500,
                                multiplier: 100,
                                title: "Effective Efficiency (%)",
                                hideifoptions: {
                                    'hpwh': {
                                        type: 'notin',
                                        values: [
                                            'Yes'
                                        ]
                                    }
                                }
                            }
                        }
                    ]
                }
            ]
        },
        {
            title: "Cooking Appliance",
            image: cookingAppliance,
            inputs: [
                {
                    title: 'Oven Energy Usage out of Total Cooking Energy Usage (%)',
                    tippyText: 'This is the average oven energy consumption out of total oven and stove energy consumption. The default value is based on the average oven and stove consumption frequency per week in the state, sourced from the <a href="https://www.eia.gov/consumption/residential/data/2020/index.php?view=microdata" target="__blank">Energy Information Administration,</a> and assuming 15 minutes per stove use and 45 minutes per oven use.',
                    input: {
                        name: 'ovenrangeratio',
                        type: 'number',
                        rounding: 0,
                        min: 0,
                        max: 100,
                        multiplier: 100,
                        title: 'Oven Energy Usage out of Total Cooking Energy Usage (%)',
                        hideifoptions: {
                            'range': {
                                type: 'notin',
                                values: [
                                    'Yes'
                                ]
                            }
                        }
                    }
                },
                {
                    title: 'Green Upgrade - [cooking_cleaner]',
                    textStart: true,
                    secondary: true,
                    subtitle: true,
                },
                {
                    title: 'Oven Efficiency (%)',
                    tippyText: 'This is the efficiency of the electric oven element for the green upgrade. The default value of 70% is based on common efficiencies of electric ovens, sourced from <a href="https://www.energystar.gov/products/commercial_ovens" target="__blank">Energy Star.</a>',
                    input: {
                        name: 'cookingcleaneff_oven',
                        type: 'number',
                        rounding: 0,
                        min: 0,
                        max: 100,
                        multiplier: 100,
                        title: 'Oven Efficiency (%)',
                        hideifoptions: {
                            'range': {
                                type: 'notin',
                                values: [
                                    'Yes'
                                ]
                            },
                            'cooking_cleaner': {
                                type: 'notin',
                                values: [
                                    'Electric Induction Range',
                                    'Electric Induction Stovetop & Electric Wall Oven',
                                    'Electric Resistance Range',
                                    'Electric Resistance Stovetop & Electric Wall Oven',
                                    'Electric Wall Oven'
                                ]
                            }
                        }
                    }
                },
                {
                    title: 'Stovetop Efficiency (%)',
                    tippyText: 'This is the efficiency of the electric stovetop selected. The default is based on common efficiencies for the induction or electric resistance stovetop selected, sourced from <a href="https://www.energystar.gov/about/2021_residential_induction_cooking_tops" target="__blank">Energy Star.</a>',
                    input: {
                        name: 'cookingcleaneff_stove',
                        type: 'number',
                        rounding: 0,
                        min: 0,
                        max: 100,
                        multiplier: 100,
                        title: 'Stovetop Efficiency (%)',
                        hideifoptions: {
                            'range': {
                                type: 'notin',
                                values: [
                                    'Yes'
                                ]
                            },
                            'cooking_cleaner': {
                                type: 'notin',
                                values: [
                                    'Electric Induction Range',
                                    'Electric Induction Stovetop',
                                    'Electric Induction Stovetop & Electric Wall Oven',
                                    'Electric Resistance Range',
                                    'Electric Resistance Stovetop',
                                    'Electric Resistance Stovetop & Electric Wall Oven'
                                ]
                            }
                        }
                    }
                },
                {
                    title: 'Traditional Replacement - [cooking_current]',
                    secondary: true,
                    subtitle: true,
                    hidetitleIf: {
                        "cooking_current": {
                            type: 'in',
                            values: [
                                "I would not upgrade my stove"
                            ]
                        }
                    }
                },
                {
                    title: 'Oven Efficiency (%)',
                    tippyText: 'This is the efficiency of the traditional replacement of the oven. The default value is based on common efficiencies of the current oven technology and fuel source selected, sourced from <a href="https://www.energystar.gov/products/commercial_ovens" target="__blank">Energy Star.</a>',
                    input: {
                        name: 'cookingbaueff_oven',
                        type: 'number',
                        rounding: 0,
                        min: 0,
                        max: 100,
                        multiplier: 100,
                        title: "Oven Efficiency (%)",
                        hideifoptions: {
                            'range': {
                                type: 'notin',
                                values: [
                                    'Yes'
                                ]
                            },
                            'cooking_cleaner': {
                                type: 'notin',
                                values: [
                                    'Electric Induction Range',
                                    'Electric Induction Stovetop & Electric Wall Oven',
                                    'Electric Resistance Range',
                                    'Electric Resistance Stovetop & Electric Wall Oven',
                                    'Electric Wall Oven'
                                ]
                            },
                            "cooking_current": {
                                type: 'in',
                                values: [
                                    "I would not upgrade my stove"
                                ]
                            }
                        }
                    }
                },
                {
                    title: 'Stovetop Efficiency (%)',
                    tippyText: 'This is the efficiency of the traditional replacement stovetop. The default value is based on common efficiencies of the current stovetop technology and fuel source selected, sourced from <a href="https://www.energystar.gov/about/2021_residential_induction_cooking_tops" target="__blank">Energy Star.</a>',
                    input: {
                        name: 'cookingbaueff_stove',
                        type: 'number',
                        rounding: 0,
                        min: 0,
                        max: 100,
                        multiplier: 100,
                        title: "Stovetop Efficiency (%)",
                        hideifoptions: {
                            'range': {
                                type: 'notin',
                                values: [
                                    'Yes'
                                ]
                            },
                            'cooking_cleaner': {
                                type: 'notin',
                                values: [
                                    'Electric Induction Range',
                                    'Electric Induction Stovetop',
                                    'Electric Induction Stovetop & Electric Wall Oven',
                                    'Electric Resistance Range',
                                    'Electric Resistance Stovetop',
                                    'Electric Resistance Stovetop & Electric Wall Oven'
                                ]
                            },
                            "cooking_current": {
                                type: 'in',
                                values: [
                                    "I would not upgrade my stove"
                                ]
                            }
                        }
                    }
                },
                {
                    title: 'Traditional [cooking_current_noupgrade]',
                    secondary: true,
                    subtitle: true,
                    hidetitleIf: {
                        "cooking_current": {
                            type: 'notin',
                            values: [
                                "I would not upgrade my stove"
                            ]
                        }
                    }
                },
                {
                    title: 'Oven Efficiency (%)',
                    tippyText: 'This is the efficiency of the traditional replacement of the oven. The default value is based on common efficiencies of the current oven technology and fuel source selected, sourced from <a href="https://www.energystar.gov/products/commercial_ovens" target="__blank">Energy Star.</a>',
                    input: {
                        name: 'cookingbaueff_oven',
                        type: 'number',
                        rounding: 0,
                        min: 0,
                        max: 100,
                        multiplier: 100,
                        title: "Oven Efficiency (%)",
                        hideifoptions: {
                            'range': {
                                type: 'notin',
                                values: [
                                    'Yes'
                                ]
                            },
                            'cooking_cleaner': {
                                type: 'notin',
                                values: [
                                    'Electric Induction Range',
                                    'Electric Induction Stovetop & Electric Wall Oven',
                                    'Electric Resistance Range',
                                    'Electric Resistance Stovetop & Electric Wall Oven',
                                    'Electric Wall Oven'
                                ]
                            },
                            "cooking_current": {
                                type: 'notin',
                                values: [
                                    "I would not upgrade my stove"
                                ]
                            }
                        }
                    }
                },
                {
                    title: 'Stovetop Efficiency (%)',
                    tippyText: 'This is the efficiency of the traditional replacement stovetop. The default value is based on common efficiencies of the current stovetop technology and fuel source selected, sourced from <a href="https://www.energystar.gov/about/2021_residential_induction_cooking_tops" target="__blank">Energy Star.</a>',
                    input: {
                        name: 'cookingbaueff_stove',
                        type: 'number',
                        rounding: 0,
                        min: 0,
                        max: 100,
                        multiplier: 100,
                        title: "Stovetop Efficiency (%)",
                        hideifoptions: {
                            'range': {
                                type: 'notin',
                                values: [
                                    'Yes'
                                ]
                            },
                            'cooking_cleaner': {
                                type: 'notin',
                                values: [
                                    'Electric Induction Range',
                                    'Electric Induction Stovetop',
                                    'Electric Induction Stovetop & Electric Wall Oven',
                                    'Electric Resistance Range',
                                    'Electric Resistance Stovetop',
                                    'Electric Resistance Stovetop & Electric Wall Oven'
                                ]
                            },
                            "cooking_current": {
                                type: 'notin',
                                values: [
                                    "I would not upgrade my stove"
                                ]
                            }
                        }
                    }
                }
            ]
        },
        {
            title: "Motor Vehicle",
            image: motorVehicle,
            inputs: [
                {
                    title: 'Green Upgrade - [phevmake]',
                    secondary: true,
                    subtitle: true,
                },
                {
                    title: 'Green Upgrade - [phevmake] [phevmodel]',
                    secondary: true,
                    subtitle: true,
                },
                {
                    title: "Vehicle Electric Efficiency (kWh/mi)",
                    tippyText: 'This is the expected kilowatt-hours per mile efficiency of the plug-in hybrid electric vehicle selected when operating exclusively on the electric battery. The default value is sourced from the <a href="https://fueleconomy.gov/feg/" target="__blank">U.S. Department of Energy</a> and adjusted based on the hourly temperatures in the climate zone.',
                    input: {
                        name: 'pheveff',
                        type: 'number',
                        rounding: 2,
                        min: 0.05,
                        max: 0.95,
                        step: 0.01,
                        title: "Efficiency (kWh/mi)",
                        hideifoptions: {
                            'buy-electric-car': {
                                type: 'notin',
                                values: [
                                    'Yes'
                                ]
                            },
                            'vehicle_type': {
                                type: 'notin',
                                values: [
                                    'PHEV'
                                ]
                            }
                        },
                        referenceText: {
                            text: `For reference, this efficiency is equivalent to [phevmpge] miles per gallon.`,
                            condition: (formData) => {
                                return formData?.pheveff;
                            }
                        },
                    }
                },
                {
                    title: "Vehicle Electric Efficiency (kWh/mi)",
                    tippyText: 'This is the expected kilowatt-hours per mile efficiency of the electric vehicle selected. The default value is sourced from the <a href="https://fueleconomy.gov/feg/" target="__blank">U.S. Department of Energy</a> and adjusted based on the hourly temperatures in the climate zone.',
                    input: {
                        name: 'beveff',
                        type: 'number',
                        rounding: 2,
                        min: 0.05,
                        max: 0.95,
                        step: 0.01,
                        title: "Efficiency (kWh/mi)",
                        hideifoptions: {
                            'buy-electric-car': {
                                type: 'notin',
                                values: [
                                    'Yes'
                                ]
                            },
                            'vehicle_type': {
                                type: 'notin',
                                values: [
                                    'BEV'
                                ]
                            }
                        },
                        referenceText: {
                            text: `For reference, this efficiency is equivalent to [bevmpge] miles per gallon.`,
                            condition: (formData) => {
                                return formData?.beveff;
                            }
                        },
                    }
                },
                {
                    title: 'Vehicle Fuel Efficiency (mpg)',
                    tippyText: 'This is the expected miles per gallon efficiency of the plug-in hybrid electric vehicle selected once the battery has been depleted. The default value is sourced from the <a href="https://fueleconomy.gov/feg/" target="__blank">U.S. Department of Energy.</a>',
                    input: {
                        name: 'phevfueleff',
                        type: 'number',
                        rounding: 0,
                        min: 5,
                        max: 70,
                        title: "Fuel Efficiency (mpg)",
                        hideifoptions: {
                            'buy-electric-car': {
                                type: 'notin',
                                values: [
                                    'Yes'
                                ]
                            },
                            'vehicle_type': {
                                type: 'notin',
                                values: [
                                    'PHEV'
                                ]
                            }
                        }
                    }
                },
                {
                    title: 'Electric-Only Range (Miles)',
                    tippyText: 'This is the number of miles an individual can drive the plug-in hybrid electric vehicle exclusively using the electric battery. The default value is sourced from theThis is the number of miles an individual can drive the plug-in hybrid electric vehicle exclusively using the electric battery. The default value is sourced from the <a href="https://fueleconomy.gov/feg/" target="__blank">US Department of Energy.</a>',
                    input: {
                        name: 'phevelecmiles',
                        type: 'number',
                        rounding: 1,
                        min: 1,
                        max: 60,
                        title: "Electric Only Range (Miles)",
                        hideifoptions: {
                            'vehicle_type': {
                                type: 'notin',
                                values: [
                                    'PHEV'
                                ]
                            }
                        }
                    }
                },
                {
                    title: 'Miles Driven on Battery (%)',
                    tippyText: 'This is the percentage of miles an individual would drive the plug-in hybrid electric vehicle using the battery versus gasoline. The default value is calculated following the process outlined in the technical calculations section of the FAQ using historic US driving data sourced from the <a href="https://theicct.org/publication/real-world-phev-us-dec22/" target="__blank"> International Council on Clean Transportation.</a>',
                    input: {
                        name: 'phevutilityfactor',
                        type: 'number',
                        rounding: 0,
                        min: 0,
                        max: 100,
                        step: 0.1,
                        multiplier: 100,
                        title: "Miles Driven on Battery (%)",
                        hideifoptions: {
                            'vehicle_type': {
                                type: 'notin',
                                values: [
                                    'PHEV'
                                ]
                            }
                        }
                    }
                },
                {
                    title: 'Traditional Replacement - [ffvmake] [ffvmodel]',
                    textStart: true,
                    secondary: true,
                    subtitle: true,
                },
                {
                    title: 'Vehicle Fuel Efficiency (mpg)',
                    tippyText: 'This is the expected miles per gallon efficiency of the traditional replacement gasoline vehicle selected. The default value is sourced from the <a href="https://fueleconomy.gov/feg/" target="__blank">US Department of Energy.</a>',
                    input: {
                        name: 'ffveff',
                        type: 'number',
                        rounding: 0,
                        min: 5,
                        max: 80,
                        title: "Fuel Efficiency (mpg)",
                        hideifoptions: {
                            ...atLeastOneTransportUpgrade
                        }
                    }
                }
            ]
        },
        {
            title: 'Electric Bike',
            image: electricBike,
            inputs: [
                {
                    title: 'Electric Bike Type',
                    tippyText: 'A "Commuting" bike refers to a standard electric bike that would be commonly used for commuting, errands, and recreational riding. A "Cargo" bike refers to a larger electric bike typically used to transport individuals or for delivery purposes in replacement of a vehicle.',
                    input: {
                        name: 'ebiketype',
                        type: 'select',
                        values: [
                            {
                                title: 'Cargo',
                                value: 'Cargo'
                            },
                            {
                                title: 'Commuting',
                                value: 'Commuting'
                            }
                        ],
                        title: 'Electric Bike Type',
                        hideifoptions: {
                            'ebike': {
                                type: 'notin',
                                values: [
                                    'Yes'
                                ]
                            }
                        }
                    }
                },
                {
                    title: 'Electric Bike Efficiency (kWh/mi)',
                    tippyText: 'his is the kilowatt-hours per mile efficiency of the electric bike selected. The default value is sourced from <a href="https://electricbikereview.com/" target="__blank">Electric Bike Review</a> and adjusted based on the hourly temperatures in the climate zone.',
                    input: {
                        name: 'ebikeeff',
                        type: 'number',
                        rounding: 2,
                        min: 0.01,
                        max: 0.1,
                        step: 0.01,
                        title: "E-Bike Efficiency (kWh/mi)",
                        hideifoptions: {
                            'ebike': {
                                type: 'notin',
                                values: [
                                    'Yes'
                                ]
                            }
                        }
                    }
                },
            ],
            dropdowns: [
                {
                    title: "Show Advanced Inputs",
                    inputs: [
                        {
                            title: 'Electric Bike Battery Useful Life (Years)',
                            tippyText: 'This is the number of years the battery in the selected electric bike is expected to last. The default value of five years is a common lifespan for electric bike batteries.',
                            input: {
                                name: 'ebikebatteryuselife',
                                type: 'number',
                                rounding: 0,
                                min: 1,
                                max: 10,
                                title: "Electric Bike Battery Useful Life (Years)",
                                hideifoptions: {
                                    'ebike': {
                                        type: 'notin',
                                        values: [
                                            'Yes'
                                        ]
                                    }
                                }
                            }
                        }
                    ]
                }
            ]
        },
        {
            title: 'Ride-Hailing and Rental Vehicles',
            image: ridehalingRentalCars,
            inputs: [
                {
                    title: 'Fuel Type of Ride-Hailing Vehicles or Rental Vehicles You Plan to Use',
                    tippyText: 'This is the assumed fuel type of ride-hailing and rental vehicles. The model assumes these are gasoline vehicles by default since gas-powered vehicles are the most common ride-hailing vehicle type today.',
                    input: {
                        name: 'carsharingtype',
                        type: 'select',
                        values: [
                            {
                                title: 'Gas Vehicles',
                                value: 'Gas Vehicles'
                            },
                            {
                                title: 'Plug-in Hybrid Electric Vehicles',
                                value: 'Plug-in Hybrid Electric Vehicles'
                            },
                            {
                                title: 'Battery Electric Vehicles',
                                value: 'Battery Electric Vehicles'
                            }
                        ],
                        title: "Fuel Type of Ride-Hailing Vehicles or Rental Vehicles You Plan to Use",
                        hideifoptions: {
                            'vehicle': {
                                type: 'in',
                                values: [
                                    'Yes: Car-Free'
                                ]
                            },
                            "finalise-transport": {
                                type: 'notin',
                                values: [
                                    'Ridehailing',
                                    'Carsharing/Rental Car'
                                ]
                            }
                        }
                    }
                }
            ]
        },
        {
            title: 'Grid Emissions',
            image: analysisTimeframeGridEmissions,
            inputs: [
                {
                    title: 'State Annual Average Marginal Grid Intensity (lb CO\u2082e per MWh)',
                    tippyText: "This is the state electric grid's current average marginal emissions, in pounds of carbon dioxide equivalent per MWh. The default value is the state average load-adjusted annual value in the National Renewable Energy Laboratory\’s <a href='https://www.nrel.gov/analysis/cambium.html' target='__blank'> 2022 Cambium data set</a>. For more detail on this calculation, see the FAQ section.",
                    input: {
                        name: 'gridintensity',
                        type: 'number',
                        rounding: 0,
                        min: 1,
                        max: 2000,
                        title: 'State Annual Average Marginal Grid Intensity (lb CO\u2082e per MWh)'
                    }
                },
                {
                    title: 'Average Annual Grid Intensity Reduction over the Analysis (%)',
                    tippyText: 'This is the annual average percent reduction in the state’s electric grid emissions over the analysis time frame. The default value is the average annual percent change for the state in the National Renewable Energy Laboratory\’s <a href="https://www.nrel.gov/analysis/cambium.html" target="__blank">Cambium "Mid Case Scenario"</a> over the analysis time frame.',
                    input: {
                        name: 'emissionsreduction',
                        type: 'number',
                        rounding: 1,
                        min: 0,
                        max: 50,
                        step: 0.1,
                        multiplier: 100,
                        title: 'Average Annual Grid Intensity Percent Reduction over the Analysis'
                    }
                }
            ]
        }
    ]
};

export default TechnicalDetails;