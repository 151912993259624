import { slide as Menu } from 'react-burger-menu';
import React, { useState, useContext } from "react";

import MenuLinks from '../menu/index';
import Logo from './../../images/logo.svg';

import FaqModal from "../modal/faq-modal";
import ApiModal from "../modal/api-modal";
import PartnersModal from "../modal/partners-modal";
import ContactModal from "../modal/contact-modal";
import ResetModal from "../modal/reset-modal";
import ErrorModal from "../modal/error-modal";

import * as styles from './mobile-sidebar.module.scss';

const Burger = () => {

  const [modalPartnersOpen, setPartnersModalOpen] = useState(false);
  const [modalFaqOpen, setFaqModalOpen] = useState(false);
  const [modalApiOpen, setApiModalOpen] = useState(false);
  const [modalContactOpen, setContactModalOpen] = useState(false);
  const [modalResetOpen, setResetModalOpen] = useState(false);
  const [modalErrorOpen, setErrorModalOpen] = useState(false);
  
  const closeMenu = () => document.querySelector(".closeMenu").click()
  
  return (
    <div className="mobile">
      <div className={styles.mobileHeader}>
        <Menu 
          left 
          width={ '50%' }
          disableAutoFocus 
          overlayClassName={"closeMenu"}
        >

          <MenuLinks/>
          <hr />

          <FaqModal modalFaqOpen={modalFaqOpen} setFaqModalOpen={setFaqModalOpen} />
          <ApiModal modalApiOpen={modalApiOpen} setApiModalOpen={setApiModalOpen} />
          <PartnersModal modalPartnersOpen={modalPartnersOpen} setPartnersModalOpen={setPartnersModalOpen} /> 
          <ContactModal modalContactOpen={modalContactOpen} setContactModalOpen={setContactModalOpen} /> 
          <ResetModal modalResetOpen={modalResetOpen} setResetModalOpen={setResetModalOpen} /> 
          <ErrorModal modalErrorOpen={modalErrorOpen} setErrorModalOpen={setErrorModalOpen} /> 

          <nav className={styles.contentNav}>
            <ul>
                <li><button onClick={e => setPartnersModalOpen(!modalPartnersOpen)}>Our Partners</button></li>
                <li><button onClick={e => setFaqModalOpen(!modalFaqOpen)}>FAQ</button></li>
                <li><button onClick={e => setApiModalOpen(!modalApiOpen)}>API</button></li>
                <li><button onClick={e => setContactModalOpen(!modalContactOpen)}>Contact Us</button></li>
                <li><button onClick={e => setResetModalOpen(!modalResetOpen)}>Reset Analysis</button></li>
            </ul>
          </nav>
        </Menu>
        <a className={styles.mobileLogoContainer} href="https://rmi.org/" target="__blank">
          <img
            className={styles.mobileLogo}
            src={Logo}
            alt="RMI Green Upgrade Calculator" />
        </a>
        <div>
          <p className="main-heading">Green Upgrade Calculator</p>
        </div>
      </div>
    </div>
  );
}

export default Burger;