import React, { useState } from "react";

import * as styles from './footer.module.scss';
import ContactModal from "../modal/contact-modal";

const Footer = () => {

    const today = new Date();

    const [modalContactOpen, setContactModalOpen] = useState(false);

    return (
        <div className={styles.footerMain}>
            <p><b>Our vision is a world thriving, verdant, and secure, for all, forever.</b></p>
            <p>Connect with us online</p>
            <div className={styles.footerSocial}>

            <ContactModal modalContactOpen={modalContactOpen} setContactModalOpen={setContactModalOpen} /> 
                <ul>
                    { /*<li><a className="social-icon facebook" href="#" target="__blank" title="Facebook"><span className="screen-reader">Facebook</span></a></li> */ }
                    <li><a className="social-icon youtube" href="https://www.youtube.com/user/RockyMtnInstitute" target="__blank" title="YouTube"><span className="screen-reader">YouTube</span></a></li>
                    <li><a className="social-icon twitter" href="https://twitter.com/RockyMtnInst" target="__blank" title="Twitter"><span className="screen-reader">Twitter</span></a></li>
                    <li><a className="social-icon linkedin" href="https://www.linkedin.com/company/rocky-mountain-institute" target="__blank" title="Linkedin"><span className="screen-reader">Linkedin</span></a></li>
                    <li><a className="social-icon instagram" href="https://www.instagram.com/rockymtninst/" target="__blank" title="Instagram"><span className="screen-reader">Instagram</span></a></li>
                    <li><button className="social-icon email"  onClick={e => setContactModalOpen(!modalContactOpen)}>Email</button>
                        
                        </li>

                    
                </ul>
            </div>
            <p className={styles.copyright}>© {today.getFullYear()} RMI&nbsp;|&nbsp;<a href="https://rmi.org/privacy-policy/" target="__blank">Privacy Policy</a></p>
        </div>
    )
}

export default Footer;