import Start from './fields/start';
import BasicInformation from "./fields/basicInformation";
import GreenUpgrades from './fields/greenUpgrades';
import TechnicalDetails from "./fields/technicalDetails";
import OperationAndMaintenance from './fields/operationAndMaintenance'
import UpFrontCostsAndInCentives from "./fields/upFrontCostsAndInCentives";
import finacingInfo from "./fields/finacingInfo";
import traditionalSystems from './fields/traditional-systems';
import economicImpact from './fields/economic-impact';
import climateImpact from './fields/climate-impact';
import takeActions from './fields/take-action';

const FormFields = {
    start: Start,
    basicInformation: BasicInformation,
    greenUpgrades: GreenUpgrades,
    technicalDetails: TechnicalDetails,
    operationAndMaintenance: OperationAndMaintenance,
    upFrontCostsAndInCentives: UpFrontCostsAndInCentives,
    finacingInfo: finacingInfo,
    traditionalSystems: traditionalSystems,
    economicImpact: economicImpact,
    climateImpact: climateImpact,
    takeActions: takeActions
}

const findValue = (obj, keyToFind, valueToFind) => {
    let result = null;

    if (Array.isArray(obj)) {
        for (let i = 0; i < obj.length; i++) {
            result = findValue(obj[i], keyToFind, valueToFind);
            if (result) {
                return result;
            }
        }
    } else {
        for (let key in obj) {
            if (key === keyToFind && obj[key] === valueToFind) {
                return obj;
            } else if (obj[key] !== null && typeof obj[key] === 'object') {
                result = findValue(obj[key], keyToFind, valueToFind);
                if (result) {
                    return result;
                }
            }
        }
    }

    return result;
}

export const getField = (key) => {
    let input = findValue(FormFields,'name',key);

    return input;
}

const findValues = (obj, keyToFind, valueToFind, results = []) => {
    if (Array.isArray(obj)) {
        for (let i = 0; i < obj.length; i++) {
            findValues(obj[i], keyToFind, valueToFind, results);
        }
    } else {
        for (let key in obj) {
            if (key === keyToFind && obj[key] === valueToFind) {
                results.push(obj);
            } else if (obj[key] !== null && typeof obj[key] === 'object') {
                findValues(obj[key], keyToFind, valueToFind, results);
            }
        }
    }

    return results;
};

export const getFields = (key) => {
    let input = findValues(FormFields, 'name', key);

    return input;
};

export default FormFields;