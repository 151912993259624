// FormDataProvider.js
import React from 'react';
import FormDataContext from './FormDataContext';
import useFormData from './useFormData';
import ResultsContext from './ResultsContext';
import useResults from './useResults';

const FormDataProvider = ({ children }) => {
  const formDataContextValue = useFormData();
  const resultsContextValue = useResults();

  return (
    <ResultsContext.Provider value={resultsContextValue}>
      <FormDataContext.Provider value={formDataContextValue}>
        {children}
      </FormDataContext.Provider>
    </ResultsContext.Provider>
  );
};

export default FormDataProvider;