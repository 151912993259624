const ResultsOne = () => (
    {
        "mode": "Home (TEMPEST-H)",
        "zipcode": 80304,
        "state": "CO",
        "county": "Boulder",
        "hometype": "Single-Family Detached Home",
        "homeyr": "pre-1980",
        "homestories": "2",
        "homearea": "2250",
        "carmodel": "0",
        "solar": "Yes",
        "storage": "No",
        "communitysolar": "No",
        "wthz": "No",
        "wthz_new": "0",
        "ashp": "No",
        "ashpducting": "No",
        "spaceheatspmt_cleanertech": "Electric Resistance Strip in Heat Pump",
        "hpwh": "No",
        "range": "No",
        "cooking_cleaner": "0",
        "upgrades": "No",
        "panel": "No",
        "roof": "No",
        "hs": "No",
        "vehicle": "No",
        "bevmake": "--",
        "bevmodel": "--",
        "phevmake": "--",
        "phevmodel": "--",
        "evrangelevel1": "N/A",
        "chargeraccess": "Using an Existing Level-1 Charger",
        "ebike": "No",
        "publictransit": "No",
        "ridehailing": "No",
        "carsharing": "No",
        "walking": "No",
        "ac_current": "Ducted Central AC",
        "ac_remaininglife": "0",
        "spaceheat_current": "Natural Gas Furnace",
        "spaceheat_remaininglife": "0",
        "wh_current": "Tank Natural Gas Water Heater",
        "wh_remaininglife": "0",
        "cooking_current": "Natural Gas Range",
        "range_remaininglife": "0",
        "ffvmake": "0",
        "ffvmodel": "0",
        "timeframe_h": "25",
        "timeframe_t": "10",
        "gridintensity": "712.4355",
        "emissionsreduction": "0.0534951429893064",
        "solarkw": "3.4397322072140426",
        "solartilt": "20° Tilt South",
        "solargen": "5874.163387904538",
        "solardegradation": "0.005",
        "solaruselife": "25",
        "communitysaving": "0",
        "storagesize": "0",
        "storageuselife": "15",
        "acbau_units": "1",
        "acbau_indoorunits": "0",
        "acbau_size": "2",
        "acbau_maxcap95F": "2.0322222222222224",
        "acbau_maxcap82F": "2.1458703703703703",
        "ashpbau_maxcap47F": "0",
        "ashpbau_maxcap17F": "0",
        "ashpbau_maxcap5F": "0",
        "acbau_SEER": "12",
        "acbau_eff": "3.8369281322278117",
        "ashpbau_hspf": "0",
        "ashpbau_heatingeff": "0",
        "ashpbau_switchtemp": "-20",
        "acbau_uselife": "25",
        "spaceheatbausize": "9",
        "spaceheatbau_afue": "0.95",
        "spaceheatbau_eff": "0.855",
        "spaceheatbauuselife": "25",
        "ashpoutdoorunits": "1",
        "ashpindoorunits": "0",
        "ashpsize": "5",
        "ashp_calculated_heatloadservedwswitchoff": "0.909176310295955",
        "ashp_maxcap95F": "4.7091666666666665",
        "ashp_maxcap82F": "5.01525",
        "ashp_maxcap47F": "4.785416666666666",
        "ashp_maxcap17F": "3.6458333333333335",
        "ashp_maxcap5F": "2.9665625",
        "ashp_SEER": "17",
        "ashpcoolingeff": "5.480428971458819",
        "ashp_HSPF": "9.5",
        "ashpheatingeff": "N/A",
        "ashpswitchtemp": "-30",
        "ashpuselife": "25",
        "spaceheatspmt_cleaner_afue": "0",
        "spaceheatspmt_cleaner_eff": "0",
        "spaceheatspmt_cleaneruselife": "25",
        "wthz_current": "N/A",
        "rvalwall_current": "6.0277666666666665",
        "rvalroof_current": "11.045825",
        "ach50_current": "16",
        "rvalwindow_current": "1",
        "rvalwall_new": "6.0277666666666665",
        "rvalroof_new": "11.045825",
        "ach50_new": "16",
        "rvalwindow_new": "1",
        "whbausize": "50",
        "whbauuef": "0.7",
        "whbaueff": "0.63",
        "hpwhsize": "0",
        "hpwhuef": "3.677777777777778",
        "hpwheff": "3.106258740893045",
        "whlocation": "Basement",
        "hpwhuselife": "25",
        "ovenrangeratio": "0.6071691989969021",
        "rangeuselife": "25",
        "cookingbaueff_oven": "0.65",
        "cookingbaueff_stove": "0.32",
        "cookingcleaneff_oven": "0.65",
        "cookingcleaneff_stove": "0.32",
        "ffveff": "N/A",
        "pheveff": "N/A",
        "phevfueleff": "N/A",
        "phevelecmiles": "N/A",
        "phevutilityfactor": "N/A",
        "beveff": "N/A",
        "vehicleuselife": "10",
        "ebiketype": "Commuting",
        "ebikeeff": "0.011466542073829177",
        "ebikeuselife": "10",
        "ebikebatteryuselife": "5",
        "carsharingtype": "FFV",
        "energybill": "203.18609051632316",
        "energyusage_total": "123.09643743419427",
        "energyusage_spacecooling": "0.0332447285130617",
        "energyusage_spaceheating": "0.6623562817084337",
        "energyusage_waterheating": "0.1458414941604621",
        "energyusage_cooking": "0.014953375538565017",
        "energyusage_other": "0.14360412007947745",
        "miles": "10863",
        "shorttrips": "21.88846297240456",
        "ebiketrippercent": "0.3",
        "trips_publictransit": "0",
        "trips_ridehailing": "0",
        "trips_carsharing": "0",
        "trips_ebike": "0.09429992332767499",
        "trips_walkorbike": "0",
        "electricratetype": "State Average",
        "rateelec_fixed": "10",
        "rateelec_retail": "0.14701494087107012",
        "rateelec_fixedsolar": "10",
        "rateelec_netmetering": "0.14701494087107012",
        "rateelec_incr": "0.02951639265455246",
        "rateng_fixed": "10",
        "rateng_retail": "1.3227613636363635",
        "rateng_incr": "0.038274225774225776",
        "ratefueloil": "N/A",
        "ratefueloil_incr": "N/A",
        "ratepropane": "N/A",
        "ratepropane_incr": "N/A",
        "rategasoline": "3.5244",
        "rategasoline_incr": "0.04759800434134532",
        "acmtncosts": "125",
        "spaceheatmtncosts": "125",
        "ashpmtncosts": "125",
        "spaceheatspmt_cleanermtncosts": "0",
        "whmtncosts": "75",
        "ffvmtncosts": "0.101",
        "bevmtncosts": "0.061",
        "phevmtncosts": "0.09",
        "ebikemtncosts": "0.09666666666666668",
        "vehiclecosts_regist": "305",
        "evvehiclecosts_regist": "355",
        "vehiclecosts_insur": "1940",
        "publictransitcost": "114",
        "ridehail_costpermile": "2.25",
        "rentalcar_costperday": "90",
        "solarcapco_perw": "3.5782825848193456",
        "solarcapco": "12308.333853516217",
        "storagecapco": "0",
        "acbaucapco": "0",
        "spaceheatbaucapco": "0",
        "wthzcapco": "0",
        "ashpcapco": "0",
        "spaceheatspmt_cleanercapco": "N/A",
        "whbaucapco": "0",
        "hpwhcapco": "0",
        "rangebaucapco": "0",
        "rangecapco": "0",
        "panelcapco": "0",
        "roofcapco": "0",
        "hscapco": "0",
        "vehicleprice": "Economy",
        "ebikeprice": "Economy",
        "ffvcapco": "0",
        "phevcapco": "N/A",
        "bevcapco": "N/A",
        "ebikecapco": "1299",
        "chargercapco": "0",
        "vehiclecapco_incr": "0.15",
        "salestaxrate": "0.029",
        "incomelvl": "80%-150% Area Median Income",
        "fedtaxliab": "11280.420215896249",
        "heehra": "Yes",
        "solarincent_fed": "3692.500156054865",
        "solarincent_state": "0",
        "solarincent_ongoing": "0",
        "solarincent_yrs": "0",
        "spaceheatbau_fedincent": "N/A",
        "spaceheatbau_stateincent": "N/A",
        "spaceheatbau_localorutilityincent": "N/A",
        "acbau_fedincent": "N/A",
        "acbau_stateincent": "N/A",
        "acbau_localorutilityincent": "N/A",
        "ashpincent_fed": "N/A",
        "ashpincent_state": "N/A",
        "ashpincent_localorutility": "N/A",
        "wthzincent_fed": "0",
        "wthzincent_local": "0",
        "wthzincent_utility": "0",
        "whbau_fedincent": "0",
        "whbau_stateincent": "0",
        "whbau_localorutilityincent": "0",
        "hpwhincent_fed": "N/A",
        "hpwhincent_state": "N/A",
        "hpwhincent_localorutility": "N/A",
        "rangebau_fedincent": "0",
        "rangebau_stateincent": "N/A",
        "rangebau_localorutilityincent": "N/A",
        "rangeincent_fed": "N/A",
        "rangeincent_state": "N/A",
        "rangeincent_localorutility": "N/A",
        "panelincent_fed": "N/A",
        "panelincent_state": "N/A",
        "panelincent_localorutility": "N/A",
        "evincent_fed": "0",
        "evincent_state": "0",
        "evincent_localorutility": "0",
        "ebikeincent_state": "0",
        "otherincent": "0",
        "otherincent_yrs": "0",
        "solarfinance": "No: Cash Purchase",
        "solarloan_moneydown": "0",
        "solarloan_amount": "8615.833697461352",
        "solarloan_yrs": "20",
        "solarloan_interestrate": "0.0799",
        "solartpo_irr": "0.12",
        "solartpo_yrs": "N/A",
        "solartpo_escalator": "0.02",
        "solartpo_yronerate": "N/A",
        "solartpo_ownership": "Yes",
        "spaceheatcoolfinance": "No",
        "spaceheatcoolfinance_down": "0",
        "spaceheatcoolfinance_yrs": "10",
        "spaceheatcoolfinance_bauinterest": "0.1019",
        "spaceheatcoolfinance_cleanerinterest": "0.0725",
        "wthzfinance": "No",
        "wthzfinance_down": "0",
        "wthzfinance_yrs": "10",
        "wthzfinance_interest": "0.0725",
        "whfinance": "No",
        "whfinance_down": "0",
        "whfinance_yrs": "5",
        "whfinance_bauinterest": "0.0829",
        "whfinance_interest": "0.0625",
        "rangefinance": "No",
        "rangefinance_down": "0",
        "rangefinance_yrs": "5",
        "rangefinance_bauinterest": "0.0829",
        "rangefinance_interest": "0.0625",
        "upgradesfinance": "No",
        "upgradesfinance_down": "0",
        "upgradesfinance_yrs": "10",
        "upgradesfinance_interest": "0.1019",
        "taxcreditloan_amount": "3692.500156054865",
        "taxcreditloan_yrs": "1",
        "taxcreditloan_interest": "0.075",
        "vehiclefinance": "No: Cash Purchase",
        "vehiclefinance_down": "0.1",
        "vehiclefinance_yrs": "5",
        "ffvloan_interest": "0.0629",
        "evloan_interest": "0.0599",
        "ffvlease_interest": "0.075",
        "evlease_interest": "0.075",
        "vehiclelease_yrs": "3",
        "vehiclelease_fee": "595",
        "vehiclelease_incent": "1",
        "vehiclelease_residual": "0.6",
        "ebikefinance": "No",
        "ebikeloan_yrs": "5",
        "ebikeloan_interest": "0.0925",
        "chargerfinance": "No: Cash Purchase",
        "chargerfinance_down": "0",
        "chargerfinance_yrs": "2",
        "chargerfinance_interest": "0.0575",
        "inflationrate": "0.0248"
    }
);

export default ResultsOne;