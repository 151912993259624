const ResultsTwo = () => (
    {
        //TEMPEST Live Version_s37.xlsm
        "atLeastOneUpgrade":false,
        "mode":"Home (TEMPEST-H)",
        "basic-information":true,
        "zipcode":"80304",
        "zipData":{
            "Zip Code":80304,
            "Official USPS State Code":"CO",
            "Density":1084.5,"Urbanity":"Urban",
            "Primary Official County Name":"Boulder",
            "State_County":"CO_Boulder",
            "IECC Climate Zone":5,
            "IECC Moisture Regime":"B",
            "BA Climate Zone":"Cold",
            "state":"CO",
            "homeareadefaults":{
                "Single-Family Detached Home":"2449",
                "Townhome":"1494",
                "Apartment":"828",
                "Mobile Home":"1181"
            }
        },
        "rural-zip":false,
        "zip-greater-than-four":true,
        "hometype":"Single-Family Detached Home",
        "homearea":"2750",
        "homeyr":"pre-1980",
        "green-upgrades":true,
        "solar":"Yes",
        "traditional-systems":true,
        "ac_current":"Ducted Central AC",
        "spaceheat_current":"Electric Resistance Furnace",
        "wh_current":"Tank Electric Resistance Water Heater",
        "cooking_current":"Electric Resistance Range",
        "solarfinance":"Yes: Solar Lease",
        "solartpo_escalator":"0.04"
    }
);

export default ResultsTwo;