import React, { useEffect, useRef, useMemo } from 'react';

import HideIf from "../../context/functions/HideIf";

import _ from 'lodash'

import * as styles from './icon.module.scss';

const Icon = ({
    title,
    image,
    hideifoptions
}) => {
    //If undefined then we may as well just return null. 
    //Saves a white space
    if (!title && !image)
        return null;

    if (hideifoptions && HideIf(hideifoptions)) {
        return null;
    }

    return (
        <div
            className={styles.details}
        >
            {
                image 
            &&
                <div className='icon-img-wrapper'>
                    <img
                        className={styles.icon}
                        src={image}
                        alt={title} 
                    />
                </div>
            }
            {title && <h3>{title}</h3>}
        </div>
    )
}

/**
 * Jank solution to hide if the elements next to it are hidden or none exisitant.
 * 
 * @returns Icon | null
 */
export const InputListIcon = (props) => {
    const visible = useRef(false);
    const iconEl = useRef(null);

    const updateVisibility = _.debounce(() => {
        window.requestAnimationFrame(() => {
            if (!iconEl?.current)
                return false;
    
            let elsVisible = 0;
    
            iconEl.current?.parentNode.childNodes.forEach(el => {
                if (el.nodeName == 'STYLE') {
                    return;
                }
                if (el.classList.contains('input-div') || el.classList.contains('table-wrap') || el.classList.contains('dropdown')) {
                    if (!el.classList.contains('icon-wrapper') && getComputedStyle(el).display !== 'none')
                        elsVisible++
                }
            });

            visible.current = (elsVisible > 0);
    
            iconEl.current.style.display = (elsVisible > 0) ? '' : 'none';
        })
    }, 300) // add debounce of 300ms

    useEffect(() => {
        updateVisibility();
        document.addEventListener('input-hidden',updateVisibility);
        document.addEventListener('dropdowns-hidden',updateVisibility);
        return () => {
            document.removeEventListener('input-hidden',updateVisibility);
            document.removeEventListener('dropdowns-hidden',updateVisibility);
        }
    }, [])

    const style = {
        display: visible.current ? '' : 'none'
    };

    return (
        <div
            className='icon-wrapper'
            onClick={props.onClick}
            ref={iconEl} 
            style={style}
        >
            <Icon {...props}/>
        </div>
    );
}


export default Icon;