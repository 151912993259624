import { getField } from "../FormFields";

const defaultFallbacks = (formData = {}) => ({
});

export const GetMissingFields = () => {
    let missingFields = [];
    let fallbacks = defaultFallbacks();
    for (let key in fallbacks) {
        let field = getField(key);
        if (!field)
            missingFields.push(key);
    }

    return missingFields
}

export default defaultFallbacks;