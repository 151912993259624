import React, { useEffect, useState } from "react";

import * as styles from './index.module.scss';

var statusObj = {
    uptodate: {
        text: "Generated default values are up to date",
        styles: styles.statusBar
    },
    updating: {
        text: "Updating generated default values",
        styles: `${styles.statusBar} ${styles.updating}`
    },
    error: {
        text: "Error updating generated default values",
        styles: `${styles.statusBar} ${styles.error}`
    }
};

const DefaultsStatusBar = () => {
    const [status, setStatus] = useState(statusObj.uptodate);

    //An event listener for defaults status update, this checks the variable window.defaultsStatus and gets then sets the status
    useEffect(() => {
        window.addEventListener('defaultsStatus', (e) => {
            setStatus(statusObj[window.defaultsStatus]);
        });    
    }, [])

    return <div className={status.styles + ' hide '}>{status.text}</div>;
};

export default DefaultsStatusBar;