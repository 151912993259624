import { getFields } from './../FormFields';

//Test of moving this into it's own file to see if it solves issues with nested useContext


/**
 * Allows use to run the HideIfChecks without using context
 * 
 * @param object options 
 * @param object formData 
 * @param string key - Used most for console logging if needed
 */
export const HideIfNoContext = (options, formData = false, key = false) => {
    let hide = false;

    if (!options || typeof options == 'undefined')
        return false;

    Object.keys(options).forEach(key => {
        let {
            type,
            values,
            or
        } = options[key];
        if (or && Object.keys(or).length > 0) { //if we have an or that means we want to loop through instead. The other logic will be picked up in the loop
            let orHide = true;
            let orArray = or;
            orArray[key] = {
                type: type,
                values: values
            }; // Include the parent data
            Object.keys(orArray).forEach(orKey => {
                let orHideArgs = {};
                orHideArgs[orKey] = orArray[orKey];
                if (!HideIfNoContext(orHideArgs, formData))
                    orHide = false;
            });
            hide = orHide;
        } else if (formData && typeof formData[key] != 'undefined') {
            let data = formData[key];
            switch (type) {
                case 'in':
                    if (values.includes(data))
                        hide = true;
                    break;
                case 'notin':
                    if (typeof data == 'string') {
                        if (!values.includes(data))
                            hide = true;
                    }
                    else if (Array.isArray(data)) {
                        // The values is an array
                        let hasAtLeastOne = false;
                        data.forEach(v => {if (values.includes(v)) {hasAtLeastOne = true}})
                        hide = !hasAtLeastOne;
                    }
                            
                    break;
                case 'novalue':
                    if (data === '')
                        hide = true;
                    break;
                case 'false': 
                    if (data === false)
                        hide = true;
                    break;
                case 'true': 
                    if (data === true)
                        hide = true;
                    break;
                default: break;
            }
        } else {
            //Value not set, lets hide it
            switch (type) {
                case 'notin':
                    hide = true;
                    break;
                case 'novalue':
                    hide = true;
                    break;
                case 'false': 
                    hide = true;
                    break;
                case 'true': 
                    hide = true;
                    break;
                default: break;
            }

        }
    });

    return hide;
}

/**
 * Used to allow us to get the fields and return if it should be hidden or not
 * @param string key 
 * @param object formData 
 */
export const HideIfKey = (key, formData = false) => {
    const fields = getFields(key);

    // If no matching fields, assume the field should be hidden
    if (fields.length === 0) return true;

    // Check if all the fields meet the condition for being hidden
    let fieldsToHide = 0;
    fields.every(input => {
        if (HideIfNoContext(input.hideifoptions, formData, key))
            fieldsToHide++;
    });

    return fieldsToHide === fields.length;

};
