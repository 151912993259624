import homeAppliance from './../../images/home.svg';
import carReplacement from './../../images/car.svg';

import {
    atLeastOneHomeUpgrade
} from './commonHides'

const traditionalSystems = {
    pageName: 'Traditional Systems',
    sections: [
        {
            title: 'Home',
            image: homeAppliance,
            hideifoptions: atLeastOneHomeUpgrade,
            inputs: [
                {
                    title: 'What traditional equipment replacement(s) do you want to compare the selected green upgrades to? When would the traditional replacement be installed?',
                    tippyText: 'This is the equipment the homeowner would otherwise need to purchase at the end of the equipment\’s useful life if they do not purchase the green upgrade. The year entered is when the traditional replacement equipment would be installed, while the green upgrade is assumed to be installed this year. Enter "0" for a direct upfront comparison to the corresponding green upgrade.',
                    subtitle: true,
                    hidetitleIf: {
                        'hpwh': {
                            type: 'notin',
                            values: [
                                "Yes"
                            ],
                            or: {
                                'ashp': {
                                    type: 'notin',
                                    values: [
                                        "Yes"
                                    ],
                                    or: {
                                        'range': {
                                            type: 'notin',
                                            values: [
                                                "Yes"
                                            ]
                                        },
                                    }
                                },
                            }
                        },
                    }
                },
                {
                    table: [
                        {
                            type: 'header',
                            inputs: [
                                {

                                },
                                {
                                    title: 'Traditional Replacement'
                                },
                                {
                                    title: 'Years Until Replacement'
                                }
                            ]
                        },
                        {
                            type: 'row',
                            inputs: [
                                {
                                    title: 'Space-Cooling Appliance'
                                },
                                {
                                    input: {
                                        type: 'select',
                                        title: 'Space-Cooling Appliance',
                                        name: 'ac_current',
                                        hideifoptions: {
                                            'ashp': {
                                                type: 'notin',
                                                values: [
                                                    'Yes'
                                                ]
                                            }
                                        },
                                        values: [
                                            {
                                                title: 'Ducted Central AC',
                                                value: 'Ducted Central AC'
                                            },
                                            {
                                                title: 'Window AC',
                                                value: 'Window AC'
                                            },
                                            {
                                                title: 'No Mechanical Cooling',
                                                value: 'No Mechanical Cooling'
                                            }
                                        ]
                                    }
                                },
                                {
                                    input: {
                                        name: 'ac_remaininglife',
                                        type: 'number',
                                        min: 0,
                                        max: 20,
                                        partialDisable: true,
                                        title: "When do you want to replace the current appliance?",
                                        hideifoptions: {
                                            'ashp': {
                                                type: 'notin',
                                                values: [
                                                    'Yes'
                                                ]
                                            }
                                        },
                                        disableifoptions: {
                                            'ac_current': {
                                                type: 'in',
                                                values: [
                                                    "No Mechanical Cooling"
                                                ]
                                            }
                                        }
                                    }
                                }
                            ]
                        },
                        {
                            type: 'row',
                            inputs: [
                                {
                                    title: (formData) => {
                                        if(typeof formData == 'object' && (formData["ac_current"] === "Ducted Central Heat Pump" || 
                                            formData["ac_current"] === "Ductless Mini Split Heat Pump")) {
                                            return "Supplemental Space-Heating Appliance";
                                        }else{
                                            return "Space-Heating Appliance";
                                        }
                                    } 
                                },
                                {
                                    input: {
                                        type: 'select',
                                        title: (formData) => {
                                            if(typeof formData == 'object' && (formData["ac_current"] === "Ducted Central Heat Pump" || 
                                                formData["ac_current"] === "Ductless Mini Split Heat Pump")) {
                                                return "Supplemental Space-Heating Appliance";
                                            }else{
                                                return "Space-Heating Appliance";
                                            }
                                        },
                                        name: 'spaceheat_current',
                                        hideifoptions: {
                                            'ashp': {
                                                type: 'notin',
                                                values: [
                                                    'Yes'
                                                ]
                                            }
                                        },
                                        values: [
                                            {
                                                title: 'None',
                                                value: 'None'
                                            },
                                            {
                                                title: 'Natural Gas Furnace',
                                                value: 'Natural Gas Furnace'
                                            },
                                            {
                                                title: 'Natural Gas Boiler',
                                                value: 'Natural Gas Boiler'
                                            },
                                            {
                                                title: 'Propane Furnace',
                                                value: 'Propane Furnace'
                                            },
                                            {
                                                title: 'Propane Boiler',
                                                value: 'Propane Boiler'
                                            },
                                            {
                                                title: 'Fuel Oil Furnace',
                                                value: 'Fuel Oil Furnace'
                                            },
                                            {
                                                title: 'Fuel Oil Boiler',
                                                value: 'Fuel Oil Boiler'
                                            },
                                            {
                                                title: 'Electric Resistance Furnace',
                                                value: 'Electric Resistance Furnace'
                                            },
                                            {
                                                title: 'Electric Resistance Boiler',
                                                value: 'Electric Resistance Boiler'
                                            },
                                            {
                                                title: 'Electric Resistance Baseboard',
                                                value: 'Electric Resistance Baseboard'
                                            }
                                        ]
                                    }
                                },
                                {
                                    input: {
                                        name: 'spaceheat_remaininglife',
                                        type: 'number',
                                        min: 0,
                                        max: 30,
                                        title: "When do you want to replace the current appliance?",
                                        hideifoptions: {
                                            'ashp': {
                                                type: 'notin',
                                                values: [
                                                    'Yes'
                                                ]
                                            }
                                        }
                                    }
                                }
                            ]
                        },
                        {
                            type: 'row',
                            inputs: [
                                {
                                    title: 'Water-Heating Appliance'
                                },
                                {
                                    input: {
                                        type: 'select',
                                        title: 'Water-Heating Appliance',
                                        name: 'wh_current',
                                        hideifoptions: {
                                            'hpwh': {
                                                type: 'notin',
                                                values: [
                                                    'Yes'
                                                ]
                                            }
                                        },
                                        values: [
                                            {
                                                title: 'Tank Natural Gas Water Heater',
                                                value: 'Tank Natural Gas Water Heater'
                                            },
                                            {
                                                title: 'Tankless Natural Gas Water Heater',
                                                value: 'Tankless Natural Gas Water Heater'
                                            },
                                            {
                                                title: 'Tank Propane Water Heater',
                                                value: 'Tank Propane Water Heater'
                                            },
                                            {
                                                title: 'Tank Fuel Oil Water Heater',
                                                value: 'Tank Fuel Oil Water Heater'
                                            },
                                            {
                                                title: 'Tank Electric Resistance Water Heater',
                                                value: 'Tank Electric Resistance Water Heater'
                                            },
                                            {
                                                title: 'Tankless Electric Water Heater',
                                                value: 'Tankless Electric Water Heater'
                                            },
                                            {
                                                title: 'Tank Electric Heat Pump Water Heater (Hybrid Water Heater)',
                                                value: 'Tank Electric Heat Pump Water Heater (Hybrid Water Heater)'
                                            }
                                        ]
                                    }
                                },
                                {
                                    input: {
                                        name: 'wh_remaininglife',
                                        type: 'number',
                                        min: 0,
                                        max: 20,
                                        title: "When do you want to replace the current appliance?",
                                        hideifoptions: {
                                            'hpwh': {
                                                type: 'notin',
                                                values: [
                                                    'Yes'
                                                ]
                                            }
                                        },
                                    }
                                }
                            ]
                        },
                        {
                            type: 'row',
                            inputs: [
                                {
                                    title: 'Cooking Appliance',
                                },
                                {
                                    input: {
                                        type: 'select',
                                        title: 'Cooking Appliance',
                                        name: 'cooking_current',
                                        hideifoptions: {
                                            'range': {
                                                type: 'notin',
                                                values: [
                                                    'Yes'
                                                ]
                                            },
                                            'cooking_cleaner': {
                                                type: 'notin',
                                                values: [
                                                    'Electric Induction Range',
                                                    'Electric Induction Stovetop',
                                                    'Electric Induction Stovetop & Electric Wall Oven',
                                                    'Electric Resistance Range',
                                                    'Electric Resistance Stovetop',
                                                    'Electric Resistance Stovetop & Electric Wall Oven',
                                                    'Electric Wall Oven',
                                                ]
                                            }
                                        },
                                        
                                        values: (formData) => {
                                            if (!formData || !formData?.cooking_cleaner)
                                                return [];

                                            switch (formData?.cooking_cleaner) {

                                                case "Electric Induction Range":
                                                    return [
                                                        { title: 'Natural Gas Range', value: 'Natural Gas Range' },
                                                        { title: 'Propane Range', value: 'Propane Range' },
                                                        { title: 'Electric Resistance Range', value: 'Electric Resistance Range' },
                                                        { title: 'Electric Induction Range', value: 'Electric Induction Range' },
                                                        { title: 'Natural Gas Stovetop & Natural Gas Wall Oven', value: 'Natural Gas Stovetop & Natural Gas Wall Oven' },
                                                        { title: 'Natural Gas Stovetop & Electric Wall Oven', value: 'Natural Gas Stovetop & Electric Wall Oven' },
                                                        {  title: 'Propane Stovetop & Propane Wall Oven', value: 'Propane Stovetop & Propane Wall Oven' },
                                                        { title: 'Propane Stovetop & Electric Wall Oven', value: 'Propane Stovetop & Electric Wall Oven' },
                                                        { title: 'Electric Resistance Stovetop & Electric Wall Oven', value: 'Electric Resistance Stovetop & Electric Wall Oven' },
                                                        { title: 'Electric Resistance Stovetop & Natural Gas Wall Oven', value: 'Electric Resistance Stovetop & Natural Gas Wall Oven' },
                                                        { title: 'Electric Resistance Stovetop & Propane Wall Oven', value: 'Electric Resistance Stovetop & Propane Wall Oven' },
                                                        { title: 'Electric Induction Stovetop & Electric Wall Oven', value: 'Electric Induction Stovetop & Electric Wall Oven' },
                                                        { title: 'Electric Induction Stovetop & Natural Gas Wall Oven', value: 'Electric Induction Stovetop & Natural Gas Wall Oven' },
                                                        { title: 'Electric Induction Stovetop & Propane Wall Oven', value: 'Electric Induction Stovetop & Propane Wall Oven' },
                                                    ];

                                                case "Electric Induction Stovetop":
                                                    return [
                                                        { title: 'Natural Gas Stovetop', value: 'Natural Gas Stovetop' },
                                                        { title: 'Electric Resistance Stovetop', value: 'Electric Resistance Stovetop' },
                                                        { title: 'Propane Stovetop', value: 'Propane Stovetop' },
                                                        { title: 'I would not upgrade my stove', value: 'I would not upgrade my stove' },
                                                    ];
                                                
                                                case "Electric Induction Stovetop & Electric Wall Oven":
                                                    return [
                                                        { title: 'Natural Gas Range', value: 'Natural Gas Range' },
                                                        { title: 'Propane Range', value: 'Propane Range' },
                                                        { title: 'Electric Resistance Range', value: 'Electric Resistance Range' },
                                                        { title: 'Electric Induction Range', value: 'Electric Induction Range' },
                                                        { title: 'Natural Gas Stovetop & Natural Gas Wall Oven', value: 'Natural Gas Stovetop & Natural Gas Wall Oven' },
                                                        { title: 'Natural Gas Stovetop & Electric Wall Oven', value: 'Natural Gas Stovetop & Electric Wall Oven' },
                                                        {  title: 'Propane Stovetop & Propane Wall Oven', value: 'Propane Stovetop & Propane Wall Oven' },
                                                        { title: 'Propane Stovetop & Electric Wall Oven', value: 'Propane Stovetop & Electric Wall Oven' },
                                                        { title: 'Electric Resistance Stovetop & Electric Wall Oven', value: 'Electric Resistance Stovetop & Electric Wall Oven' },
                                                        { title: 'Electric Resistance Stovetop & Natural Gas Wall Oven', value: 'Electric Resistance Stovetop & Natural Gas Wall Oven' },
                                                        { title: 'Electric Resistance Stovetop & Propane Wall Oven', value: 'Electric Resistance Stovetop & Propane Wall Oven' },
                                                        { title: 'Electric Induction Stovetop & Electric Wall Oven', value: 'Electric Induction Stovetop & Electric Wall Oven' },
                                                        { title: 'Electric Induction Stovetop & Natural Gas Wall Oven', value: 'Electric Induction Stovetop & Natural Gas Wall Oven' },
                                                        { title: 'Electric Induction Stovetop & Propane Wall Oven', value: 'Electric Induction Stovetop & Propane Wall Oven' },
                                                    ];
                                                
                                                case "Electric Resistance Range":
                                                    return [
                                                        { title: 'Natural Gas Range', value: 'Natural Gas Range' },
                                                        { title: 'Propane Range', value: 'Propane Range' },
                                                        { title: 'Electric Resistance Range', value: 'Electric Resistance Range' },
                                                        { title: 'Electric Induction Range', value: 'Electric Induction Range' },
                                                        { title: 'Natural Gas Stovetop & Natural Gas Wall Oven', value: 'Natural Gas Stovetop & Natural Gas Wall Oven' },
                                                        { title: 'Natural Gas Stovetop & Electric Wall Oven', value: 'Natural Gas Stovetop & Electric Wall Oven' },
                                                        {  title: 'Propane Stovetop & Propane Wall Oven', value: 'Propane Stovetop & Propane Wall Oven' },
                                                        { title: 'Propane Stovetop & Electric Wall Oven', value: 'Propane Stovetop & Electric Wall Oven' },
                                                        { title: 'Electric Resistance Stovetop & Electric Wall Oven', value: 'Electric Resistance Stovetop & Electric Wall Oven' },
                                                        { title: 'Electric Resistance Stovetop & Natural Gas Wall Oven', value: 'Electric Resistance Stovetop & Natural Gas Wall Oven' },
                                                        { title: 'Electric Resistance Stovetop & Propane Wall Oven', value: 'Electric Resistance Stovetop & Propane Wall Oven' },
                                                        { title: 'Electric Induction Stovetop & Electric Wall Oven', value: 'Electric Induction Stovetop & Electric Wall Oven' },
                                                        { title: 'Electric Induction Stovetop & Natural Gas Wall Oven', value: 'Electric Induction Stovetop & Natural Gas Wall Oven' },
                                                        { title: 'Electric Induction Stovetop & Propane Wall Oven', value: 'Electric Induction Stovetop & Propane Wall Oven' },
                                                    ];

                                                case "Electric Resistance Stovetop":
                                                    return [
                                                        { title: 'Natural Gas Stovetop', value: 'Natural Gas Stovetop' },
                                                        { title: 'Electric Resistance Stovetop', value: 'Electric Resistance Stovetop' },
                                                        { title: 'Propane Stovetop', value: 'Propane Stovetop' },
                                                        { title: 'I would not upgrade my stove', value: 'I would not upgrade my stove' },
                                                    ];

                                                case "Electric Resistance Stovetop & Electric Wall Oven":
                                                    return [
                                                        { title: 'Natural Gas Range', value: 'Natural Gas Range' },
                                                        { title: 'Propane Range', value: 'Propane Range' },
                                                        { title: 'Electric Resistance Range', value: 'Electric Resistance Range' },
                                                        { title: 'Electric Induction Range', value: 'Electric Induction Range' },
                                                        { title: 'Natural Gas Stovetop & Natural Gas Wall Oven', value: 'Natural Gas Stovetop & Natural Gas Wall Oven' },
                                                        { title: 'Natural Gas Stovetop & Electric Wall Oven', value: 'Natural Gas Stovetop & Electric Wall Oven' },
                                                        {  title: 'Propane Stovetop & Propane Wall Oven', value: 'Propane Stovetop & Propane Wall Oven' },
                                                        { title: 'Propane Stovetop & Electric Wall Oven', value: 'Propane Stovetop & Electric Wall Oven' },
                                                        { title: 'Electric Resistance Stovetop & Electric Wall Oven', value: 'Electric Resistance Stovetop & Electric Wall Oven' },
                                                        { title: 'Electric Resistance Stovetop & Natural Gas Wall Oven', value: 'Electric Resistance Stovetop & Natural Gas Wall Oven' },
                                                        { title: 'Electric Resistance Stovetop & Propane Wall Oven', value: 'Electric Resistance Stovetop & Propane Wall Oven' },
                                                        { title: 'Electric Induction Stovetop & Electric Wall Oven', value: 'Electric Induction Stovetop & Electric Wall Oven' },
                                                        { title: 'Electric Induction Stovetop & Natural Gas Wall Oven', value: 'Electric Induction Stovetop & Natural Gas Wall Oven' },
                                                        { title: 'Electric Induction Stovetop & Propane Wall Oven', value: 'Electric Induction Stovetop & Propane Wall Oven' },
                                                    ];   

                                                case "Electric Wall Oven":
                                                    return [
                                                        { title: 'Natural Gas Wall Oven', value: 'Natural Gas Wall Oven' },
                                                        { title: 'Propane Wall Oven', value: 'Propane Wall Oven' },
                                                    ];
                                            }
                                        },
                                    }
                                },
                                {
                                    input: {
                                        name: 'range_remaininglife',
                                        type: 'number',
                                        min: 0,
                                        max: 30,
                                        title: "When do you want to replace the current appliance?",
                                        partialDisable: true,
                                        hideifoptions: {
                                            'range': {
                                                type: 'notin',
                                                values: [
                                                    'Yes'
                                                ]
                                            }
                                        },
                                        disableifoptions: {
                                            'cooking_current': {
                                                type: 'in',
                                                values: [
                                                    "I would not upgrade my stove"
                                                ]
                                            }
                                        }
                                    }
                                }
                            ]
                        }
                    ]
                },
                {
                    title: 'What other appliances are in the home but that you do not want to analyze replacing?',
                    tippyText: "These are additional home appliances that can impact a home\’s energy usage and are necessary to generate the traditional hourly load profile. All other electrical loads, such as plug loads, refrigeration, and lighting, are also included in the hourly load profile. This energy usage is the same in both the traditional and green upgrade scenario.",
                    subtitle: true,
                    hidetitleIf: {
                        'hpwh': {
                            type: "in",
                            values: [
                                'Yes'
                            ],
                            or: {
                                'range': {
                                    type: "in",
                                    values: [
                                        'Yes'
                                    ],
                                },
                                'ashp': {
                                    type: "in",
                                    values: [
                                        'Yes'
                                    ],
                                },
                            }
                        },
                        mode: {
                            type: 'in',
                            values: [
                                'Transportation (TEMPEST-T)'
                            ],
                        },
                    }
                },
                {
                    table: [
                        {
                            type: 'row',
                            inputs: [
                                {
                                    title: 'Space-Cooling Appliance'
                                },
                                {
                                    input: {
                                        type: 'select',
                                        title: 'Space-Cooling Appliance',
                                        name: 'ac_current',
                                        hideifoptions: {
                                            'ashp': {
                                                type: 'in',
                                                values: [
                                                    'Yes'
                                                ]
                                            },
                                            mode: {
                                                type: 'in',
                                                values: [
                                                    'Transportation (TEMPEST-T)'
                                                ]
                                            }
                                        },

                                        values : [
                                            {
                                                title: 'Ducted Central AC',
                                                value: 'Ducted Central AC'
                                            },
                                            {
                                                title: 'Window AC',
                                                value: 'Window AC'
                                            },
                                            {
                                                title: 'Ducted Central Heat Pump',
                                                value: 'Ducted Central Heat Pump'
                                            },
                                            {
                                                title: 'Ductless Mini Split Heat Pump',
                                                value: 'Ductless Mini Split Heat Pump'
                                            },
                                            {
                                                title: 'No Mechanical Cooling',
                                                value: 'No Mechanical Cooling'
                                            }
                                        ]
                                    }
                                }
                            ]
                        },
                        {
                            type: 'row',
                            inputs: [
                                {
                                    title: (formData) => {
                                        if(typeof formData == 'object' && (formData["ac_current"] === "Ducted Central Heat Pump" || 
                                            formData["ac_current"] === "Ductless Mini Split Heat Pump")) {
                                            return "Supplemental Space-Heating Appliance";
                                        }else{
                                            return "Space-Heating Appliance";
                                        }
                                    },
                                },
                                {
                                    input: {
                                        type: 'select',
                                        title: (formData) => {
                                            if(typeof formData == 'object' && (formData["ac_current"] === "Ducted Central Heat Pump" || 
                                                formData["ac_current"] === "Ductless Mini Split Heat Pump")) {
                                                return "Supplemental Space-Heating Appliance";
                                            }else{
                                                return "Space-Heating Appliance";
                                            }
                                        },
                                        name: 'spaceheat_current',
                                        hideifoptions: {
                                            'ashp': {
                                                type: 'in',
                                                values: [
                                                    'Yes'
                                                ]
                                            },
                                            mode: {
                                                type: 'in',
                                                values: [
                                                    'Transportation (TEMPEST-T)'
                                                ]
                                            }
                                        },
                                        values: [
                                            {
                                                title: 'None',
                                                value: 'None'
                                            },
                                            {
                                                title: 'Natural Gas Furnace',
                                                value: 'Natural Gas Furnace'
                                            },
                                            {
                                                title: 'Natural Gas Boiler',
                                                value: 'Natural Gas Boiler'
                                            },
                                            {
                                                title: 'Propane Furnace',
                                                value: 'Propane Furnace'
                                            },
                                            {
                                                title: 'Propane Boiler',
                                                value: 'Propane Boiler'
                                            },
                                            {
                                                title: 'Fuel Oil Furnace',
                                                value: 'Fuel Oil Furnace'
                                            },
                                            {
                                                title: 'Fuel Oil Boiler',
                                                value: 'Fuel Oil Boiler'
                                            },
                                            {
                                                title: 'Electric Resistance Furnace',
                                                value: 'Electric Resistance Furnace'
                                            },
                                            {
                                                title: 'Electric Resistance Boiler',
                                                value: 'Electric Resistance Boiler'
                                            },
                                            {
                                                title: 'Electric Resistance Baseboard',
                                                value: 'Electric Resistance Baseboard'
                                            }
                                        ]
                                    }
                                }
                            ]
                        },
                        {
                            type: 'row',
                            inputs: [
                                {
                                    title: 'Water-Heating Appliance'
                                },
                                {
                                    input: {
                                        type: 'select',
                                        title: 'Water-Heating Appliance',
                                        name: 'wh_current',
                                        hideifoptions: {
                                            'hpwh': {
                                                type: 'in',
                                                values: [
                                                    'Yes'
                                                ]
                                            },
                                            mode: {
                                                type: 'in',
                                                values: [
                                                    'Transportation (TEMPEST-T)'
                                                ]
                                            }
                                        },
                                        values: [
                                            {
                                                title: 'Tank Natural Gas Water Heater',
                                                value: 'Tank Natural Gas Water Heater'
                                            },
                                            {
                                                title: 'Tankless Natural Gas Water Heater',
                                                value: 'Tankless Natural Gas Water Heater'
                                            },
                                            {
                                                title: 'Tank Propane Water Heater',
                                                value: 'Tank Propane Water Heater'
                                            },
                                            {
                                                title: 'Tank Fuel Oil Water Heater',
                                                value: 'Tank Fuel Oil Water Heater'
                                            },
                                            {
                                                title: 'Tank Electric Resistance Water Heater',
                                                value: 'Tank Electric Resistance Water Heater'
                                            },
                                            {
                                                title: 'Tankless Electric Water Heater',
                                                value: 'Tankless Electric Water Heater'
                                            },
                                            {
                                                title: 'Tank Electric Heat Pump Water Heater (Hybrid Water Heater)',
                                                value: 'Tank Electric Heat Pump Water Heater (Hybrid Water Heater)'
                                            }
                                        ]
                                    }
                                }
                            ]
                        },
                        {
                            type: 'row',
                            inputs: [
                                {
                                    title: 'Cooking Appliance',
                                },
                                {
                                    input: {
                                        type: 'select',
                                        title: 'Cooking Appliance',
                                        name: 'cooking_current',
                                        hideifoptions: {
                                            'range': {
                                                type: 'in',
                                                values: [
                                                    'Yes'
                                                ]
                                            },
                                            mode: {
                                                type: 'in',
                                                values: [
                                                    'Transportation (TEMPEST-T)'
                                                ]
                                            }
                                        },
                                        
                                        values: [
                                            { title: 'Natural Gas Range', value: 'Natural Gas Range' },
                                            { title: 'Propane Range', value: 'Propane Range' },
                                            { title: 'Electric Resistance Range', value: 'Electric Resistance Range' },
                                            { title: 'Electric Induction Range', value: 'Electric Induction Range' },
                                            { title: 'Natural Gas Stovetop & Natural Gas Wall Oven', value: 'Natural Gas Stovetop & Natural Gas Wall Oven' },
                                            { title: 'Natural Gas Stovetop & Electric Wall Oven', value: 'Natural Gas Stovetop & Electric Wall Oven' },
                                            {  title: 'Propane Stovetop & Propane Wall Oven', value: 'Propane Stovetop & Propane Wall Oven' },
                                            { title: 'Propane Stovetop & Electric Wall Oven', value: 'Propane Stovetop & Electric Wall Oven' },
                                            { title: 'Electric Resistance Stovetop & Electric Wall Oven', value: 'Electric Resistance Stovetop & Electric Wall Oven' },
                                            { title: 'Electric Resistance Stovetop & Natural Gas Wall Oven', value: 'Electric Resistance Stovetop & Natural Gas Wall Oven' },
                                            { title: 'Electric Resistance Stovetop & Propane Wall Oven', value: 'Electric Resistance Stovetop & Propane Wall Oven' },
                                            { title: 'Electric Induction Stovetop & Electric Wall Oven', value: 'Electric Induction Stovetop & Electric Wall Oven' },
                                            { title: 'Electric Induction Stovetop & Natural Gas Wall Oven', value: 'Electric Induction Stovetop & Natural Gas Wall Oven' },
                                            { title: 'Electric Induction Stovetop & Propane Wall Oven', value: 'Electric Induction Stovetop & Propane Wall Oven' },
                                        ]
                                    }
                                }
                            ]
                        },
                        {
                            type: 'row',
                            inputs: [
                                {
                                    title: 'Cooking Appliance',
                                },
                                {
                                    input: {
                                        type: 'select',
                                        title: 'Cooking Appliance',
                                        name: 'cooking_current_noupgrade',
                                        hideifoptions: {
                                            'cooking_current': {
                                                type: 'notin',
                                                values: [
                                                    'I would not upgrade my stove'
                                                ]
                                            },
                                            mode: {
                                                type: 'in',
                                                values: [
                                                    'Transportation (TEMPEST-T)'
                                                ]
                                            }
                                        },
                                        values: [
                                            {
                                                title: 'Natural Gas Range',
                                                value: 'Natural Gas Range'
                                            },
                                            {
                                                title: 'Propane Range',
                                                value: 'Propane Range'
                                            },
                                            {
                                                title: 'Electric Resistance Range',
                                                value: 'Electric Resistance Range'
                                            },
                                            {
                                                title: 'Electric Induction Range',
                                                value: 'Electric Induction Range'
                                            },
                                            {
                                                title: 'Natural Gas Stovetop & Natural Gas Wall Oven',
                                                value: 'Natural Gas Stovetop & Natural Gas Wall Oven'
                                            },
                                            {
                                                title: 'Natural Gas Stovetop & Electric Wall Oven',
                                                value: 'Natural Gas Stovetop & Electric Wall Oven'
                                            },
                                            {
                                                title: 'Propane Stovetop & Propane Wall Oven',
                                                value: 'Propane Stovetop & Propane Wall Oven'
                                            },
                                            {
                                                title: 'Propane Stovetop & Electric Wall Oven',
                                                value: 'Propane Stovetop & Electric Wall Oven'
                                            },
                                            {
                                                title: 'Electric Resistance Stovetop & Electric Wall Oven',
                                                value: 'Electric Resistance Stovetop & Electric Wall Oven'
                                            },
                                            {
                                                title: 'Electric Resistance Stovetop & Natural Gas Wall Oven',
                                                value: 'Electric Resistance Stovetop & Natural Gas Wall Oven'
                                            },
                                            {
                                                title: 'Electric Resistance Stovetop & Propane Wall Oven',
                                                value: 'Electric Resistance Stovetop & Propane Wall Oven'
                                            },
                                            {
                                                title: 'Electric Induction Stovetop & Electric Wall Oven',
                                                value: 'Electric Induction Stovetop & Electric Wall Oven'
                                            }
                                        ]
                                    }
                                }
                            ]
                        }
                    ]
                }
            ]
        },
        {
            title: 'Transportation',
            image: carReplacement,
            hideifoptions: {
                'buy-electric-car': {
                    type: 'notin',
                    values: [
                        'Yes'
                    ],
                    or: {
                        'vehicle': {
                            type: 'notin',
                            values: [
                                'Yes: Car-Free'
                            ]
                        }
                    }
                }
            },
            inputs: [
                {
                    title: 'You are all set! Click on "View Results" below to see your impact.',
                    subtitle: true,
                    hidetitleIf: {
                        mode: {
                            type: 'notin',
                            values: [
                                'Transportation (TEMPEST-T)'
                            ]
                        },
                        'buy-electric-car': {
                            type: 'in',
                            values: [
                                'Yes'
                            ]
                        },
                        'vehicle': {
                            type: 'in',
                            values: [
                                'Yes: Car-Free'
                            ]
                        }
                    }
                },
                {
                    title: 'What new traditional gasoline vehicle do you want to analyze versus the selected electric vehicle?',
                    tippyText: 'This is the new gasoline vehicle make and model that the calculator will compare to the selected electric vehicle or going car-free. If you do not have a particular make and model in mind, you can alternatively select an “Average” value for various body types in the “Vehicle Make” drop-down menu. ICEV refers to an internal combustion engine vehicle, also known as a gasoline powered car.',
                    subtitle: true,
                    hidetitleIf: {
                        'buy-electric-car': {
                            type: 'notin',
                            values: [
                                'Yes'
                            ]
                        }
                    }
                },
                {
                    title: 'If you decided to not go car free, what type of traditional gasoline vehicle would you buy?',
                    tippyText: 'This is the gasoline vehicle make and model you would consider purchasing instead of going car-free. If you do not have a particular make and model in mind, you can alternatively select an “Average” value for various body types in the “Vehicle Make” drop down menu.',
                    subtitle: true,
                    hidetitleIf: {
                        'vehicle': {
                            type: 'notin',
                            values: [
                                'Yes: Car-Free'
                            ]
                        }
                    }
                },
                {
                    title: 'Vehicle Make',
                    input: {
                        type: 'select',
                        title: 'Car Make',
                        name: 'ffvmake',
                        hideifoptions: {
                            'buy-electric-car': {
                                type: 'notin',
                                values: [
                                    'Yes'
                                ],
                                or: {
                                    'vehicle': {
                                        type: 'notin',
                                        values: [
                                            'Yes: Car-Free'
                                        ]
                                    }
                                }
                            }
                        },
                        values: [
                            {"title": "Average ICEV Hatchback", "value": "Average FFV Hatchback"},
                            {"title": "Average ICEV Pick-up Truck", "value": "Average FFV Pick-up Truck"},
                            {"title": "Average ICEV Sedan/Coupe", "value": "Average FFV Sedan/Coupe"},
                            {"title": "Average ICEV SUV", "value": "Average FFV SUV"},
                            {"title": "Acura", "value": "Acura"},
                            {"title": "Audi", "value": "Audi"},
                            {"title": "BMW", "value": "BMW"},
                            {"title": "Buick", "value": "Buick"},
                            {"title": "Cadillac", "value": "Cadillac"},
                            {"title": "Chevrolet", "value": "Chevrolet"},
                            {"title": "Chrysler", "value": "Chrysler"},
                            {"title": "Dodge", "value": "Dodge"},
                            {"title": "Ford", "value": "Ford"},
                            {"title": "Genesis", "value": "Genesis"},
                            {"title": "GMC", "value": "GMC"},
                            {"title": "Honda", "value": "Honda"},
                            {"title": "Hyundai", "value": "Hyundai"},
                            {"title": "Infiniti", "value": "Infiniti"},
                            {"title": "Jeep", "value": "Jeep"},
                            {"title": "Kia", "value": "Kia"},
                            {"title": "Land Rover", "value": "Land Rover"},
                            {"title": "Lexus", "value": "Lexus"},
                            {"title": "Lincoln", "value": "Lincoln"},
                            {"title": "Mazda", "value": "Mazda"},
                            {"title": "Mercedes-Benz", "value": "Mercedes-Benz"},
                            {"title": "Mini", "value": "Mini"},
                            {"title": "Mitsubishi", "value": "Mitsubishi"},
                            {"title": "Nissan", "value": "Nissan"},
                            {"title": "Porsche", "value": "Porsche"},
                            {"title": "Ram", "value": "Ram"},
                            {"title": "Subaru", "value": "Subaru"},
                            {"title": "Toyota", "value": "Toyota"},
                            {"title": "Volkswagen", "value": "Volkswagen"},
                            {"title": "Volvo", "value": "Volvo"}
                        ]
                          
                    }
                },
                {
                    title: 'Vehicle Model',
                    input: {
                        type: 'select',
                        title: 'Vehicle Model',
                        name: 'ffvmodel',
                        hideifoptions: {
                            'buy-electric-car': {
                                type: 'notin',
                                values: [
                                    'Yes'
                                ],
                                or: {
                                    'vehicle': {
                                        type: 'notin',
                                        values: [
                                            'Yes: Car-Free'
                                        ]
                                    }
                                }
                            },
                            'ffvmake': {
                                type: 'notin',
                                values: [
                                    'Audi',
                                    'Acura',
                                    'BMW',
                                    'Buick',
                                    'Cadillac',
                                    'Chevrolet',
                                    'Chrysler',
                                    'Dodge',
                                    'Ford',
                                    'Genesis',
                                    'GMC',
                                    'Honda',
                                    'Hyundai',
                                    'Infiniti',
                                    'Jeep',
                                    'Kia',
                                    'Land Rover',
                                    'Lexus',
                                    'Lincoln',
                                    'Mazda',
                                    'Mercedes-Benz',
                                    'Mini',
                                    'Mitsubishi',
                                    'Nissan',
                                    'Porsche',
                                    'Ram',
                                    'Subaru',
                                    'Toyota',
                                    'Volkswagen',
                                    'Volvo'
                                ]
                            }
                        },
                        values: (formData) => {
                            if (!formData || !formData?.ffvmake)
                                return [];
                            switch (formData?.ffvmake) {
                                case "Average ICEV Hatchback":
                                    return [
                                        { title: 'Average ICEV Hatchback', value: 'Average FFV Hatchback' },
                                    ];
                        
                                case "Average ICEV Pick-up Truck":
                                    return [
                                        { title: 'Average ICEV Pick-up Truck', value: 'Average FFV Pick-up Truck' },
                                    ];
                        
                                case "Average ICEV Sedan/Coupe":
                                    return [
                                        { title: 'Average ICEV Sedan/Coupe', value: 'Average FFV Sedan/Coupe' },
                                    ];
                        
                                case "Average ICEV SUV":
                                    return [
                                        { title: 'Average ICEV SUV', value: 'Average FFV SUV' },
                                    ];
                        
                                case "Acura":
                                    return [
                                        { title: 'MDX', value: 'MDX' },
                                        { title: 'RDX', value: 'RDX' },
                                        { title: 'TLX', value: 'TLX' },
                                        { title: 'ILX', value: 'ILX' },
                                    ];
                        
                                case "Audi":
                                    return [
                                        { title: 'Q5', value: 'Q5' },
                                        { title: 'Q3', value: 'Q3' },
                                        { title: 'Q7', value: 'Q7' },
                                        { title: 'A5', value: 'A5' },
                                        { title: 'A4', value: 'A4' },
                                        { title: 'Q8', value: 'Q8' },
                                        { title: 'RS 5', value: 'RS 5' },
                                    ];
                        
                                case "BMW":
                                    return [
                                        { title: 'X3', value: 'X3' },
                                        { title: 'X5', value: 'X5' },
                                        { title: '3-Series', value: '3-Series' },
                                        { title: '5-Series', value: '5-Series' },
                                        { title: 'X7', value: 'X7' },
                                        { title: '4-Series', value: '4-Series' },
                                        { title: 'X1', value: 'X1' },
                                        { title: '2-Series', value: '2-Series' },
                                        { title: 'X4', value: 'X4' },
                                    ];
                        
                                case "Buick":
                                    return [
                                        { title: 'Encore GX', value: 'Encore GX' },
                                        { title: 'Envision', value: 'Envision' },
                                        { title: 'Enclave', value: 'Enclave' },
                                        { title: 'Encore', value: 'Encore' },
                                    ];
                        
                                case "Cadillac":
                                    return [
                                        { title: 'Escalade', value: 'Escalade' },
                                        { title: 'XT5', value: 'XT5' },
                                        { title: 'XT6', value: 'XT6' },
                                        { title: 'XT4', value: 'XT4' },
                                    ];
                        
                                case "Chevrolet":
                                    return [
                                        { title: 'Silverado 1500 Limited', value: 'Silverado 1500 Limited' },
                                        { title: 'Equinox', value: 'Equinox' },
                                        { title: 'Traverse', value: 'Traverse' },
                                        { title: 'Tahoe', value: 'Tahoe' },
                                        { title: 'Trailblazer', value: 'Trailblazer' },
                                        { title: 'Suburban', value: 'Suburban' },
                                        { title: 'Colorado', value: 'Colorado' },
                                        { title: 'Blazer', value: 'Blazer' },
                                        { title: 'Express', value: 'Express' },
                                        { title: 'Trax', value: 'Trax' },
                                        { title: 'Malibu', value: 'Malibu' },
                                        { title: 'Corvette', value: 'Corvette' },
                                        { title: 'Spark', value: 'Spark' },
                                        { title: 'Camaro', value: 'Camaro' },
                                    ];
                        
                                case "Chrysler":
                                    return [
                                        { title: 'Pacifica', value: 'Pacifica' },
                                        { title: 'Town and Country ', value: 'Town and Country ' },
                                        { title: '300', value: '300' },
                                    ];
                        
                                case "Dodge":
                                    return [
                                        { title: 'Charger', value: 'Charger' },
                                        { title: 'Durango', value: 'Durango' },
                                        { title: 'Challenger', value: 'Challenger' },
                                        { title: 'Journey', value: 'Journey' },
                                    ];
                        
                                case "Ford":
                                    return [
                                        { title: 'F-150', value: 'F-150' },
                                        { title: 'Explorer', value: 'Explorer' },
                                        { title: 'Escape', value: 'Escape' },
                                        { title: 'Bronco Sport', value: 'Bronco Sport' },
                                        { title: 'Transit Connect Cargo Van', value: 'Transit Connect Cargo Van' },
                                        { title: 'Ranger', value: 'Ranger' },
                                        { title: 'Edge', value: 'Edge' },
                                        { title: 'Expedition', value: 'Expedition' },
                                        { title: 'Mustang', value: 'Mustang' },
                                        { title: 'EcoSport', value: 'EcoSport' },
                                        { title: 'Bronco', value: 'Bronco' },
                                        { title: 'Maverick', value: 'Maverick' },
                                        { title: 'Fusion', value: 'Fusion' },
                                    ];
                        
                                case "Genesis":
                                    return [
                                        { title: 'GV80', value: 'GV80' },
                                        { title: 'GV70', value: 'GV70' },
                                        { title: 'G70', value: 'G70' },
                                    ];
                        
                                case "GMC":
                                    return [
                                        { title: 'Sierra 1500 Limited', value: 'Sierra 1500 Limited' },
                                        { title: 'Yukon', value: 'Yukon' },
                                        { title: 'Acadia', value: 'Acadia' },
                                        { title: 'Terrain', value: 'Terrain' },
                                        { title: 'Savana', value: 'Savana' },
                                        { title: 'Canyon', value: 'Canyon' },
                                    ];
                        
                                case "Honda":
                                    return [
                                        { title: 'CR-V', value: 'CR-V' },
                                        { title: 'Civic', value: 'Civic' },
                                        { title: 'Accord', value: 'Accord' },
                                        { title: 'Pilot', value: 'Pilot' },
                                        { title: 'HR-V', value: 'HR-V' },
                                        { title: 'Odyssey', value: 'Odyssey' },
                                        { title: 'Passport', value: 'Passport' },
                                        { title: 'Ridgeline', value: 'Ridgeline' },
                                        { title: 'Insight', value: 'Insight' },
                                    ];
                        
                                case "Hyundai":
                                    return [
                                        { title: 'Tuscon', value: 'Tuscon' },
                                        { title: 'Elantra', value: 'Elantra' },
                                        { title: 'Santa Fe', value: 'Santa Fe' },
                                        { title: 'Kona', value: 'Kona' },
                                        { title: 'Palisade', value: 'Palisade' },
                                        { title: 'Sonata', value: 'Sonata' },
                                        { title: 'Venue', value: 'Venue' },
                                        { title: 'Accent', value: 'Accent' },
                                        { title: 'Santa Cruz', value: 'Santa Cruz' },
                                    ];
                        
                                case "Infiniti":
                                    return [
                                        { title: 'QX50', value: 'QX50' },
                                        { title: 'Q50', value: 'Q50' },
                                        { title: 'QX80', value: 'QX80' },
                                    ];
                        
                                case "Jeep":
                                    return [
                                        { title: 'Grand Cherokee', value: 'Grand Cherokee' },
                                        { title: 'Wrangler', value: 'Wrangler' },
                                        { title: 'Gladiator', value: 'Gladiator' },
                                        { title: 'Cherokee', value: 'Cherokee' },
                                        { title: 'Compass', value: 'Compass' },
                                        { title: 'Renegade', value: 'Renegade' },
                                    ];
                        
                                case "Kia":
                                    return [
                                        { title: 'Forte', value: 'Forte' },
                                        { title: 'Telluride', value: 'Telluride' },
                                        { title: 'Sportage', value: 'Sportage' },
                                        { title: 'Sorento', value: 'Sorento' },
                                        { title: 'K5', value: 'K5' },
                                        { title: 'Soul', value: 'Soul' },
                                        { title: 'Seltos', value: 'Seltos' },
                                        { title: 'Rio', value: 'Rio' },
                                        { title: 'Niro', value: 'Niro' },
                                        { title: 'Sedona', value: 'Sedona' },
                                        { title: 'Stinger', value: 'Stinger' },
                                    ];
                        
                                case "Land Rover":
                                    return [
                                        { title: 'Range Rover Sport', value: 'Range Rover Sport' },
                                        { title: 'Defender', value: 'Defender' },
                                        { title: 'Range Rover', value: 'Range Rover' },
                                        { title: 'Range Rover Velar', value: 'Range Rover Velar' },
                                    ];
                        
                                case "Lexus":
                                    return [
                                        { title: 'RX 350', value: 'RX 350' },
                                        { title: 'NX', value: 'NX' },
                                        { title: 'ES', value: 'ES' },
                                        { title: 'GX', value: 'GX' },
                                        { title: 'LX', value: 'LX' },
                                        { title: 'IS', value: 'IS' },
                                        { title: 'UX', value: 'UX' },
                                    ];
                        
                                case "Lincoln":
                                    return [
                                        { title: 'MKX/Nautilus', value: 'MKX/Nautilus' },
                                        { title: 'MKC', value: 'MKC' },
                                        { title: 'Aviator', value: 'Aviator' },
                                        { title: 'Navigator', value: 'Navigator' },
                                    ];
                        
                                case "Mazda":
                                    return [
                                        { title: 'CX-5', value: 'CX-5' },
                                        { title: 'CX-30', value: 'CX-30' },
                                        { title: '3', value: '3' },
                                        { title: 'CX-9', value: 'CX-9' },
                                        { title: '6', value: '6' },
                                    ];
                        
                                case "Mercedes-Benz":
                                    return [
                                        { title: 'GLE-Class', value: 'GLE-Class' },
                                        { title: 'GLC/GLK-Class', value: 'GLC/GLK-Class' },
                                        { title: 'Sprinter', value: 'Sprinter' },
                                        { title: 'C-Class', value: 'C-Class' },
                                        { title: 'GLB', value: 'GLB' },
                                        { title: 'GL/GLS-Class', value: 'GL/GLS-Class' },
                                        { title: 'E CLS-Class', value: 'E CLS-Class' },
                                        { title: 'GLA-Class', value: 'GLA-Class' },
                                        { title: 'S-Class', value: 'S-Class' },
                                        { title: 'Metris', value: 'Metris' },
                                    ];
                        
                                case "Mini":
                                    return [
                                        { title: 'Cooper', value: 'Cooper' },
                                        { title: 'Countryman', value: 'Countryman' },
                                    ];
                        
                                case "Mitsubishi":
                                    return [
                                        { title: 'Outlander Sport', value: 'Outlander Sport' },
                                        { title: 'Outlander', value: 'Outlander' },
                                        { title: 'Mirage', value: 'Mirage' },
                                    ];
                        
                                case "Nissan":
                                    return [
                                        { title: 'Rogue', value: 'Rogue' },
                                        { title: 'Sentra', value: 'Sentra' },
                                        { title: 'Altima', value: 'Altima' },
                                        { title: 'Kicks', value: 'Kicks' },
                                        { title: 'Versa', value: 'Versa' },
                                        { title: 'Frontier', value: 'Frontier' },
                                        { title: 'Murano', value: 'Murano' },
                                        { title: 'Pathfinder', value: 'Pathfinder' },
                                        { title: 'Titan', value: 'Titan' },
                                        { title: 'Armada', value: 'Armada' },
                                        { title: 'NV200', value: 'NV200' },
                                        { title: 'Maxima', value: 'Maxima' },
                                        { title: 'NV', value: 'NV' },
                                    ];
                        
                                case "Porsche":
                                    return [
                                        { title: 'Macan', value: 'Macan' },
                                        { title: 'Cayenne', value: 'Cayenne' },
                                    ];
                        
                                case "Ram":
                                    return [
                                        { title: '1500 Classic', value: '1500 Classic' },
                                        { title: 'ProMaster', value: 'ProMaster' },
                                        { title: 'ProMaster City', value: 'ProMaster City' },
                                    ];
                        
                                case "Subaru":
                                    return [
                                        { title: 'Forester', value: 'Forester' },
                                        { title: 'Outback', value: 'Outback' },
                                        { title: 'CrossTrek', value: 'CrossTrek' },
                                        { title: 'Ascent', value: 'Ascent' },
                                        { title: 'Impreza', value: 'Impreza' },
                                        { title: 'Legacy', value: 'Legacy' },
                                    ];
                        
                                case "Toyota":
                                    return [
                                        { title: 'RAV4', value: 'RAV4' },
                                        { title: 'Camry', value: 'Camry' },
                                        { title: 'Highlander', value: 'Highlander' },
                                        { title: 'Tacoma', value: 'Tacoma' },
                                        { title: 'Corrola', value: 'Corrola' },
                                        { title: '4Runner', value: '4Runner' },
                                        { title: 'Tundra', value: 'Tundra' },
                                        { title: 'Venza', value: 'Venza' },
                                        { title: 'Prius Family', value: 'Prius Family' },
                                        { title: 'C-HR', value: 'C-HR' },
                                        { title: 'Avalon', value: 'Avalon' },
                                        { title: 'Sienna', value: 'Sienna' },
                                    ];
                        
                                case "Volkswagen":
                                    return [
                                        { title: 'Tiguan', value: 'Tiguan' },
                                        { title: 'Atlas', value: 'Atlas' },
                                        { title: 'Jetta', value: 'Jetta' },
                                        { title: 'Atlas Sport', value: 'Atlas Sport' },
                                        { title: 'Taos', value: 'Taos' },
                                        { title: 'Passat', value: 'Passat' },
                                    ];
                        
                                case "Volvo":
                                    return [
                                        { title: 'XC60', value: 'XC60' },
                                        { title: 'XC90', value: 'XC90' },
                                        { title: 'XC40', value: 'XC40' },
                                    ];
                            }
                        }
                    }
                }
            ]
        },
    ]
};

export default traditionalSystems;