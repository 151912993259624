import React, { useContext, useEffect, useMemo } from "react";

import HideIf from "../../context/functions/HideIf";
import { HideIfNoContext } from "../../context/functions/HideIfNoContext";

import ResultsContext from '../../context/ResultsContext';
import FormDataContext from '../../context/FormDataContext';

import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; // optional

const replaceBracketedText = (str, formData, modifiedVariables) => {

    if(typeof str === "function") {
        return str(formData);
    }

    if (!formData && typeof formData != 'object') {
        return '';
    }

    if(!modifiedVariables) {
        return str?.replace(/\[([^\]]+)\]/g, (_, key) => formData[key.trim()] || '').replace('FFV', 'ICEV');
    }

    return str?.replace(/\[([^\]]+)\]/g, (_, key) => {
        let dataPoint = parseFloat(formData[key.trim()] || 0);
        const multiplier = modifiedVariables[key.trim()]?.multiplier;
        const rounding = modifiedVariables[key.trim()]?.rounding;
        if (multiplier && typeof multiplier === "number" && typeof dataPoint === "number") {
            dataPoint = dataPoint * multiplier;
        }
        if(rounding !== "undefined" && typeof rounding === "number" && typeof dataPoint === "number") {
            dataPoint = dataPoint.toFixed(rounding);
        }
        return dataPoint;
    }).replace('FFV', 'ICEV');
};

const Input = ({
    title,
    image,
    tippyText,
    updateInputsInList,
    input,
    secondary,
    subtitle,
    child,
    hidetitle,
    hidetitleIf,
    defaults = true
}) => {

    const { formData, addOrUpdateFormData, clearFormData, addDefault, getDefaultsFromApi, addNameToInputtedValues, removeNameFromInputtedValues, allFormData, clearFormDataNoReload } = useContext(FormDataContext);
    const { results, getResults } = useContext(ResultsContext);

    title = replaceBracketedText(title,formData);

    /**
     * Set Defaults
     */
    useEffect(() => {
        if (!input || typeof input.defaultValueFnc == 'undefined')
            return; 
        //Default to come back too
        if (input?.hideifoptions && !HideIfNoContext(input?.hideifoptions,formData))
            input.defaultValueFnc(formData, addDefault);
    }, [formData])

    //Hide if criteria is set
    if (input?.hideifoptions && HideIf(input?.hideifoptions)) {
        if (typeof updateInputsInList == 'function')
            updateInputsInList(title,false);
        return null;
    }

    const handleChange = (e) => {
        let { name, value } = e.target;
        // if (formData.share == true && (formData.name != value)) {
        //     formData.share = false;
        // }

        if (name == 'mode' && (formData.share != true || (formData.mode != value))) {

            clearFormDataNoReload(() => {
                addNameToInputtedValues(name);
                addOrUpdateFormData(name, value, false);
                formData.share = false;
                document.querySelector('.continue')?.click();
            });


        } 
        else if(name == 'mode' && formData.share == true){
            addOrUpdateFormData(name, value, false);
            document.querySelector('.continue')?.click();
        }
        else {

            if(max && value > max) {
                value = max;
            }

            if (value !== "") {
                addNameToInputtedValues(name);
            } else {
                removeNameFromInputtedValues(name);
            }

            addOrUpdateFormData(name, value, false);
            if (input.mirrorValue && input.mirrorValue.length > 0)
                input.mirrorValue.forEach(item => {
                    addOrUpdateFormData(item, value, true);
                })

        }

    };

    const handleChangeManual = (name, value) => {

        if (value !== "") {
            addNameToInputtedValues(name);
        } else {
            removeNameFromInputtedValues(name);
        }

        addOrUpdateFormData(name, value, false);
        if (input.mirrorValue && input.mirrorValue.length > 0)
            input.mirrorValue.forEach(item => {
                addOrUpdateFormData(item, value,true);
            })
    }

    if (!input?.type) {
        if (typeof updateInputsInList == 'function')
            updateInputsInList(title,true);

        if (hidetitleIf && HideIf(hidetitleIf)) {
            if (typeof updateInputsInList == 'function')
                updateInputsInList(title,false);
            return null;
        }

        let labelClasses = []; // Split this out to make it easier to read;

        if (typeof secondary != 'undefined')
            labelClasses.push('secondary');

        if (typeof subtitle != 'undefined')
            labelClasses.push('subtitle')

        if (tippyText)
            labelClasses.push('tippy-text');

        return (
            <p
                className={labelClasses.join(' ')}
            >{title}{tippyText && <Tippy content={<span dangerouslySetInnerHTML={{__html: tippyText}}/>} allowHTML={true} interactive={true}>
            <span className='tippy-text_icon'></span></Tippy>}</p>
        )
    }

    let max = (typeof input?.maxfnc == 'function') ? input?.maxfnc(formData) : (typeof input?.max !== 'undefined') ? input?.max : 0

    if (typeof updateInputsInList == 'function')
        updateInputsInList(title,true);

    const getNewDefaults = () => {
        if (defaults) {
            getDefaultsFromApi(formData);
        }
    }

    const getTimelineResults = () => {
        getResults(formData);
    }

    if(input?.timelinegetresults) {
        input.onBlur = getTimelineResults;
    } else {
        input.onBlur = getNewDefaults;
    }

    if (formData && typeof formData[input.name] == 'undefined' && allFormData && typeof allFormData[input.name] !== 'undefined') {
        formData[input.name] = allFormData[input.name];
    }


    if ([
        'select'
    ].includes(input.type)) {
        return (
            <SelectInput
                label={title}
                image={image}
                child={child}
                secondary={secondary}
                tippyText={tippyText}
                input={input}
                handleChange={handleChange}
                formData={formData} />
        );
    } else if ([
        'text', 'number'
    ].includes(input.type))
        return (
            <TextInput
                label={title}
                image={image}
                tippyText={tippyText}
                secondary={secondary}
                child={child}
                subtitle={subtitle}
                input={input}
                handleChange={handleChange}
                formData={formData}
                max={max} />
        );
    else if ([
        'checkbox'
    ].includes(input.type))
        return (
            <CheckBoxInputs
                input={input}
                handleChange={handleChangeManual}
                child={child}
                tippyText={tippyText}
                secondary={secondary}
                title={title}
                hidetitle={hidetitle}
                formData={formData} />
        );
    else if ([
        'radio'
    ].includes(input.type))
        return (
            <RadioInputs
                input={input}
                handleChange={handleChange}
                formData={formData} />
        );

    return null;
}
const SelectInput = ({
    label,
    image,
    tippyText,
    input,
    handleChange,
    formData,
    secondary,
    child
}) => {
    let values = [];
    if (input?.values) {
        if (Array.isArray(input.values))
            values = input.values;
        else if (typeof input.values == 'function')
            values = input.values(formData);
    }

        return (
        <div className={"label-container " + ((formData && formData[input.name] && (formData[input.name] !== "" || formData[input.name] !== 0)) ? 'complete ' : '') + ((typeof child != 'undefined') ? 'child' : '')}>
            <label>
                <div className="details-container">
                    { image &&
                    <div className="details">
                        {image && <img className="icon" src={image} alt={label + " icon"} />}
                    </div>
                    }            
                    {(label || tippyText) && <p className={`tippy-text ` + (typeof secondary != 'undefined' ? 'secondary' : '') }>{label} {tippyText && <Tippy content={<span dangerouslySetInnerHTML={{__html: tippyText}}/>} allowHTML={true} interactive={true}>
                        <span className='tippy-text_icon'></span></Tippy>}
                    </p>}
                </div>
                
                <select
                    name={input.name}
                    value={formData && typeof formData[input.name] != 'undefined' ? formData[input.name] : ''}
                    onBlur={input.onBlur}
                    onChange={e => {
                        handleChange(e);
                        if (input?.onChange && typeof input.onChange == 'function') {
                            input.onChange(e);
                        }
                    }}                    
                >
                    <option value="" >--</option>
                    {values.map(option => <option key={`${input.name}-${option.value}`} value={option.value} disabled={(typeof option.value == 'undefined')} >{option.title}</option>)}
                </select>
            </label>
            { input?.referenceText?.condition(formData) &&
                <span className="reference-text">{ replaceBracketedText(input?.referenceText?.text, formData, input?.referenceText?.modifiedVariables) }</span>
            }
        </div>
    )
}

const TextInput = ({
    label,
    image,
    tippyText,
    input,
    secondary,
    child,
    handleChange,
    formData,
    subtitle,
    max
}) => {
    let inputArgs = input;
    let greyOut = (input?.disableifoptions && (
        HideIfNoContext(input?.disableifoptions, formData, `${input?.name}`)
    )) || input?.disabled;
    let partiallyDisabled = greyOut && input?.partialDisable;

    const inputValue = useMemo(() => {

        if (!formData || typeof formData[input.name] === 'undefined') {
            return null;
        }

        if(isNaN(formData[input.name]) && isNaN(parseFloat(formData[input.name])) || formData[input.name] === " ") {
            return formData[input.name];
        }

        if(input?.type !== "number" && formData[input.name] === "" && input?.rounding !== undefined) {
            return formData[input.name];
        }
        
        let parsedValue = parseFloat(formData[input.name]);

        if(max && parsedValue > max) {
            return max;
        }
    
        if (inputArgs?.rounding === 0) {
            return Math.round(parsedValue);
        } else if (inputArgs?.rounding > 0) {
            return Number(parsedValue.toFixed(inputArgs.rounding));
        }
        
        return parsedValue;
    }, [formData, input, inputArgs]);

    return (
        <div className={
            "label-container " + 
            ((formData && formData[input.name] && formData[input.name].length > 0) ? 'complete' : '') + 
            ((typeof child != 'undefined') ? 'child' : '')
        }>
            <label>
                <div className="details-container">
                    { image &&
                    <div className="details">
                        {image && <img className="icon" src={image} />}
                    </div>
                    }
                    
                    {(label || tippyText) && <p className={'tippy-text' + (typeof subtitle != 'undefined' ? ' subtitle' : '') + (typeof secondary != 'undefined' ? ' secondary' : '')}>{label} {tippyText && <Tippy content={<span dangerouslySetInnerHTML={{__html: tippyText}}/>} allowHTML={true} interactive={true}>
                        <span className='tippy-text_icon'></span></Tippy>}
                    </p> }
                </div>
                <input
                    className={ `${inputArgs?.name === "zipcode" ? "disable-arrows" : ""} ${partiallyDisabled ? 'input-only-disabled' : ''}` }
                    type={ inputArgs?.type }
                    name={ inputArgs?.name }
                    title={ inputArgs?.title }
                    max={ max }
                    min={ input?.min }
                    step={ input?.step ? input?.step : 1 }
                    value={ formData && typeof formData[input.name] !== 'undefined' ? typeof formData[input.name] !== "number" ? inputValue : inputValue : '' }
                    onChange={e => {
                        handleChange(e);
                        if (input?.onChange && typeof input.onChange == 'function')
                            input.onChange(e);
                    }} 
                    onBlur={input.onBlur}
                    disabled={partiallyDisabled}
                />
            </label>
            { input?.referenceText?.condition(formData) &&
                <span className="reference-text">{ replaceBracketedText(input?.referenceText?.text, formData, input?.referenceText?.modifiedVariables) }</span>
            }
        </div>
    )
}

const CheckBoxInputs = ({
    input,
    handleChange,
    formData,
    child,
    title,
    hidetitle,
    tippyText
}) => {
    let values = formData && typeof formData[input.name] != 'undefined' ? formData[input.name] : '';
    let greyOut = input?.disableifoptions && HideIfNoContext(input?.disableifoptions, formData, `${input?.name}-test`);

    return (
        <div className={(greyOut) ? 'input-disabled' : ''}>
            {typeof title != 'undefined' && typeof hidetitle == 'undefined' ? 
                <p className={'checkboxintro' + ((typeof child != 'undefined') ? ' child' : '')}>
                    {title}
                    <Tippy content={<span dangerouslySetInnerHTML={{__html:tippyText}}/>} allowHTML={true} interactive={true}>
                        <span className='tippy-text_icon'></span>
                    </Tippy>
                </p> 
            : 
                ''
            }
            <div className={(typeof input.class != 'undefined' ? input.class : '') + ((typeof child != 'undefined') ? ' child' : '')}>
                {
                    input.values.map(value => {
                        let checked = values && values.includes(value.value);
                        return (
                            <label key={`checkbox-${input.name}-${value.value}`} className={`checkbox ` + (checked ? 'checked ' : '') + `${input.name}` }>
                                <input
                                    type="checkbox"
                                    name={input.name}
                                    value={value.value}
                                    checked={checked}
                                    onBlur={input.onBlur}
                                    onChange={e => {
                                        
                                        //If single value, we just want to store the value of the item selected
                                        if (input?.storage && input.storage === 'single-value') {
                                            handleChange(input.name, (e.target.checked) ? e.target.value : '');
                                        //Fallback, just store all.
                                        } else {
                                            const newValues = e.target.checked
                                                ? [...(values || []), e.target.value]
                                                : values.filter(val => val !== e.target.value);
                                            handleChange(input.name, newValues);
                                        }

                                        if (input?.onChange && typeof input.onChange == 'function')
                                            input.onChange(e);

                                        input.onBlur();
                                    }} />

                                {(value?.title || value?.tippyText) && <p className='tippy-text'>{value.title} {value.tippyText && <Tippy content={<span dangerouslySetInnerHTML={{__html:value.tippyText}}/>} allowHTML={true} interactive={true}>
                                    <span className='tippy-text_icon'></span></Tippy>}
                                </p>}       
                                
                            </label>
                        )
                    })
                }
            </div>
        </div>
    )
}


const RadioInputs = ({
    input,
    handleChange,
    formData
}) => {
    let values = formData && typeof formData[input.name] != 'undefined' ? formData[input.name] : '';

    return input.values.map(value => {
        let checked = values && values.includes(value.value);
        return (
            <label key={`radio-${input.name}-${value.value}`} className={"radioLabel " + (checked ? ' checked' : '')}>
                <input
                    type="radio"
                    name={input.name}
                    value={value.value}
                    checked={checked}
                    onBlur={input.onBlur}
                    onChange={e => {
                        handleChange(e);
                        if (input?.onChange && typeof input.onChange == 'function')
                            input.onChange(e);
                    }}
                    onClick={e => {
                        handleChange(e);
                        if (input?.onClick && typeof input.onClick == 'function')
                            input.onClick(e);
                    }} />
                    
                    {(value?.title || value?.tippyText) && <p className='tippy-text'>{value.title} {value.tippyText && <Tippy content={<span dangerouslySetInnerHTML={{__html:value.tippyText}}/>} allowHTML={true} interactive={true}>
                        <span className='tippy-text_icon'></span></Tippy>}
                    </p>}
            </label>
        )
    })
}

export default Input;