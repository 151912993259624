import homeEnergyUsage from './../../images/operation-and-maintenance-home-energy-usage.png';
import vehicleUsage from './../../images/operation-and-maintenance-vehicle-usage.png';
import energyRates from './../../images/operation-and-maintenance-energy-rates.png';
import maintenanceCosts from './../../images/operation-and-maintenance-maintenance-costs.png';

import {
    atLeastOneHomeUpgrade,
    atLeastOneFuelOil,
    atLeastOneNaturalGas,
    atLeastOnePropane,
    atLeastOneTransportUpgrade
} from './commonHides';

const OperationAndMaintenance = {
    pageName: 'Operation & Maintenance',
    sections: [
        {
            title: "Home Energy Usage",
            image: homeEnergyUsage,
            inputs: [
                {
                    title: 'Typical Monthly Home Energy Bill ($/month)',
                    tippyText: 'This is the typical total monthly energy expenses for the home including electricity, natural gas, fuel oil, and/or propane, as applicable. The default value corresponds to the annual energy consumption by fuel type multiplied by the corresponding energy rate value plus all associated fixed charges.',
                    input: {
                        name: 'energybill',
                        type: 'number',
                        rounding: 0,
                        min: 0,
                        max: 1000,
                        title: "Typical Monthly Home Energy Bill ($/month)",
                        hideifoptions: atLeastOneHomeUpgrade
                    }
                },
            ],
            dropdowns: [
                {
                    
                    title: "Show Advanced Inputs",
                    inputs: [
                        {
                            title: 'Typical Annual Home Energy Usage (MMBtu/year)',
                            tippyText: "Enter the typical annual home energy consumption, in million British thermal units per year, including electricity, natural gas, fuel oil, and propane, as applicable. The default value is based on the selected home type, home age, home size, and climate zone, sourced from Energy Information Administration (EIA) <a href='https://www.eia.gov/consumption/residential/data/2020/index.php?view=microdata' target='__blank'>Residential Energy Consumption (RECS) 2020 consumption microdata</a>.",
                            input: {
                                name: 'energyusage_total',
                                type: 'number',
                                rounding: 1,
                                min: 0,
                                max: 500,
                                title: "Typical Annual Home Energy Usage (MMBtu/year)",
                                hideifoptions: atLeastOneHomeUpgrade
                            }
                        },
                        {
                            title: 'Current Annual Energy End Use Breakdown:',
                            secondary: true,
                            subtitle: true,
                            hidetitleIf: {
                                mode: {
                                    type: 'in',
                                    values: [
                                        'Transportation (TEMPEST-T)'
                                    ]
                                },
                                ...atLeastOneHomeUpgrade
                            }
                        },
                        {
                            title: 'Space Heating (%)',
                            tippyText: 'This is the percentage of annual energy consumption used for space heating. The default value is based on the selected home type, home age, home size, and climate zone, sourced from Energy Information Administration (EIA) <a href="https://www.eia.gov/consumption/residential/data/2020/index.php?view=microdata" target="__blank">Residential Energy Consumption (RECS) 2020 consumption microdata</a>, and then modified based on the home envelope values, as further explained in the FAQ section.',
                            input: {
                                name: 'energyusage_spaceheating',
                                type: 'number',
                                rounding: 0,
                                min: 0,
                                max: 100,
                                multiplier: 100,
                                title: "Space Heating (%)",
                                hideifoptions: atLeastOneHomeUpgrade
                            }
                        },
                        {
                            title: 'Space Cooling (%)',
                            tippyText: 'This is the percentage of annual energy consumption used for space cooling. The default value is based on the selected home type, home age, home size, and climate zone, sourced from Energy Information Administration (EIA) <a href="https://www.eia.gov/consumption/residential/data/2020/index.php?view=microdata" target="__blank">Residential Energy Consumption (RECS) 2020 consumption microdata</a>, and then modified based on the home envelope values, as further explained in the FAQ section.',
                            input: {
                                name: 'energyusage_spacecooling',
                                type: 'number',
                                rounding: 0,
                                min: 0,
                                max: 100,
                                multiplier: 100,
                                title: "Space Cooling (%)",
                                hideifoptions: atLeastOneHomeUpgrade
                            }
                        },
                        {
                            title: 'Water Heating (%)',
                            tippyText: 'This is the percentage of annual energy consumption used for water heating. The default value is based on the selected home type, home size, and climate zone, sourced from Energy Information Administration (EIA) <a href="https://www.eia.gov/consumption/residential/data/2020/index.php?view=microdata" target="__blank">Residential Energy Consumption (RECS) 2020 consumption microdata</a>.',
                            input: {
                                name: 'energyusage_waterheating',
                                type: 'number',
                                rounding: 0,
                                min: 0,
                                max: 100,
                                multiplier: 100,
                                title: "Water Heating (%)",
                                hideifoptions: atLeastOneHomeUpgrade
                            }
                        },
                        {
                            title: 'Cooking (%)',
                            tippyText: 'This is the percentage of annual energy consumption used for cooking. The default value is based on the selected home type, home size, and climate zone, sourced from Energy Information Administration (EIA) <a href="https://www.eia.gov/consumption/residential/data/2020/index.php?view=microdata" target="__blank">Residential Energy Consumption (RECS) 2020 consumption microdata</a>.',
                            input: {
                                name: 'energyusage_cooking',
                                type: 'number',
                                rounding: 0,
                                min: 0,
                                max: 100,
                                multiplier: 100,
                                title: "Cooking (%)",
                                hideifoptions: atLeastOneHomeUpgrade
                            }
                        },
                        {
                            title: 'All Other Loads (%)',
                            tippyText: 'This is the percentage of annual energy consumption used for all home energy needs except space cooling, space heating, water heating, and cooking. The default value is based on the selected home type, home size, and climate zone, sourced from Energy Information Administration (EIA) <a href="https://www.eia.gov/consumption/residential/data/2020/index.php?view=microdata" target="__blank">Residential Energy Consumption (RECS) 2020 consumption microdata</a>.',
                            input: {
                                name: 'energyusage_other',
                                type: 'number',
                                rounding: 0,
                                min: 0,
                                max: 100,
                                multiplier: 100,
                                title: "All Other Loads (%)",
                                hideifoptions: atLeastOneHomeUpgrade
                            }
                        }
                    ]
                }
            ]
        },
        {
            title: "Transportation Usage",
            image: vehicleUsage,
            inputs: [
                {
                    title: 'Number of Miles Driven per Year',
                    tippyText: 'This is the number of miles typically driven per year in the current vehicle. The default value is the average vehicle miles traveled in  the corresponding state, sourced from the <a href="https://www.fhwa.dot.gov/policyinformation/statistics/2017/" target="__blank">Federal Highway Administration.</a>',
                    input: {
                        name: 'miles',
                        type: 'number',
                        rounding: 0,
                        min: 500,
                        max: 40000,
                        title: 'Number of Miles Driven per Year',
                        hideifoptions: atLeastOneTransportUpgrade
                    }
                },
                {
                    title: 'Number of Trips Driven per Week under 5 Miles',
                    tippyText: 'This is the number of one-way trips under 5 miles typically driven per week in the current vehicle. The default value is the national average, sourced from the <a href="https://nhts.ornl.gov/" target="__blank">National Household Travel Survey.</a>',
                    input: {
                        name: 'shorttrips',
                        type: 'number',
                        rounding: 0,
                        min: 1,
                        max: 100,
                        title: 'Number of Trips Driven per Week under 5 Miles',
                        hideifoptions: {
                            ...atLeastOneTransportUpgrade,
                            'ebike': {
                                type: 'notin',
                                values: [
                                    'Yes'
                                ]
                            }
                        }
                    }
                },
                {
                    title: "Percentage of Trips Under 5 Miles Willing to Take by Electric Bike",
                    tippyText: 'This is the percentage of one-way trips under 5 miles an individual would be willing to take by electric bike. The default value is based on an internal estimate using data from RMI’s E-Bike Economic and Environment Impact Assessment Calculator.',
                    input: {
                        name: 'ebiketrippercent',
                        type: 'number',
                        rounding: 0,
                        min: 0,
                        max: 100,
                        multiplier: 100,
                        title: "Percentage of Trips Under 5 Miles Willing to Take by Electric Bike",
                        hideifoptions: {
                            ...atLeastOneTransportUpgrade,
                            'ebike': {
                                type: 'notin',
                                values: [
                                    'Yes'
                                ]
                            }
                        }
                    }
                },
                {
                    title: 'Alternative Transportation in a Car-Free Scenario (% of Total Trips)',
                    tippyText: 'These are the percentages of all trips an individual would take by the selected transportation modes in the car-free scenario. The default values are based on the population density of the zip code entered and data on transportation trips by travel mode sourced from <a href="https://www.replicahq.com/" target="__blank">Replica.</a>',
                    textStart: true,
                    secondary: true,
                    subtitle: true,
                    hidetitleIf: {
                        'vehicle': {
                            type: 'notin',
                            values: [
                                "Yes: Car-Free"
                            ]
                        },
                    }
                },
                {
                    title: "Public Transit (%)",
                    input: {
                        name: 'trips_publictransit',
                        type: 'number',
                        rounding: 0,
                        min: 0,
                        max: 100,
                        step: 1,
                        multiplier: 100,
                        title: "Public Transit (%)",
                        hideifoptions: {
                            ...atLeastOneTransportUpgrade,
                            'publictransit': {
                                type: 'notin',
                                values: [
                                    'Yes'
                                ]
                            },
                            'finalise-transport': {
                                type: 'notin',
                                values: [
                                    "Public Transit"
                                ]
                            }
                        }
                    }
                },
                {
                    title: "Ridehailing (%)",
                    input: {
                        name: 'trips_ridehailing',
                        type: 'number',
                        rounding: 0,
                        min: 0,
                        max: 100,
                        step: 1,
                        multiplier: 100,
                        title: "Ridehailing (%)",
                        hideifoptions: {
                            ...atLeastOneTransportUpgrade,
                            'vehicle': {
                                type: 'notin',
                                values: [
                                    "Yes: Car-Free"
                                ]
                            },
                            'finalise-transport': {
                                type: 'notin',
                                values: [
                                    "Ridehailing"
                                ]
                            }
                        }
                    }
                },
                {
                    title: "Carsharing/rental car (%)",
                    input: {
                        name: 'trips_carsharing',
                        type: 'number',
                        rounding: 0,
                        min: 0,
                        max: 100,
                        step: 1,
                        multiplier: 100,
                        title: "Carsharing/rental car (%)",
                        hideifoptions: {
                            ...atLeastOneTransportUpgrade,
                            'vehicle': {
                                type: 'notin',
                                values: [
                                    "Yes: Car-Free"
                                ]
                            },
                            'finalise-transport': {
                                type: 'notin',
                                values: [
                                    "Carsharing/Rental Car"
                                ]
                            }
                        }
                    }
                },
                {
                    title: "Electric Bike (%)",
                    input: {
                        name: 'trips_ebike',
                        type: 'number',
                        rounding: 0,
                        min: 0,
                        max: 100,
                        step: 1,
                        multiplier: 100,
                        title: "Electric Bike (%)",
                        hideifoptions: {
                            ...atLeastOneTransportUpgrade,
                            'vehicle': {
                                type: 'notin',
                                values: [
                                    "Yes: Car-Free"
                                ]
                            },
                            'finalise-transport': {
                                type: 'notin',
                                values: [
                                    "Electric Bike"
                                ]
                            }
                        }
                    }
                },
                {
                    title: "Walking or Bicycling (%)",
                    input: {
                        name: 'trips_walkorbike',
                        type: 'number',
                        rounding: 0,
                        min: 0,
                        max: 100,
                        step: 1,
                        multiplier: 100,
                        title: "Walking or Bicycling (%)",
                        hideifoptions: {
                            ...atLeastOneTransportUpgrade,
                            'vehicle': {
                                type: 'notin',
                                values: [
                                    "Yes: Car-Free"
                                ]
                            },
                            'finalise-transport': {
                                type: 'notin',
                                values: [
                                    "Walking/Biking"
                                ]
                            }
                        }
                    }
                }
            ]
        },
        {
            title: "Energy Rates",
            image: energyRates,
            inputs: [
                {
                    title: "Electricity Rates",
                    secondary: true,
                    subtitle: true,
                },
                {
                    title: "Electric Utility",
                    tippyText: "This is the electric utility serving the resident. If a user does not select a utility, the state average will be used for the corresponding electric volumetric rates and fixed charges.",
                    input: {
                        name: 'electulrate',
                        type: 'select',
                        values: (formData) => {
                            const staticValues = [
                                {
                                    title: 'State Average Rate',
                                    value: 'State Average Rate'
                                }
                            ];
                        
                            const suppliers = (formData?.lsesData ?? []).map(lse => ({
                                title: lse.name,
                                value: lse.lseId
                            }));
                        
                            return [...staticValues, ...suppliers];
                        },
                        title: "Electric Utility"
                    }
                },
                {
                    title: "Electric Utility Tariff: Traditional Scenario",
                    tippyText: "This is the electricity tariff the resident will continue to use under the business-as-usual scenario. The default is the most common residential electricity tariff applicable to the user entered information.",
                    input: {
                        name: 'electricratetype',
                        type: 'select',
                        values: (formData) => {
                            if (!formData?.electulrate || !formData?.tariffsDataBau)
                                return [];

                            const utilities = formData?.tariffsDataBau
                                .filter(utility => utility.lseId == formData.electulrate)
                                .map(utility => ({
                                    title: utility.tariffName,
                                    value: `${utility.masterTariffId}_${utility.tariffId}`
                                }));
                            
                            return utilities;
                        },
                        title: "Electric Utility",
                        hideifoptions: {
                            'electulrate': {
                                type: 'in',
                                values: [
                                    'State Average Rate'
                                ]
                            }
                        }
                    }
                },
                {
                    title: "Electric Utility Tariff: Green Upgrade Scenario",
                    tippyText: 'This is the electricity tariff the resident will use after making the green upgrades. This may be the same as the resident\’s current tariff, or different if the green upgrades enable additional tariffs or disallow tariffs. For example, if the user selects "Install Rooftop Solar Panels", only the tariffs available to a rooftop solar residential customer will be listed. The default is the most common residential electricity tariff applicable to the user entered information.',
                    input: {
                        name: 'cleanelectricratetype',
                        type: 'select',
                        values: (formData) => {
                            if (!formData?.electulrate || !formData?.tariffsDataClean)
                                return [];

                            const utilities = formData?.tariffsDataClean
                                .filter(utility => utility.lseId == formData.electulrate)
                                .map(utility => ({
                                    title: utility.tariffName,
                                    value: `${utility.masterTariffId}_${utility.tariffId}`
                                }));
                            
                            return utilities;
                        },
                        title: "Green Electric Utility",
                        hideifoptions: {
                            'electulrate': {
                                type: 'in',
                                values: [
                                    'State Average Rate'
                                ]
                            }
                        }
                    }
                },
                {
                    title: "Electric Volumetric Rate: Traditional Scenario ($/kWh)",
                    tippyText: "This is the average electricity rate per unit (kWh) of electricity used, not including fixed charges, that the resident currently pays. If “State Average” is selected, the default value is the 2023 average residential electricity rate in the corresponding state, with total revenue and sales sourced from <a href='https://www.eia.gov/electricity/sales_revenue_price/' target='__blank'>Energy Information Administration</a> before removing revenue from fixed charges. If a specific electric utility is selected, the default is the volumetric rate corresponding to the “Electric Utility Tariff: Green Upgrade Scenario” selected. If the tariff has seasonal, usage, or time-of-day variability in pricing, “Variable” is listed.",
                    input: {
                        name: 'rateelec_retail_fe',
                        type: 'text',
                        rounding: 3,
                        max: 0.5,
                        title: "Electric Volumetric Rate: Traditional Scenario ($/kWh)",
                        disableifoptions: {
                            'electulrate': {
                                type: 'notin',
                                values: [
                                    'State Average Rate'
                                ]
                            }
                        },
                        partialDisable: true
                    }
                },
                {
                    title: "Electric Volumetric Rate: Green Upgrade Scenario ($/kWh)",
                    tippyText: 'This is the average electricity rate per unit (kWh) of electricity used, not including fixed charges, that the resident will pay after making the green upgrades. If “State Average Rate” is selected, the default value is the 2023 average residential electricity rate in the corresponding state, with total revenue and sales sourced from <a href="https://www.eia.gov/electricity/sales_revenue_price/" target="__blank">Energy Information Administration</a> before removing revenue from fixed charges. If a specific electric utility is selected, the default is the volumetric rate corresponding to the “Electric Utility Tariff: Green Upgrade Scenario” selected. If the tariff has seasonal, usage, or time-of-day variability in pricing, “Variable” is listed.',
                    input: {
                        name: 'rateelec_retailgreen_fe',
                        type: 'text',
                        title: "Electric Volumetric Rate: Green Upgrade Scenario ($/kWh)",
                        rounding: 3,
                        disableifoptions: {
                            'electulrate': {
                                type: 'notin',
                                values: [
                                    'State Average Rate'
                                ]
                            }
                        },
                        partialDisable: true
                    }
                },
                {
                    title: "Monthly Fixed Charge: Traditional Scenario ($/month)",
                    tippyText: 'This is the monthly charge for access to the electric grid that the resident currently pays; the amount is the same each month regardless of the volume of electricity used. If “State Average" is selected, the default value is the customer weighted average fixed charge according to RMI research of utility rate schedules in each state. If a specific electric utility is selected, the default is the fixed charge corresponding to the "Electric Utility Tariff: Traditional Scenario" selected.',
                    input: {
                        name: 'rateelec_fixed_fe',
                        type: 'text',
                        title: "Monthly Fixed Charge: Traditional Scenario ($/month)",
                        rounding: 2,
                        max: 50,
                        disableifoptions: {
                            'electulrate': {
                                type: 'notin',
                                values: [
                                    'State Average Rate'
                                ]
                            }
                        },
                        partialDisable: true
                    }
                },
                {
                    title: "Monthly Fixed Charge: Green Upgrade Scenario ($/month)",
                    tippyText: 'This is the monthly charge for access to the electric grid that the resident will pay after making the green upgrades; the amount is the same each month regardless of the volume of electricity used. If "State Average" is selected, the default value is the customer weighted average fixed charge according to RMI research of utility rate schedules in each state. If a specific electric utility is selected, the default is the fixed charge corresponding to the "Electric Utility Tariff: Green Upgrade Scenario" selected.',
                    input: {
                        name: 'rateelec_fixedsolar_fe',
                        type: 'text',
                        title: "Monthly Fixed Charge: Green Upgrade Scenario ($/month)",
                        rounding: 2,
                        max: 50,
                        disableifoptions: {
                            'electulrate': {
                                type: 'notin',
                                values: [
                                    'State Average Rate'
                                ]
                            }
                        },
                        partialDisable: true
                    }
                },
                {
                    title: "Solar Export Rate: Green Upgrade Scenario ($/kWh)",
                    tippyText: 'This is the compensation rate provided to customers for all solar electricity generated in excess of the home\’s electricity demand. If "State Average" is selected, the default value is estimated using state regulations and fall into four categories: (1) full retail rate, (2) less than retail rate, (3) avoided cost rate, and (4) no compensation. If a specific electric utility is selected, the default corresponds to the "Electric Utility Tariff: Green Upgrade Scenario" selected.',
                    input: {
                        name: 'rateelec_netmetering_fe',
                        type: 'text',
                        title: "Solar Export Rate: Green Upgrade Scenario ($/kWh)",
                        rounding: 3,
                        max: 0.5,
                        hideifoptions: {
                            'solar': {
                                type: 'notin',
                                values: [
                                    'Yes'
                                ]
                            }
                        },
                        disableifoptions: {
                            'electulrate': {
                                type: 'notin',
                                values: [
                                    'State Average Rate'
                                ]
                            }
                        },
                        partialDisable: true
                    }
                },
                {
                    title: "Annual Volumetric Rate Increase (%/year)",
                    tippyText: 'This is the annual percent increase in the utility electricity rate over the life of the analysis. The default value is the average annual increase in retail electricity prices in the state over the past 10 years, sourced from the <a href="https://www.eia.gov/electricity/sales_revenue_price/" target="__blank">Energy Information Administration.</a>',
                    input: {
                        name: 'rateelec_incr',
                        type: 'number',
                        rounding: 1,
                        min: 0,
                        max: 10,
                        step: 0.1,
                        multiplier: 100,
                        title: "Annual Volumetric Rate Increase (%/year)"
                    }
                },
                {
                    title: "Natural Gas Rates",
                    secondary: true,
                    subtitle: true,
                },
                {
                    title: "Natural Gas Volumetric Rate ($/ccf)",
                    tippyText: "This is the average natural gas rate per unit (ccf) of natural gas used, not including fixed charges, that the resident currently pays. The default value is the 2023 average natural gas rate in the corresponding state, with total revenue and sales sourced from the <a href='https://www.eia.gov/dnav/ng/ng_pri_sum_a_epg0_prs_dmcf_a.htm' target='__blank'>Energy Information Administration</a> before removing revenue from fixed charges.",
                    input: {
                        name: 'rateng_retail',
                        type: 'number',
                        rounding: 2,
                        min: 0,
                        max: 10,
                        title: "Natural Gas Volumetric Rate ($/ccf)",
                        hideifoptions: {
                            ...atLeastOneNaturalGas
                        }
                    }
                },
                {
                    title: "Monthly Fixed Charge: Traditional Scenario ($/month)",
                    tippyText: 'This is the monthly charge for access to the natural gas system; the amount is the same each month regardless of the volume of natural gas used. The default value is based on the customer weighted average fixed charge according to RMI research of utility rate schedules in each state.',
                    input: {
                        name: 'rateng_fixed',
                        type: 'number',
                        rounding: 2,
                        min: 0,
                        max: 50,
                        title: "Monthly Fixed Charge: Traditional Scenario ($/month)",
                        hideifoptions: atLeastOneNaturalGas
                    }
                },
                {
                    title: "Annual Retail Rate Increase (%/year)",
                    tippyText: 'This is the annual percent increase in the natural gas rate over the life of the analysis. The default value is the average annual increase in retail natural gas rates in the state over the past 10 years, sourced from the <a href="https://www.eia.gov/dnav/ng/ng_pri_sum_a_epg0_prs_dmcf_a.htm" target="__blank">Energy Information Administration.</a>',
                    input: {
                        name: 'rateng_incr',
                        type: 'number',
                        rounding: 1,
                        min: 0,
                        max: 10,
                        step: 0.1,
                        multiplier: 100,
                        title: "Yearly Retail Rate Increase (%/year)",
                        hideifoptions: atLeastOneNaturalGas
                    }
                },
                {
                    title: "Fuel Oil Rates",
                    textStart: true,
                    secondary: true,
                    subtitle: true,
                },
                {
                    title: "Fuel Oil Volumetric Rate ($/gallon)",
                    tippyText: 'This is the average fuel oil rate per unit (gallon) that the resident currently pays. The default value is the 2023 average fuel oil rate in the corresponding region, sourced from the <a href="https://www.eia.gov/dnav/pet/pet_pri_wfr_a_EPD2F_PRS_dpgal_m.htm" target="__blank"> Energy Information Administration.</a>',
                    input: {
                        name: 'ratefueloil',
                        type: 'number',
                        rounding: 2,
                        min: 1,
                        max: 10,
                        step: 0.01,
                        title: "Fuel Oil Volumetric Rate ($/gallon)",
                        hideifoptions: {
                            ...atLeastOneFuelOil
                        }
                    }
                },
                {
                    title: "Annual Retail Rate Increase (%/year)",
                    tippyText: 'This is the annual percent increase in the fuel oil rate over the life of the analysis. The default is the average annual increase in fuel oil rates in the region over the past 20 years, sourced from the <a href="https://www.eia.gov/dnav/pet/pet_pri_wfr_a_EPD2F_PRS_dpgal_m.htm" target="__blank">Energy Information Administration.</a>',
                    input: {
                        name: 'ratefueloil_incr',
                        type: 'number',
                        rounding: 1,
                        min: 0,
                        max: 10,
                        step: 0.1,
                        multiplier: 100,
                        title: "Annual Retail Rate Increase (%/year)",
                        hideifoptions: atLeastOneFuelOil
                    }
                },
                {
                    title: "Propane Rates",
                    textStart: true,
                    secondary: true,
                    subtitle: true,
                },
                {
                    title: 'Propane Volumetric Rate ($/gallon)',
                    tippyText: 'This is the average propane rate per unit (gallon) that the resident currently pays. The default value is the 2023 average propane rate in the corresponding region, sourced from the <a href="https://www.eia.gov/dnav/pet/pet_pri_wfr_a_EPD2F_PRS_dpgal_m.htm" target="__blank">Energy Information Administration.</a>',
                    input: {
                        name: 'ratepropane',
                        type: 'number',
                        rounding: 2,
                        min: 1,
                        max: 10,
                        step: 0.01,
                        title: 'Propane Volumetric Rate ($/gallon)',
                        hideifoptions: {
                            ...atLeastOnePropane
                        }
                    }
                },
                {
                    title: "Annual Retail Rate Increase (%/year)",
                    tippyText: 'This is the annual percent increase in the propane rate over the life of the analysis. The default value is the average annual increase in propane rates in the region over the past 20 years, sourced from the <a href="https://www.eia.gov/dnav/pet/pet_pri_wfr_a_EPLLPA_PRS_dpgal_m.htm" target="__blank">Energy Information Administration.</a>',
                    input: {
                        name: 'ratepropane_incr',
                        type: 'number',
                        rounding: 1,
                        min: 0,
                        max: 10,
                        step: 0.1,
                        multiplier: 100,
                        title: "Annual Retail Rate Increase (%/year)",
                        hideifoptions: atLeastOnePropane
                    }
                },
                {
                    title: "Gasoline Price",
                    textStart: true,
                    secondary: true,
                    subtitle: true,
                },
                {
                    title: 'Gasoline Price ($/gallon)',
                    tippyText: 'This is the current price of gasoline in the corresponding area. The default value is the 2023 average regular unleaded gasoline rate in the corresponding region, sourced from the <a href="https://www.eia.gov/petroleum/gasdiesel/" target="__blank">Energy Information Administration.</a>',
                    input: {
                        name: 'rategasoline',
                        type: 'number',
                        rounding: 2,
                        min: 1,
                        max: 10,
                        step: 0.01,
                        title: "Gasoline Price ($/gallon)",
                        hideifoptions: atLeastOneTransportUpgrade
                    }
                },
                {
                    title: "Annual Retail Rate Increase (%/year)",
                    tippyText: 'This is the annual increase in gasoline prices over the analysis period. The default value is the average annual increase in gasoline prices in the region over the past 20 years, sourced the <a href="https://www.eia.gov/petroleum/gasdiesel/" target="__blank"> Energy Information Administration.</a>',
                    input: {
                        name: 'rategasoline_incr',
                        type: 'number',
                        rounding: 1,
                        min: 0,
                        max: 10,
                        step: 0.1,
                        multiplier: 100,
                        title: "Annual Retail Rate Increase (%/year)",
                        hideifoptions: {
                            ...atLeastOneTransportUpgrade,
                            carmodel: {
                                type: 'in',
                                values: [
                                    'Do not currently own a vehicle'
                                ]
                            }
                        }
                    }
                }
            ]
        },
        {
            title: "Maintenance Costs",
            image: maintenanceCosts,
            inputs: [
                {
                    title: "Space Cooling and Heating",
                    textStart: true,
                    secondary: true,
                    subtitle: true,
                },
                {
                    title: "Air-Source Heat Pump Annual Maintenance Costs ($)",
                    tippyText: 'Enter the annual maintenance costs, including servicing and air-filter replacements. The default value includes costs for servicing every other year and air-filter replacements for the air handler or each mini-split unit, based on estimates from <a href="https://www.homeadvisor.com/cost/heating-and-cooling/service-maintain-ac-unit/" target="__blank">HomeAdvisor.com</a>.',
                    input: {
                        name: 'ashpmtncosts',
                        type: 'number',
                        rounding: 0,
                        min: 0,
                        max: 500,
                        title: "Air-Source Heat Pump Annual Maintenance Costs ($)",
                        hideifoptions: {
                            'ashp': {
                                type: 'notin',
                                values: [
                                    'Yes'
                                ]
                            }
                        }
                    }
                },
                {
                    title: "Air-Source Heat Pump Supplemental Heating Annual Maintenance Costs ($)",
                    tippyText: 'These are the annual maintenance costs, including servicing and air-filter replacements, if applicable. The default value includes costs for (1) servicing every other year for all combustion systems and every five years for electric resistance systems, and (2) air-filter replacements during the heating season for ducted systems, based on estimates from <a href="https://www.homeadvisor.com/cost/heating-and-cooling/repair-a-furnace/" target="__blank">HomeAdvisor.com</a>.',
                    input: {
                        name: 'spaceheatspmt_cleanermtncosts',
                        type: 'number',
                        rounding: 0,
                        min: 0,
                        max: 500,
                        title: "Air-Source Heat Pump Supplemental Heating Annual Maintenance Costs ($)",
                        hideifoptions: {
                            'spaceheatspmt_cleanertech': {
                                type: 'notin',
                                values: [
                                    'Hybrid Heat Pump'
                                ],
                            },
                            'ashpducting': {
                                type: 'notin',
                                values: [
                                    "Ductless Mini Split"
                                ]
                            }
                        }
                    }
                },
                {
                    title: "[ac_current] Annual Maintenance Costs ($)",
                    tippyText: 'These are the annual maintenance costs, including servicing and air-filter replacements. For central cooling systems, the default value includes costs for semiannual servicing and air-filter replacements during the cooling season, based on estimates from <a href="https://www.homeadvisor.com/cost/heating-and-cooling/service-maintain-ac-unit/" target="__blank">HomeAdvisor.com</a>.',
                    input: {
                        name: 'acmtncosts',
                        type: 'number',
                        rounding: 0,
                        min: 0,
                        max: 500,
                        title: "[ac_current] Annual Maintenance Costs",
                        hideifoptions: {
                            'ashp': {
                                type: 'notin',
                                values: [
                                    'Yes'
                                ]
                            },
                            ac_current: {
                                type: 'in',
                                values: [
                                    'Window AC',
                                    'No Mechanical Cooling'
                                ]
                            }
                        }
                    }
                },
                {
                    title: "[spaceheat_current] Annual Maintenance Costs ($)",
                    tippyText: 'These are the annual maintenance costs, including servicing and air-filter replacements, if applicable. The default value includes costs for servicing every other year for all combustion systems and every five years for electric resistance systems, and air-filter replacements during the heating season for ducted systems, based on estimates from <a href="https://www.homeadvisor.com/cost/heating-and-cooling/repair-a-furnace/" target="__blank">HomeAdvisor.com.</a>',
                    input: {
                        name: 'spaceheatmtncosts',
                        type: 'number',
                        rounding: 0,
                        min: 0,
                        max: 500,
                        title: "[spaceheat_current] Annual Maintenance Costs",
                        hideifoptions: {
                            'ashp': {
                                type: 'notin',
                                values: [
                                    'Yes'
                                ]
                            }
                        }
                    }
                },
                {
                    title: "Water Heating",
                    textStart: true,
                    secondary: true,
                    subtitle: true,
                },
                {
                    title: "Water Heater Annual Maintenance Costs ($)",
                    tippyText: 'These are the annual maintenance costs, including servicing and draining. The default value is $75 based on an estimated $150 for semiannual servicing, based on estimates from <a href="https://www.homeadvisor.com/cost/plumbing/install-a-water-heater/" target="__blank">HomeAdvisor.com.</a>',
                    input: {
                        name: 'whmtncosts',
                        type: 'number',
                        rounding: 0,
                        min: 0,
                        max: 500,
                        title: "Water Heater Annual Maintenance Costs",
                        hideifoptions: {
                            'hpwh': {
                                type: 'notin',
                                values: [
                                    'Yes'
                                ]
                            }
                        }
                    }
                },
                {
                    title: "Transportation",
                    textStart: true,
                    secondary: true,
                    subtitle: true,
                },
                {
                    title: "[phevmake] [phevmodel] Maintenance Costs per Mile ($/mile)",
                    tippyText: 'This is the average maintenance cost per mile for the selected electric vehicle in the first year of operation. The default value is sourced from a <a href="https://www.osti.gov/biblio/1780970/" target="__blank">US Department of Energy study.</a>',
                    input: {
                        name: 'bevmtncosts',
                        type: 'number',
                        rounding: 2,
                        min: 0,
                        max: 1,
                        step: 0.01,
                        title: "[phevmake] [phevmodel] Maintenance Costs per Mile",
                        hideifoptions: {
                            ...atLeastOneTransportUpgrade,
                            'buy-electric-car': {
                                type: 'notin',
                                values: [
                                    'Yes'
                                ]
                            },
                            'vehicle_type': {
                                type: 'notin',
                                values: [
                                    'BEV'
                                ]
                            }
                        }
                    }
                },
                {
                    title: "[phevmake] [phevmodel] Maintenance Costs per Mile ($/mile)",
                    tippyText: 'This is the average maintenance cost per mile for the selected electric vehicle in the first year of operation. The default value is sourced from a <a href="https://www.osti.gov/biblio/1780970/" target="__blank">US Department of Energy study.</a>',
                    input: {
                        name: 'phevmtncosts',
                        type: 'number',
                        rounding: 2,
                        min: 0,
                        max: 1,
                        step: 0.01,
                        title: "[phevmake] [phevmodel] Maintenance Costs per Mile",
                        hideifoptions: {
                            ...atLeastOneTransportUpgrade,
                            'buy-electric-car': {
                                type: 'notin',
                                values: [
                                    'Yes'
                                ]
                            },
                            'vehicle_type': {
                                type: 'notin',
                                values: [
                                    'PHEV'
                                ]
                            }
                        }
                    }
                },
                {
                    title: "[phevmake] [phevmodel] Annual Vehicle Registration Cost ($)",
                    tippyText: 'This is the annual registration fee for the electric vehicle selected. The default value is sourced from research conducted by the <a href="https://www.ncsl.org/research/transportation/registration-and-title-fees-by-state.aspx" target="__blank">National Council of State Legislatures.</a> Some states charge a premium for electric vehicles to recover lost gasoline tax revenue.',
                    input: {
                        name: 'evvehiclecosts_regist',
                        type: 'number',
                        rounding: 0,
                        min: 0,
                        max: 5000,
                        title: "[phevmake] [phevmodel] Annual Vehicle Registration Cost",
                        hideifoptions: {
                            ...atLeastOneTransportUpgrade,
                            'buy-electric-car': {
                                type: 'notin',
                                values: [
                                    'Yes'
                                ]
                            }
                        }
                    }
                },
                {
                    title: "[ffvmake] [ffvmodel] Maintenance Costs per Mile ($/mile)",
                    tippyText: 'This is the average maintenance cost per mile for the selected traditional gasoline vehicle in the first year of operation. The default value is sourced from a <a href="https://www.osti.gov/biblio/1780970/" target="__blank">US Department of Energy study.</a>',
                    input: {
                        name: 'ffvmtncosts',
                        type: 'number',
                        rounding: 2,
                        min: 0,
                        max: 1,
                        step: 0.01,
                        title: "[ffvmake] [ffvmodel] Maintenance Costs per Mile",
                        hideifoptions: atLeastOneTransportUpgrade
                    }
                },
                {
                    title: "[ffvmake] [ffvmodel] Annual Vehicle Registration Cost ($)",
                    tippyText: 'This is the annual registration fee for the selected traditional gasoline vehicle. The default value is based on research conducted by the <a href="https://www.ncsl.org/research/transportation/registration-and-title-fees-by-state.aspx" target="__blank">National Council of State Legislatures</a> and may be adjusted based on the size and age of your selected vehicle.',
                    input: {
                        name: 'vehiclecosts_regist',
                        type: 'number',
                        rounding: 0,
                        min: 0,
                        max: 5000,
                        title: "[ffvmake] [ffvmodel] Annual Vehicle Registration Cost",
                        hideifoptions: atLeastOneTransportUpgrade
                    }
                },
                {
                    title: "Electric Bike Maintenance Costs per Mile ($/mile)",
                    tippyText: 'This is the average maintenance cost per mile for the selected electric bike in the first year of operation. The default value is sourced from various websites and based on the average cost for replacing bicycle tires, a bicycle chain, and completing a tune-up on a yearly basis.',
                    input: {
                        name: 'ebikemtncosts',
                        type: 'number',
                        rounding: 2,
                        min: 0,
                        max: 1,
                        step: 0.01,
                        title: "Electric Bike Maintenance Costs per Mile ($/mile)",
                        hideifoptions: {
                            atLeastOneTransportUpgrade,
                            'ebike': {
                                type: 'notin',
                                values: [
                                    'Yes'
                                ]
                            }
                        }
                    }
                },
                {
                    title: "Annual Vehicle Insurance Cost ($)",
                    tippyText: 'This is the annual cost to insure a vehicle in your state. The default value is sourced from <a href="https://www.insure.com/car-insurance/car-insurance-rates.html" target="__blank">insure.com.</a>',
                    input: {
                        name: 'vehiclecosts_insur',
                        type: 'number',
                        rounding: 0,
                        min: 0,
                        max: 5000,
                        title: "Annual Vehicle Insurance Cost",
                        hideifoptions: atLeastOneTransportUpgrade
                    }
                },
                {
                    title: "Alternative Transportation",
                    textStart: true,
                    secondary: true,
                    subtitle: true,
                },
                {
                    title: "Public Transportation Monthly Pass Cost ($/month)",
                    tippyText: 'This is the cost for a monthly pass to the public transit system in the zip code. The default value is based on the primary public transit system available in the county. If the county is not included in the database, the default value is the average of transit systems in the state.',
                    input: {
                        name: 'publictransitcost',
                        type: 'number',
                        rounding: 0,
                        min: 0,
                        max: 300,
                        title: "Public Transportation Monthly Pass Cost",
                        hideifoptions: {
                            vehicle: {
                                type: 'notin',
                                values: [
                                    'Yes: Car-Free'
                                ],
                                or: {
                                    publictransit: {
                                        type: 'notin',
                                        values: [
                                            'Yes'
                                        ]
                                    }
                                }
                            }
                        }
                    }
                },
                {
                    title: "Ride-hailing Cost Per Mile ($/mile)",
                    tippyText: 'This is the cost per mile when using ride-hailing services. The default value is based on research from the <a href="https://www.vtpi.org/tca/tca0501.pdf" target="__blank">Victoria Transport Policy Institute.</a>',
                    input: {
                        name: 'ridehail_costpermile',
                        type: 'number',
                        rounding: 2,
                        min: 0,
                        max: 100,
                        title: "Ride-hailing Cost Per Mile ($/mile)",
                        hideifoptions: {
                            "finalise-transport": {
                                type: 'notin',
                                values: [
                                    'Ridehailing'
                                ]
                            }
                        }
                    }
                },
                {
                    title: "Rental Vehicle Cost Per Day ($/day)",
                    tippyText: 'This is the cost per day when renting a vehicle or using a carsharing service. This value does not include any gasoline or electric charging costs. The default value is based on research from the <a href="https://www.vtpi.org/tca/tca0501.pdf" target="__blank">Victoria Transport Policy Institute.</a>',
                    input: {
                        name: 'rentalcar_costperday',
                        type: 'number',
                        rounding: 0,
                        min: 0,
                        max: 1000,
                        title: "Rental Vehicle Cost Per Day ($/day)",
                        hideifoptions: {
                            "finalise-transport": {
                                type: 'notin',
                                values: [
                                    'Carsharing/Rental Car'
                                ]
                            }
                        }
                    }
                }
            ]
        }
    ]
};

export default OperationAndMaintenance;
