import * as React from "react";
import { useState, useContext } from 'react';

import FormDataContext from '../../context/FormDataContext';

import * as styles from './modal.module.scss';

const ResetModal = ({ modalResetOpen, setResetModalOpen }) => {

  const { clearFormData } = useContext(FormDataContext);

  return (
    <div className={styles.modal + (modalResetOpen ? ` ` + styles.hidden : '')}>
      <div className={styles.modalMain + ` ` + styles.modalResetSize }>
        <section>
          <div className={styles.modalHeader}>
            <h2 className={styles.contactHeader}>Reset Analysis</h2>
            <button className={styles.modalClose} onClick={e => setResetModalOpen(!modalResetOpen)}>Close</button>
          </div>
            <article>
              <p>Are you sure you want to reset the calculator? This will clear all of the inputs you have entered. You cannot undo this action.</p>

              <div className={styles.modalButtons}>
                <button className="button" onClick={e => clearFormData()}>Yes</button>
                <button className="button" onClick={e => setResetModalOpen(!modalResetOpen)}>Cancel</button>
              </div>
            </article>
        </section>
      </div>
    </div>
  );
};

export default ResetModal