const Start = {
    input: {
        name: 'mode',
        type: 'radio',
        // onChange: (e) => {
        //     document.querySelector('.continue')?.click();
        // },
        // onClick: (e) => {
        //     document.querySelector('.continue')?.click();
        // },
        values: [
            {
                title: 'Home',
                value: 'Home (TEMPEST-H)'
            },
            {
                title: 'Transportation',
                value: 'Transportation (TEMPEST-T)'
            },
            {
                title: 'Home + Transportation',
                value: 'Home & Transportation (TEMPEST-X)'
            }
        ]
    }
};

export default Start;