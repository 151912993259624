const ResultsSix = () => (
    //TEMPEST Live Version_s1.xlsm
    {
        "atLeastOneUpgrade":true,
        "basic-information":true,
        "zipData":{
            "Zip Code":80304,
            "Official USPS State Code":"CO",
            "Density":1084.5,
            "Urbanity":"Urban",
            "Primary Official County Name":"Boulder",
            "State_County":"CO_Boulder",
            "IECC Climate Zone":5,
            "IECC Moisture Regime":"B",
            "BA Climate Zone":"Cold",
            "state":"CO",
            "homeareadefaults":{
                "Single-Family Detached Home":"2449",
                "Townhome":"1494",
                "Apartment":"828",
                "Mobile Home":"1181"
            }
        },
        "rural-zip":false,
        "zip-greater-than-four":true,
        "hpwh":"No",
        "wthz":"No",
        "ashp":"No",
        "range":"No",
        "solartpo_ownership":"Yes",
        "storage":"No",
        "rangefinance_down":0,
        "rangefinance_yrs":5,
        "upgradesfinance":"No",
        "solartpo_escalator":0.02,
        "vehicle":"No",
        "communitysolar":"No",
        "additional_home_upgrade":"No",
        "ac_current":"Ducted Central AC",
        "gridintensity":"712",
        "electricratetype":"State Average Rate",
        "rateelec_fixed":"10",
        "rateelec_fixedsolar":10,
        "inflationrate":2.48,
        "otherincent_yrs":0,
        "otherincent":0,
        "emissionsreduction":"5.3",
        "energyusage_spaceheating":"0.66",
        "energyusage_waterheating":"0.15",
        "energyusage_cooking":"0.01",
        "energyusage_other":"0.14",
        "rateelec_retail":"0",
        "rateelec_netmetering":"0.1470",
        "rateelec_incr":0,
        "rateng_fixed":"10",
        "rateng_retail":0,
        "rateng_incr":"0.038",
        "rangecapco":0,
        "fedtaxliab":0,
        "solartpo_yrs":0,
        "solartpo_yronerate":0,
        "rangefinance_bauinterest":0,
        "rangefinance_interest":0,
        "ffvmtncosts":0.101,
        "ffveff":0,
        "miles":0,
        "rategasoline":0,
        "rategasoline_incr":0,
        "vehiclecosts_regist":0,
        "vehiclecosts_insur":0,
        "ffvcapco":0,
        "vehiclecapco_incr":0,
        "salestaxrate":0,
        "green-upgrades":true,
        "solarfinance":"No: Cash Purchase",
        "solardegradation":0.005,
        "incomelvl":"80%-150% Area Median Income",
        "solarkw":"3.4",
        "solarcapco_perw":"3.58",
        "solarcapco":0,
        "solarincent_fed":0,
        "solarincent_state":0,
        "solarincent_ongoing":0,
        "solarincent_yrs":0,
        "traditional-systems":true,
        "energyusage_spacecooling":"0.03",
        "energyusage_total":"123",
        "energybill":"203.19",
        "solargen":"5874",
        "solartilt":"20° Tilt South",
        "wh_current":"Tank Natural Gas Water Heater",
        "spaceheat_current":"Natural Gas Furnace",
        "cooking_current":"Natural Gas Range",
        "solar":"Yes",
        "homearea":"2750",
        "mode":"Home (TEMPEST-H)",
        "zipcode":"80304",
        "hometype":"Single-Family Detached Home",
        "homeyr":"pre-1980",
        "electulrate":"State Average Rate"
    }
);

export default ResultsSix;