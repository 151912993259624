import solarBatteryStorage from './../../images/optional_steps_rooftop_solar.png';
import userFinancialDetails from './../../images/capital-costs-user-financial-details.png';
import weatherization from './../../images/optional_steps_weatherization.png';
import spaceCoolingHeating from './../../images/optional_steps_space_cooling_and_heating.png';
import waterHeating from './../../images/optional_steps_water_heating.png';
import cookingAppliance from './../../images/optional_steps_cooking_appliance.png';
import otherHomeUpgrades from './../../images/capital-costs-other-home-upgrades.png';
import vehicle from './../../images/technical-details-motor vehicle.png';
import otherIncentiveInfo from './../../images/capital-costs-other-incentive-info.png';

import {
    atLeastOneHomeUpgrade,
    atLeastOneTransportUpgrade
} from './commonHides'

const UpFrontCostsAndInCentives = {
    pageName: "Up-front Costs & Incentives",
    sections: [
        {
            title: "Incentive Criteria and Economic Factors",
            image: userFinancialDetails,
            inputs: [
                {
                    title: 'Federal Tax Liability ($/year)',
                    tippyText: 'This is the annual amount of taxes the individual owes on their earned income to the federal government. This value is used when determining the user’s ability to benefit from <a href="https://www.irs.gov/newsroom/irs-provides-tax-inflation-adjustments-for-tax-year-2023" target="__blank">federal income tax brackets. </a> The default value is calculated based on federal income tax brackets and the user’s estimated annual income. Annual income is estimated based on the estimated state median household income for a married family filing jointly, sourced from the  <a href="https://www.census.gov/library/publications/2021/demo/p60-273.html" target="__blank">Census Bureau </a> and <a href="https://fred.stlouisfed.org/release/tables?eid=259515&rid=249" target="__blank">Federal Reserve Bank of St. Louis, </a> opting for the standard deduction and 5% 401(k) contributions, and adjusted based on the household income category selected.',
                    input: {
                        type: 'number',
                        rounding: 0,
                        title: 'Federal Tax Liability ($/year)',
                        name: 'fedtaxliab',
                        min: 0,
                        max: 100000,
                        hideifoptions: {
                            
                        }
                    }
                },
                {
                    title: 'Household Income Category',
                    tippyText: 'This is a metric of how the household income compares with the surrounding area and is used for some income-qualifying incentives. Identify area median income using Fannie Mae\’s <a href="https://ami-lookup-tool.fanniemae.com/amilookuptool/" target="__blank">Area Median Income Lookup Tool.</a>',
                    input: {
                        title: 'Household Income Category',
                        type: 'select',
                        name: 'incomelvl',
                        values: [
                            {
                                title: '<80% Area Median Income',
                                value: '<80% Area Median Income'
                            },
                            {
                                title: '80%-150% Area Median Income',
                                value: '80%-150% Area Median Income'
                            },
                            {
                                title: '>150% Area Median Income',
                                value: '>150% Area Median Income'
                            }
                        ]
                    }
                },
                {
                    title: "Active Home Electrification and Appliance Rebate Program",
                    tippyText: 'The Home Electrification and Appliance Rebates program awards grants to State energy offices and tribal entities to provide rebates that discount the price of high-efficiency electric home appliances, like heat pumps and heat pump waters, to low-income and moderate-income residents. The default is based on whether or not the program was active in January 2025.',
                    input: {
                        title: 'Active Home Electrification and Appliance Rebate Program',
                        type: 'select',
                        name: 'heehra',
                        values: [
                            {
                                title: 'Yes',
                                value: 'Yes'
                            },
                            {
                                title: 'No',
                                value: 'No'
                            }
                        ],
                        hideifoptions: {
                            mode: {
                                type: 'notin',
                                values: [
                                    'Home (TEMPEST-H)',
                                    'Home & Transportation (TEMPEST-X)'
                                ]
                            }
                        }
                    }
                },
                {
                    title: "Active Home Efficiency Rebate Program",
                    tippyText: 'The Home Efficiency Rebates program awards grants to State energy offices to provide rebates that discount the price of energy-saving retrofits that reduce at least 20% of total home energy usage, like weatherization and heat pumps, to residents. The default is based on whether or not the program was active in January 2025.',
                    input: {
                        title: 'Active Home Efficiency Rebate Program',
                        type: 'select',
                        name: 'homes',
                        values: [
                            {
                                title: 'Yes',
                                value: 'Yes'
                            },
                            {
                                title: 'No',
                                value: 'No'
                            }
                        ],
                        hideifoptions: {
                            mode: {
                                type: 'notin',
                                values: [
                                    'Home (TEMPEST-H)',
                                    'Home & Transportation (TEMPEST-X)'
                                ]
                            }
                        }
                    }
                },
                {
                    title: "Inflation Rate (%)",
                    tippyText: 'This is the inflation rate applied to maintenance costs over the lifetime of the analysis. The inflation rate is not applied to future upfront costs given uncertainty in future cost curves for systems like electric vehicles, battery storage, rooftop solar, etc. The default is the average annual US inflation rate over the past 20 years, sourced from the <a href="https://www.bls.gov/cpi/" target="__blank">US Bureau of Labor Statistics Consumer Price Index.</a>',
                    input: {
                        name: 'inflationrate',
                        type: 'number',
                        rounding: 2,
                        multiplier: 100,
                        min: 0,
                        max: 25,
                        step: 0.1,
                        title: "Inflation Rate (%)",
                        hideifoptions: {
                            'mode': {
                                type: 'in',
                                values: [
                                    'Transportation (TEMPEST-T)'
                                ]
                            }
                        }
                    }
                },
                {
                    title: "Cost Discount Rate (%)",
                    tippyText: 'This is the rate used to discount future cash flows to their present value in the cost impact analysis. The default assumes 0% to convey non-discounted results.',
                    input: {
                        name: 'discountrate_cost',
                        type: 'number',
                        rounding: 2,
                        multiplier: 100,
                        min: 0,
                        max: 25,
                        step: 0.1,
                        title: "Cost Discount Rate (%)",
                        hideifoptions: {}
                    }
                },
                {
                    title: "Climate Discount Rate (%)",
                    tippyText: 'This is the rate used to discount future greenhouse gases emitted to their present value in the climate impact analysis. The default assumes 0% to convey non-discounted results.',
                    input: {
                        name: 'discountrate_climate',
                        type: 'number',
                        rounding: 2,
                        multiplier: 100,
                        min: 0,
                        max: 25,
                        step: 0.1,
                        title: "Climate Discount Rate (%)",
                        hideifoptions: {}
                    }
                },
            ],
        },
        {
            title: "Solar and Battery Storage",
            image: solarBatteryStorage,
            inputs: [
                {
                    title: 'Total Rooftop Solar Installed Cost ($)',
                    tippyText: 'This is the installed cost of the rooftop solar PV system. The default value is based on the “Solar PV System Size (kW)” and the estimated residential solar panel cost per Watt. The residential solar panel cost per Watt is estimated based on state average relative residential solar panel cost per Watt for a 5 kW system in 2022, sourced from <a href="https://www.energysage.com" target="_blank">energysage.com</a>, and then adjusted based on the user\’s solar panel system size using a trendline of national average installed costs by system size, sourced from <a href="https://emp.lbl.gov/tracking-the-sun" target="_blank">Lawrence Berkeley National Lab</a>.',
                    input: {
                        type: 'number',
                        title: 'Total Rooftop Solar Installed Cost ($)',
                        name: 'solarcapco',
                        rounding: 0,
                        min: 0,
                        max: 250000,
                        disabled: 'disabled',
                        hideifoptions: {
                            solar: {
                                type: 'notin',
                                values: [
                                    'Yes'
                                ]
                            }
                        },
                        referenceText: {
                            text: `For reference, this solar PV system cost is equal to $[solarcapco_perw] per Watt.`,
                            modifiedVariables: {
                                "solarcapco_perw": {
                                    rounding: 2,
                                },
                            },
                            condition: (formData) => {
                                return formData?.solarcapco;
                            }
                        },
                    }
                },
                {
                    title: 'Battery Storage Installed Cost ($)',
                    tippyText: 'This is the installed cost of the battery storage system. The default value is based on Tesla Powerwall material costs, based on July 2023 pricing, corresponding to the battery storage system size selected and corresponding labor installation.',
                    input: {
                        type: 'number',
                        title: 'Battery Storage Installed Cost ($)',
                        name: 'storagecapco',
                        rounding: 0,
                        min: 0,
                        max: 100000,
                        hideifoptions: {
                            storage: {
                                type: 'notin',
                                values: [
                                    'Yes'
                                ]
                            }
                        }

                    }
                },
                {
                    title: 'Solar PV and Battery Storage Ongoing Incentives ($/MWh)',
                    tippyText: "This is the value for solar incentives that are based on the solar energy produced (i.e., performance-based incentives or renewable energy certificates). The default value is based on the state and/or utility performance-based incentives or <a href='https://www.flettexchange.com/' target='_blank'>recent value of renewable energy certificates</a> corresponding to the state selected.",
                    input: {
                        name: 'solarincent_ongoing',
                        type: 'number',
                        rounding: 0,
                        min: 0,
                        max: 500,
                        title: "Solar PV and Battery Storage Ongoing Incentives ($/MWh)",
                        hideifoptions: {
                            solar: {
                                type: 'notin',
                                values: [
                                    'Yes'
                                ],
                                or: {
                                    storage: {
                                        type: 'notin',
                                        values: [
                                            'Yes'
                                        ]
                                    }
                                }
                            }
                        }
                    }
                },
                {
                    title: 'Number of Years the Ongoing Incentives Are Received',
                    input: {
                        name: 'solarincent_yrs',
                        type: 'number',
                        min: 0,
                        max: 25,
                        title: "Number of Years the Ongoing Incentives Are Received",
                        hideifoptions: {
                            solar: {
                                type: 'notin',
                                values: [
                                    'Yes'
                                ],
                                or: {
                                    storage: {
                                        type: 'notin',
                                        values: [
                                            'Yes'
                                        ],
                                        or: {
                                            solar: {
                                                type: 'notin',
                                                values: [
                                                    'Yes'
                                                ]
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                },
                {
                    table: [
                        {
                            type: 'header',
                            inputs: [
                                {
                                },
                                {
                                    title: 'Federal',
                                    tippyText: "This is the total value in federal incentives or tax credits for installing rooftop solar and/or battery storage. The default value is the <a href='https://www.energy.gov/eere/solar/homeowners-guide-federal-tax-credit-solar-photovoltaics' target='_blank'>Residential Clean Energy Tax Credit (25D),</a> equal to 30% of the total up-front cost.",
                                },
                                {
                                    title: 'State',
                                    tippyText: "This is the total value in state government incentives or tax credits for installing rooftop solar and/or battery storage. The default value is based on any known statewide incentive programs.",
                                },
                                {
                                    title: 'Local or Utility',
                                    tippyText: "This is the total value in local government or utility incentives or tax credits for installing rooftop solar and/or battery storage. The default value is $0.",
                                }
                            ]
                        },
                        {
                            type: 'row',
                            inputs: [
                                {
                                    title: 'Solar PV and Battery Storage Upfront Incentives ($)​'
                                },
                                {
                                    input: {
                                        name: 'solarincent_fed',
                                        type: 'number',
                                        rounding: 0,
                                        min: 0,
                                        max: 75000,
                                        title: "Federal Up-front Incentives",
                                        hideifoptions: {
                                            solar: {
                                                type: 'notin',
                                                values: [
                                                    'Yes'
                                                ],
                                                or: {
                                                    storage: {
                                                        type: 'notin',
                                                        values: [
                                                            'Yes'
                                                        ]
                                                    }
                                                }
                                            }
                                        }
                                    }
                                },
                                {
                                    input: {
                                        name: 'solarincent_state',
                                        type: 'number',
                                        rounding: 0,
                                        min: 0,
                                        max: 75000,
                                        title: "State Up-front Incentives",
                                        hideifoptions: {
                                            solar: {
                                                type: 'notin',
                                                values: [
                                                    'Yes'
                                                ],
                                                or: {
                                                    storage: {
                                                        type: 'notin',
                                                        values: [
                                                            'Yes'
                                                        ]
                                                    }
                                                }
                                            }
                                        }
                                    }
                                },
                                {
                                    input: {
                                        name: 'solarincent_localorutility',
                                        type: 'number',
                                        rounding: 0,
                                        min: 0,
                                        max: 75000,
                                        title: "Local or Utility Up-front Incentives",
                                        hideifoptions: {
                                            solar: {
                                                type: 'notin',
                                                values: [
                                                    'Yes'
                                                ],
                                                or: {
                                                    storage: {
                                                        type: 'notin',
                                                        values: [
                                                            'Yes'
                                                        ]
                                                    }
                                                }
                                            }
                                        }
                                    }
                                },
                            ]
                        }
                    ]
                }
            ]
        },
        {
            title: "Weatherization",
            image: weatherization,
            inputs: [
                {
                    title: "Weatherization Installed Costs ($)",
                    tippyText: 'This is the installation cost of the selected home weatherization upgrades. The default value is based on the weatherization measure selected and corresponding data points in the National Renewable Energy Laboratory\’s <a href="https://remdb.nrel.gov/group_listing" target="__blank">National Residential Efficiency Measures Database</a>. See the FAQ section for more detail on these cost methodologies.',
                    input: {
                        name: 'wthzcapco',
                        type: 'number',
                        rounding: 0,
                        min: 0,
                        max: 20000,
                        title: "Weatherization Installed Costs ($)",
                        hideifoptions: {
                            'wthz': {
                                type: 'notin',
                                values: [
                                    'Yes'
                                ]
                            }
                        }
                    }
                },
                {
                    table: [
                        {
                            type: 'header',
                            inputs: [
                                {
                                    
                                },
                                {
                                    title: 'Federal Up-front Incentives',
                                    tippyText: "This is the total value in federal incentives or tax credits for weatherizing the home. For users that select “<80% Area Median Income” for the income question in this section, the default value is equal to the total weatherization costs up to $8,000 to estimate the value from the federal low-income <a href='https://www.energy.gov/scep/wap/weatherization-assistance-program' target='_blank'>Weatherization Assistance Program.</a>",
                                },
                                {
                                    title: 'State Up-front Incentives',
                                    tippyText: 'This is the total value in state government incentives or tax credits for weatherizing the home. The default value is based on any known statewide incentive programs. ',
                                },
                                {
                                    title: 'Local or Utility Up-front Incentives',
                                    tippyText: 'This is the total value in local government or utility incentives for weatherizing the home. While the default value is conservatively set to $0 given the lack of a national database of local incentives to reference, users should check with local contractors and other resources to identify additional incentives to enter here. ',
                                }
                            ]
                        },
                        {
                            type: 'row',
                            inputs: [
                                {
                                    title: 'Weatherization Incentives'
                                },
                                {
                                    input: {
                                        name: 'wthzincent_fed',
                                        type: 'number',
                                        rounding: 0,
                                        min: 0,
                                        max: 20000,
                                        title: "Federal Up-front Incentives",
                                        hideifoptions: {
                                            'wthz': {
                                                type: 'notin',
                                                values: [
                                                    'Yes'
                                                ]
                                            }
                                        }
                                    }
                                },
                                {
                                    input: {
                                        name: 'wthzincent_state',
                                        type: 'number',
                                        rounding: 0,
                                        min: 0,
                                        max: 20000,
                                        title: "State Up-front Incentives",
                                        hideifoptions: {
                                            'wthz': {
                                                type: 'notin',
                                                values: [
                                                    'Yes'
                                                ]
                                            }
                                        }
                                    }
                                },
                                {
                                    input: {
                                        name: 'wthzincent_localorutility',
                                        type: 'number',
                                        rounding: 0,
                                        min: 0,
                                        max: 20000,
                                        title: "Local Government or Utility Up-front Incentives",
                                        hideifoptions: {
                                            'wthz': {
                                                type: 'notin',
                                                values: [
                                                    'Yes'
                                                ]
                                            }
                                        }
                                    }
                                }
                            ]
                        }
                    ]
                }
            ]
        },
        {
            title: "Space Cooling and Heating",
            image: spaceCoolingHeating,
            inputs: [
                {
                    title: "Air-Source Heat Pump Installed Cost ($)",
                    tippyText: 'This is the installed cost of the air-source heat pump. The default value is based on estimated material, labor, and installation costs, and state <a href="https://www.rsmeansonline.com/" target="__blank">RS Means values</a>. See the FAQ section for more detail on these cost methodologies.',
                    input: {
                        name: 'ashpcapco',
                        type: 'number',
                        rounding: 0,
                        min: 0,
                        max: 50000,
                        title: "Air-Source Heat Pump Installed Cost ($)",
                        hideifoptions: {
                            "ashp": {
                                type: 'notin',
                                values: [
                                    'Yes'
                                ]
                            }
                        }
                    }
                },
                {
                    title: "Supplemental Heating Installed Cost ($)",
                    tippyText: 'This is the installed cost of the equipment used for supplemental heating to the air-source heat pump. The default value is based on estimated material, labor, and installation costs, and state <a href="https://www.rsmeansonline.com/" target="__blank">RS Means values</a>. See the FAQ section for more detail on these cost methodologies.',
                    input: {
                        name: 'spaceheatspmt_cleanercapco',
                        type: 'number',
                        rounding: 0,
                        min: 0,
                        max: 20000,
                        title: "Supplemental Heating Installed Cost ($)",
                        hideifoptions: {
                            'spaceheatspmt_cleanertech': {
                                type: 'notin',
                                values: [
                                    'Hybrid Heat Pump'
                                ],
                            },
                            'ashpducting': {
                                type: 'notin',
                                values: [
                                    "Ductless Mini Split"
                                ]
                            }
                        }
                    }
                },
                {
                    title: "[ac_current] Installed Cost ($)",
                    tippyText: 'This is the installed cost of the traditional replacement equipment selected for cooling the home. The default value is based on estimated material, labor, and installation costs, and state <a href="https://www.rsmeansonline.com/" target="__blank">RS Means values</a>. See the FAQ section for more detail on these cost methodologies.',
                    input: {
                        name: 'acbaucapco',
                        type: 'number',
                        rounding: 0,
                        min: 0,
                        max: 30000,
                        title: "[ac_current] Installed Cost ($)",
                        hideifoptions: {
                            "ashp": {
                                type: 'notin',
                                values: [
                                    'Yes'
                                ]
                            },
                            ac_current: {
                                type: 'in',
                                values: [
                                    'No Mechanical Cooling'
                                ]
                            }
                        }
                    }
                },
                {
                    title: "[spaceheat_current] Installed Cost ($)",
                    tippyText: 'This is the installed cost of the traditional replacement equipment selected for heating the home. The default value is based on estimated material, labor, and installation costs, and state <a href="https://www.rsmeansonline.com/" target="__blank">RS Means values</a>. See the FAQ section for more detail on these cost methodologies.',
                    input: {
                        name: 'spaceheatbaucapco',
                        type: 'number',
                        rounding: 0,
                        min: 0,
                        max: 20000,
                        title: "[spaceheat_current] Installed Cost ($)",
                        hideifoptions: {
                            "ashp": {
                                type: 'notin',
                                values: [
                                    'Yes'
                                ]
                            }
                        }
                    }
                },
                {
                    table: [
                        {
                            type: 'header',
                            inputs: [
                                {
                                    
                                },
                                {
                                    title: 'Federal Up-front Incentives',
                                    tippyText: 'This is the total value in federal incentives or tax credits for installing the corresponding technology. The default value is based on the technology’s efficiency and income qualifications for the federal <a href="https://www.irs.gov/credits-deductions/energy-efficient-home-improvement-credit">Energy Efficient Home Improvement Tax Credit (25C)</a> and <a href="https://www.rewiringamerica.org/policy/high-efficiency-electric-home-rebate-act">Home Electrification Rebate Program.</a>',
                                },
                                {
                                    title: 'State Up-front Incentives',
                                    tippyText: 'This is the total value in state government incentives or tax credits for installing the corresponding technology. The default value is based on any known statewide incentive programs.',
                                },
                                {
                                    title: 'Local or Utility Up-front Incentives',
                                    tippyText: 'This is the total value in local government or utility incentives for installing the corresponding technology. Although the default value is conservatively set to $0 given the lack of a national database of local  incentives to reference, users should check with local contractors and other resources to identify additional incentives to enter here.',
                                }
                            ]
                        },
                        {
                            type: 'row',
                            inputs: [
                                {
                                    title: 'Electric Air Source Heat Pump Incentives ($)'
                                },
                                {
                                    input: {
                                        name: 'ashpincent_fed',
                                        type: 'number',
                                        rounding: 0,
                                        min: 0,
                                        max: 20000,
                                        title: "Federal Up-front Incentives",
                                        hideifoptions: {
                                            "ashp": {
                                                type: 'notin',
                                                values: [
                                                    'Yes'
                                                ]
                                            }
                                        }
                                    }
                                },
                                {
                                    input: {
                                        name: 'ashpincent_state',
                                        type: 'number',
                                        rounding: 0,
                                        min: 0,
                                        max: 20000,
                                        title: "State Up-front Incentives",
                                        hideifoptions: {
                                            "ashp": {
                                                type: 'notin',
                                                values: [
                                                    'Yes'
                                                ]
                                            }
                                        }
                                    }
                                },
                                {
                                    input: {
                                        name: 'ashpincent_localorutility',
                                        type: 'number',
                                        rounding: 0,
                                        min: 0,
                                        max: 20000,
                                        title: "Local or Utility Up-front Incentives",
                                        hideifoptions: {
                                            "ashp": {
                                                type: 'notin',
                                                values: [
                                                    'Yes'
                                                ]
                                            }
                                        }
                                    }
                                }
                            ]
                        },
                        {
                            type: 'row',
                            inputs: [
                                {
                                    title: '[ac_current] Incentives ($)'
                                },
                                {
                                    input: {
                                        name: 'acbau_fedincent',
                                        type: 'number',
                                        rounding: 0,
                                        min: 0,
                                        max: 20000,
                                        title: "Federal Up-front Incentives",
                                        hideifoptions: {
                                            ac_current: {
                                                type: 'notin',
                                                values: [
                                                    'Ducted Central AC',
                                                    'Window AC',
                                                    'Ducted Central Heat Pump',
                                                    'Ductless Mini Split Heat Pump'
                                                ]
                                            },
                                            "ashp": {
                                                type: 'notin',
                                                values: [
                                                    'Yes'
                                                ]
                                            }
                                        }
                                    }
                                },
                                {
                                    input: {
                                        name: 'acbau_stateincent',
                                        type: 'number',
                                        rounding: 0,
                                        min: 0,
                                        max: 20000,
                                        title: "State Up-front Incentives",
                                        hideifoptions: {
                                            ac_current: {
                                                type: 'notin',
                                                values: [
                                                    'Ducted Central AC',
                                                    'Window AC',
                                                    'Ducted Central Heat Pump',
                                                    'Ductless Mini Split Heat Pump'
                                                ]
                                            },
                                            "ashp": {
                                                type: 'notin',
                                                values: [
                                                    'Yes'
                                                ]
                                            }
                                        }
                                    }
                                },
                                {
                                    input: {
                                        name: 'acbau_localorutilityincent',
                                        type: 'number',
                                        rounding: 0,
                                        min: 0,
                                        max: 20000,
                                        title: "Local or Utility Up-front Incentives",
                                        hideifoptions: {
                                            ac_current: {
                                                type: 'notin',
                                                values: [
                                                    'Ducted Central AC',
                                                    'Window AC',
                                                    'Ducted Central Heat Pump',
                                                    'Ductless Mini Split Heat Pump'
                                                ]
                                            },
                                            "ashp": {
                                                type: 'notin',
                                                values: [
                                                    'Yes'
                                                ]
                                            }
                                        }
                                    }
                                }
                            ]
                        },
                        {
                            type: 'row',
                            inputs: [
                                {
                                    title: '[spaceheat_current] Incentives ($)'
                                },
                                {
                                    input: {
                                        name: 'spaceheatbau_fedincent',
                                        type: 'number',
                                        rounding: 0,
                                        min: 0,
                                        max: 20000,
                                        title: "Federal Up-front Incentives",
                                        hideifoptions: {
                                            "ashp": {
                                                type: 'notin',
                                                values: [
                                                    'Yes'
                                                ]
                                            }
                                        }
                                    }
                                },
                                {
                                    input: {
                                        name: 'spaceheatbau_stateincent',
                                        type: 'number',
                                        rounding: 0,
                                        min: 0,
                                        max: 20000,
                                        title: "State Up-front Incentives",
                                        hideifoptions: {
                                            "ashp": {
                                                type: 'notin',
                                                values: [
                                                    'Yes'
                                                ]
                                            }
                                        }
                                    }
                                },
                                {
                                    input: {
                                        name: 'spaceheatbau_localorutilityincent',
                                        type: 'number',
                                        rounding: 0,
                                        min: 0,
                                        max: 20000,
                                        title: "Local or Utility Up-front Incentives",
                                        hideifoptions: {
                                            "ashp": {
                                                type: 'notin',
                                                values: [
                                                    'Yes'
                                                ]
                                            }
                                        }
                                    }
                                }
                            ]
                        }
                    ]
                }
            ]
        },
        {
            title: "Water Heating",
            image: waterHeating,
            inputs: [
                {
                    title: "Green Upgrade: Electric Heat Pump Water Heater Installed Cost ($)",
                    tippyText: 'This is the installed cost of the electric heat pump water heater selected. The default value is based on estimated material, labor, and installation costs, and state <a href="https://www.rsmeansonline.com/" target="__blank">RS Means values</a>. See the FAQ section for more detail on these cost methodologies.',
                    input: {
                        name: 'hpwhcapco',
                        type: 'number',
                        rounding: 0,
                        min: 0,
                        max: 10000,
                        title: "Green Upgrade: Electric Heat Pump Water Heater Installed Cost ($)",
                        hideifoptions: {
                            "hpwh": {
                                type: 'notin',
                                values: [
                                    'Yes'
                                ]
                            }
                        }
                    }
                },
                {
                    title: "Traditional Replacement: [wh_current] Installed Cost ($)",
                    tippyText: 'This is the installed cost of the traditional replacement water heater selected. The default value is based on estimated material, labor, and installation costs, and state <a href="https://www.rsmeansonline.com/" target="__blank">RS Means values.</a> See the FAQ section for more detail on these cost methodologies.',
                    input: {
                        name: 'whbaucapco',
                        type: 'number',
                        rounding: 0,
                        min: 0,
                        max: 10000,
                        title: "Traditional Alternative: [wh_current] Installed Cost ($)",
                        hideifoptions: {
                            "hpwh": {
                                type: 'notin',
                                values: [
                                    'Yes'
                                ]
                            }
                        }
                    }
                },
                {
                    table: [
                        {
                            type: 'header',
                            inputs: [
                                {
                                    
                                },
                                {
                                    title: 'Federal Up-front Incentives',
                                    tippyText: 'This is the total value in federal incentives or tax credits for installing the corresponding water heater. The default value is based on the technology’s efficiency and income qualifications for the federal <a href="https://www.irs.gov/credits-deductions/energy-efficient-home-improvement-credit">Energy Efficient Home Improvement Tax Credit (25C)</a> and <a href="https://www.rewiringamerica.org/policy/high-efficiency-electric-home-rebate-act">Home Electrification Rebate Program.</a>',
                                },
                                {
                                    title: 'State Up-front Incentives',
                                    tippyText: 'This is the total value in state government incentives or tax credits for installing the corresponding water-heating technology. The default value is based on any known statewide incentive programs.',
                                },
                                {
                                    title: 'Local or Utility Up-front Incentives',
                                    tippyText: 'This is the total value in local government or utility incentives for installing the corresponding water-heating technology. Although the default value is conservatively set to $0 given the lack of a national database of local incentives to reference, users should check with local contractors and other resources to identify additional incentives to enter here.',
                                }
                            ]
                        },
                        {
                            type: 'row',
                            inputs: [
                                {
                                    title: 'Electric Heat Pump Water Heater Incentives ($)'
                                },
                                {
                                    input: {
                                        name: 'hpwhincent_fed',
                                        type: 'number',
                                        rounding: 0,
                                        min: 0,
                                        max: 5000,
                                        title: "Federal Up-front Incentives",
                                        hideifoptions: {
                                            "hpwh": {
                                                type: 'notin',
                                                values: [
                                                    'Yes'
                                                ]
                                            }
                                        }
                                    }
                                },
                                {
                                    input: {
                                        name: 'hpwhincent_state',
                                        type: 'number',
                                        rounding: 0,
                                        min: 0,
                                        max: 5000,
                                        title: "State Up-front Incentives",
                                        hideifoptions: {
                                            "hpwh": {
                                                type: 'notin',
                                                values: [
                                                    'Yes'
                                                ]
                                            }
                                        }
                                    }
                                },
                                {
                                    input: {
                                        name: 'hpwhincent_localorutility',
                                        type: 'number',
                                        rounding: 0,
                                        min: 0,
                                        max: 5000,
                                        title: "Local or Utility Up-front Incentives",
                                        hideifoptions: {
                                            "hpwh": {
                                                type: 'notin',
                                                values: [
                                                    'Yes'
                                                ]
                                            }
                                        }
                                    }
                                }
                            ]
                        },
                        {
                            type: 'row',
                            inputs: [
                                {
                                    title: '[wh_current] Incentives ($)'
                                },
                                {
                                    input: {
                                        name: 'whbau_fedincent',
                                        type: 'number',
                                        rounding: 0,
                                        min: 0,
                                        max: 5000,
                                        title: "Federal Up-front Incentives",
                                        hideifoptions: {
                                            "hpwh": {
                                                type: 'notin',
                                                values: [
                                                    'Yes'
                                                ]
                                            }
                                        }
                                    }
                                },
                                {
                                    input: {
                                        name: 'whbau_stateincent',
                                        type: 'number',
                                        rounding: 0,
                                        min: 0,
                                        max: 5000,
                                        title: "State Up-front Incentives",
                                        hideifoptions: {
                                            "hpwh": {
                                                type: 'notin',
                                                values: [
                                                    'Yes'
                                                ]
                                            }
                                        }
                                    }
                                },
                                {
                                    input: {
                                        name: 'whbau_localorutilityincent',
                                        type: 'number',
                                        rounding: 0,
                                        min: 0,
                                        max: 5000,
                                        title: "Local or Utility Up-front Incentives",
                                        hideifoptions: {
                                            "hpwh": {
                                                type: 'notin',
                                                values: [
                                                    'Yes'
                                                ]
                                            }
                                        }
                                    }
                                }
                            ]
                        }
                    ]
                }
            ]
        },
        {
            title: "Cooking Appliance",
            image: cookingAppliance,
            inputs: [
                {
                    title: "[cooking_cleaner] Installed Cost ($)",
                    tippyText: 'This is the installed cost of the selected cooking green upgrade. The default value is based on costs reported from Home Depot and Lowes and scaled based on state <a href="https://www.rsmeansonline.com/" target="__blank">RS Means values</a>',
                    input: {
                        name: 'rangecapco',
                        type: 'number',
                        min: 0,
                        max: 10000,
                        title: "[cooking_cleaner] Installed Cost ($)",
                        hideifoptions: {
                            "range": {
                                type: 'notin',
                                values: [
                                    'Yes'
                                ]
                            }
                        }
                    }
                },
                {
                    title: "[cooking_current] Installed Cost ($)",
                    tippyText: 'This is the installed cost of the selected cooking traditional replacement. The default value is based on costs reported from Home Depot and Lowe’s and scaled based on state <a href="https://www.rsmeansonline.com/" target="__blank">RS Means values</a>',
                    input: {
                        name: 'rangebaucapco',
                        type: 'number',
                        rounding: 0,
                        min: 0,
                        max: 10000,
                        title: "[cooking_current] Installed Cost ($)",
                        hideifoptions: {
                            "range": {
                                type: 'notin',
                                values: [
                                    'Yes'
                                ]
                            },
                            "cooking_current": {
                                type: 'in',
                                values: [
                                    "I would not upgrade my stove"
                                ]
                            }
                        }
                    }
                },
                {
                    table: [
                        {
                            type: 'header',
                            inputs: [
                                {
                                    
                                },
                                {
                                    title: 'Federal Up-front Incentives',
                                    tippyText: 'This is the total value in federal incentives or tax credits for installing the corresponding cooking appliance. The default value is based on electric cooking appliance incentives and income qualifications for the federal <a href="https://www.rewiringamerica.org/policy/high-efficiency-electric-home-rebate-act">Home Electrification Rebate Program.</a>',
                                },
                                {
                                    title: 'State Up-front Incentives',
                                    tippyText: 'This is the total value in state government incentives or tax credits for installing the corresponding cooking appliance. The default value is based on any known statewide incentive programs.',
                                },
                                {
                                    title: 'Local or Utility Up-front Incentives',
                                    tippyText: 'This is the total value in local government or utility incentives for installing the corresponding cooking appliance. The default value is set to $0 since cooking appliance incentives from these entities are uncommon.',
                                }
                            ]
                        },
                        {
                            type: 'row',
                            inputs: [
                                {
                                    title: '[cooking_cleaner] Incentives ($)'
                                },
                                {
                                    input: {
                                        name: 'rangeincent_fed',
                                        type: 'number',
                                        rounding: 0,
                                        min: 0,
                                        max: 5000,
                                        title: "Federal Up-front Incentives",
                                        hideifoptions: {
                                            "range": {
                                                type: 'notin',
                                                values: [
                                                    'Yes'
                                                ]
                                            }
                                        }
                                    }
                                },
                                {
                                    input: {
                                        name: 'rangeincent_state',
                                        type: 'number',
                                        rounding: 0,
                                        min: 0,
                                        max: 5000,
                                        title: "State Up-front Incentives",
                                        hideifoptions: {
                                            "range": {
                                                type: 'notin',
                                                values: [
                                                    'Yes'
                                                ]
                                            }
                                        }
                                    }
                                },
                                {
                                    input: {
                                        name: 'rangeincent_localorutility',
                                        type: 'number',
                                        rounding: 0,
                                        min: 0,
                                        max: 5000,
                                        title: "Local or Utility Up-front Incentives",
                                        hideifoptions: {
                                            "range": {
                                                type: 'notin',
                                                values: [
                                                    'Yes'
                                                ]
                                            }
                                        }
                                    }
                                }
                            ]
                        },
                        {
                            type: 'row',
                            inputs: [
                                {
                                    title: '[cooking_current] Incentives ($)'
                                },
                                {
                                    input: {
                                        name: 'rangebau_fedincent',
                                        type: 'number',
                                        rounding: 0,
                                        min: 0,
                                        max: 5000,
                                        title: "Federal Up-front Incentives",
                                        hideifoptions: {
                                            "range": {
                                                type: 'notin',
                                                values: [
                                                    'Yes'
                                                ]
                                            },
                                            "cooking_current": {
                                                type: 'in',
                                                values: [
                                                    "I would not upgrade my stove"
                                                ]
                                            }
                                        }
                                    }
                                },
                                {
                                    input: {
                                        name: 'rangebau_stateincent',
                                        type: 'number',
                                        rounding: 0,
                                        min: 0,
                                        max: 5000,
                                        title: "State Up-front Incentives",
                                        hideifoptions: {
                                            "range": {
                                                type: 'notin',
                                                values: [
                                                    'Yes'
                                                ]
                                            },
                                            "cooking_current": {
                                                type: 'in',
                                                values: [
                                                    "I would not upgrade my stove"
                                                ]
                                            }
                                        }
                                    }
                                },
                                {
                                    input: {
                                        name: 'rangebau_localorutilityincent',
                                        type: 'number',
                                        rounding: 0,
                                        min: 0,
                                        max: 5000,
                                        title: "Local or Utility Up-front Incentives",
                                        hideifoptions: {
                                            "range": {
                                                type: 'notin',
                                                values: [
                                                    'Yes'
                                                ]
                                            },
                                            "cooking_current": {
                                                type: 'in',
                                                values: [
                                                    "I would not upgrade my stove"
                                                ]
                                            }
                                        }
                                    }
                                }
                            ]
                        }
                    ]
                }
            ]
        },
        {
            title: "Other Home Upgrades",
            image: otherHomeUpgrades,
            inputs: [
                {
                    title: "Electrical Panel Upgrade Installed Cost ($)",
                    tippyText: 'This is the installed cost to upgrade the electrical panel. The default value is estimated based on applying state RS Means labor rates to an estimated $3,000 national average cost to upgrade to a 200 amp panel in the United States in 2023 based on industry interviews.',
                    input: {
                        name: 'panelcapco',
                        type: 'number',
                        rounding: 0,
                        min: 0,
                        max: 10000,
                        title: "Electrical Panel Upgrade Installed Cost ($)",
                        hideifoptions: {
                            ...atLeastOneHomeUpgrade,
                            'additional_home_upgrade': {
                                type: 'notin',
                                values: [
                                    'Yes'
                                ]
                            }
                        }
                    }
                },
                {
                    title: "Roof Upgrade Installed Cost ($)",
                    tippyText: 'This is the installed cost for reroofing the home. The default value is estimated based on applying state RS Means labor rates to an estimated $9,300 national average reroofing cost in the United States in 2023, sourced from <a href="https://www.homeadvisor.com/cost/roofing/install-a-roof/" target="__blank">HomeAdvisor.com.</a>',
                    input: {
                        name: 'roofcapco',
                        type: 'number',
                        rounding: 0,
                        min: 0,
                        max: 30000,
                        title: "Roof Upgrade Installed Cost ($)",
                        hideifoptions: {
                            'additional_home_upgrade_new': {
                                type: 'notin',
                                values: [
                                    'Roof Replacement'
                                ]
                            }
                        }
                    }
                },
                {
                    title: "Health and Safety Update Installed Cost ($)",
                    tippyText: 'This is the installed cost of addressing health and safety issues (e.g., asbestos removal). The default value is estimated based on applying state RS Means labor rates to an estimated $2,200 national average cost for asbestos removal in the United States in 2023, sourced from <a href="https://www.homeadvisor.com/cost/environmental-safety/remove-asbestos/" target="__blank">HomeAdvisor.com.</a>',
                    input: {
                        name: 'hscapco',
                        type: 'number',
                        rounding: 0,
                        min: 0,
                        max: 20000,
                        title: "Health and Safety Update Installed Cost ($)",
                        hideifoptions: {
                            'additional_home_upgrade_new': {
                                type: 'notin',
                                values: [
                                    'Health and Safety Upgrade'
                                ]
                            }
                        }
                    }
                },
                {
                    table: [
                        {
                            type: 'header',
                            inputs: [
                                {
                                    
                                },
                                {
                                    title: 'Federal Up-front Incentives',
                                    tippyText: 'This is the total value in federal incentives or tax credits for the corresponding upgrade, based on income qualifications where applicable. If an electrical panel upgrade is selected, the default value  is based on the federal <a href="https://www.irs.gov/credits-deductions/energy-efficient-home-improvement-credit" target="__blank">Energy Efficient Home Improvement Tax Credit (25C)</a> and <a href="https://www.rewiringamerica.org/policy/high-efficiency-electric-home-rebate-act" target="__blank">Home Electrification Rebate Program,</a> and corresponding income qualifications.',
                                },
                                {
                                    title: 'State Up-front Incentives',
                                    tippyText: 'This is the total value in state government incentives or tax credits for the corresponding upgrade. The default value is based on any known statewide incentive programs.',
                                },
                                {
                                    title: 'Local or Utility Up-front Incentives',
                                    tippyText: 'This is the total value in local government or utility incentives for the corresponding upgrade. The default value is set to $0.',
                                }
                            ]
                        },
                        {
                            type: 'row',
                            inputs: [
                                {
                                    title: '[Desired Home Upgrades] Incentives ($)'
                                },
                                {
                                    input: {
                                        name: 'panelincent_fed',
                                        type: 'number',
                                        rounding: 0,
                                        min: 0,
                                        max: 5000,
                                        title: "Federal Up-front Incentives",
                                        hideifoptions: {
                                            ...atLeastOneHomeUpgrade,
                                            'additional_home_upgrade': {
                                                type: 'notin',
                                                values: [
                                                    'Yes'
                                                ]
                                            }
                                        }
                                    }
                                },
                                {
                                    input: {
                                        name: 'panelincent_state',
                                        type: 'number',
                                        rounding: 0,
                                        min: 0,
                                        max: 5000,
                                        title: "State Up-front Incentives",
                                        hideifoptions: {
                                            ...atLeastOneHomeUpgrade,
                                            'additional_home_upgrade': {
                                                type: 'notin',
                                                values: [
                                                    'Yes'
                                                ]
                                            }
                                        }
                                    }
                                },
                                {
                                    input: {
                                        name: 'panelincent_localorutility',
                                        type: 'number',
                                        rounding: 0,
                                        min: 0,
                                        max: 5000,
                                        title: "Local or Utility Up-front Incentives",
                                        hideifoptions: {
                                            ...atLeastOneHomeUpgrade,
                                            'additional_home_upgrade': {
                                                type: 'notin',
                                                values: [
                                                    'Yes'
                                                ]
                                            }
                                        }
                                    }
                                }
                            ]
                        }
                    ]
                }
            ]
        },
        {
            title: "Transportation",
            image: vehicle,
            inputs: [
                {
                    title: "Price Category for a New Vehicle",
                    tippyText: 'This variable is used to ensure the analysis only considers vehicles in budget if an average electric vehicle type is selected. Economy vehicles are priced at or below $40,000, premium vehicles are priced between $40,000 and $75,000, and luxury vehicles are priced at or above $75,000.',
                    input: {
                        name: 'vehicleprice',
                        type: 'select',
                        values: [
                            {
                                title: 'Economy',
                                value: 'Economy'
                            },
                            {
                                title: 'Premium',
                                value: 'Premium'
                            },
                            {
                                title: 'Luxury',
                                value: 'Luxury'
                            }
                        ],
                        title: "Electrical Panel Upgrade Installed Cost ($)",
                        hideifoptions: {
                            "buy-electric-car": {
                                type: 'notin',
                                values: [
                                    'Yes'
                                ]
                            },

                            'phevmake': {
                                type: 'notin',
                                values: [
                                    'Average PHEV Hatchback',
                                    'Average BEV Hatchback',
                                    'Average PHEV Sedan/Coupe',
                                    'Average BEV Pick-up Truck',
                                    'Average PHEV SUV',
                                    'Average BEV Sedan/Coupe',
                                    'Average BEV SUV',
                                ]
                            }
                        }
                    }
                },
                {
                    title: "Price Category for an Electric Bike",
                    tippyText: 'This variable is used to ensure the analysis only considers electric bikes in budget. Economy electric bikes are priced at or below $1,500, premium electric bikes are priced at $1,500–$4,500, and luxury electric bikes are priced at or above $4,500.',
                    input: {
                        name: 'ebikeprice',
                        type: 'select',
                        values: [
                            {
                                title: 'Economy',
                                value: 'Economy'
                            },
                            {
                                title: 'Premium',
                                value: 'Premium'
                            },
                            {
                                title: 'Luxury',
                                value: 'Luxury'
                            }
                        ],
                        title: "Price Category for an Electric Bike",
                        hideifoptions: {
                            "ebike": {
                                type: 'notin',
                                values: [
                                    'Yes'
                                ]
                            }
                        }
                    }
                },
                {
                    title: "[phevmake] [phevmodel]: Capital Cost ($)",
                    tippyText: 'This is the capital cost of the electric vehicle selected. The default value is the vehicle manufacturer\’s suggested retail price sourced from <a href="https://carsheet.io/" target="__blank">carsheet.io.</a>',
                    input: {
                        name: 'phevcapco',
                        type: 'number',
                        rounding: 0,
                        min: 1500,
                        max: 150000,
                        title: "[phevmake] [phevmodel]: Capital Cost ($)",
                        hideifoptions: {
                            "buy-electric-car": {
                                type: 'notin',
                                values: [
                                    'Yes'
                                ]
                            },
                            'vehicle_type': {
                                type: 'notin',
                                values: [
                                    'PHEV'
                                ]
                            }
                        }
                    }
                },
                {
                    title: "[phevmake] [phevmodel]: Capital Cost ($)",
                    tippyText: 'This is the capital cost of the electric vehicle selected. The default value is the vehicle manufacturer\’s suggested retail price sourced from <a href="https://carsheet.io/" target="__blank">carsheet.io.</a>',
                    input: {
                        name: 'bevcapco',
                        type: 'number',
                        rounding: 0,
                        min: 1500,
                        max: 150000,
                        title: "[phevmake] [phevmodel]: Capital Cost ($)",
                        hideifoptions: {
                            "buy-electric-car": {
                                type: 'notin',
                                values: [
                                    'Yes'
                                ]
                            },
                            'vehicle_type': {
                                type: 'notin',
                                values: [
                                    'BEV'
                                ]
                            }
                        }
                    }
                },
                {
                    title: "Electric Bike: Capital Cost ($)",
                    tippyText: 'This is the capital cost of the electric bike selected. The default value is the electric bike manufacturer’s suggested retail price sourced from <a href="https://electricbikereview.com/" target="__blank">ElectricBikeReview.com.</a>',
                    input: {
                        name: 'ebikecapco',
                        type: 'number',
                        rounding: 0,
                        min: 150,
                        max: 15000,
                        title: "Electric Bike: Capital Cost ($)",
                        hideifoptions: {
                            "ebike": {
                                type: 'notin',
                                values: [
                                    'Yes'
                                ]
                            }
                        }
                    }
                },
                {
                    title: "Level 2 Electric Car Charger Installed Cost ($)",
                    tippyText: 'This is the total installed cost, including material, labor, and contractor overhead and profit, of the electric vehicle charger. The default value is estimated based on applying state RS Means labor rates to an estimated $1,100 national average Level 2 charger installation cost in the United States in 2023, sourced from <a href="https://www.homeadvisor.com/cost/garages/install-an-electric-vehicle-charging-station/" target="__blank">HomeAdvisor.com.</a>',
                    input: {
                        name: 'chargercapco',
                        type: 'number',
                        rounding: 0,
                        min: 0,
                        max: 10000,
                        title: "Level 2 Electric Car Charger Installed Cost ($)",
                        hideifoptions: {
                            "chargeraccess": {
                                type: 'notin',
                                values: [
                                    'Installing a New Level-2 Charger'
                                ]
                            }
                        }
                    }
                },
                {
                    title: "[ffvmake] [ffvmodel]: Capital Cost ($)",
                    tippyText: 'This is the capital cost of the gasoline vehicle selected. The default value is the manufacturer’s suggested retail price sourced from <a href="https://carsheet.io/" target="__blank">carsheet.io.</a>',
                    input: {
                        name: 'ffvcapco',
                        type: 'number',
                        rounding: 0,
                        min: 1500,
                        max: 150000,
                        title: "[ffvmake] [ffvmodel]: Capital Cost ($)",
                        hideifoptions: atLeastOneTransportUpgrade
                    }
                },
                {
                    title: "Expected Resale Value (% of Capital Cost)",
                    tippyText: 'This is the expected resale value of the traditional gasoline vehicle and electric vehicle, expressed as a percentage of its up-front cost. The default value is based on depreciation estimates from various sources.',
                    input: {
                        name: 'vehiclecapco_incr',
                        type: 'number',
                        rounding: 2,
                        min: 0,
                        max: 95,
                        step: 0.01,
                        multiplier: 100,
                        title: "Expected Resale Value (% of Capital Cost)",
                        hideifoptions: atLeastOneTransportUpgrade
                    }
                },
                {
                    title: "State Sales Tax (%)",
                    tippyText: 'This is the sales tax in the state, applied to the capital of a new vehicle or electric bike. The default value is sourced from <a href="https://www.salestaxinstitute.com/resources/rates" target="__blank">SalesTaxInstitute.com.</a>',
                    input: {
                        name: 'salestaxrate',
                        type: 'number',
                        rounding: 2,
                        min: 0,
                        max: 25,
                        step: 0.01,
                        multiplier: 100,
                        title: "State Sales Tax (%)",
                        hideifoptions: atLeastOneTransportUpgrade
                    }
                },
                {
                    table: [
                        {
                            type: 'header',
                            inputs: [
                                {
                                    
                                },
                                {
                                    title: 'Federal Up-front Incentives',
                                    tippyText: 'This is the electric vehicle federal incentive value, applied as a point-of-sale rebate beginning in 2024, for the vehicle selected. The default value is sourced from the <a href="https://fueleconomy.gov/feg/tax2023.shtml" target="__blank">US Department of Energy</a>. There is currently no electric bike federal incentive available. ',
                                },
                                {
                                    title: 'State Up-front Incentives',
                                    tippyText: 'This is the total value of state government incentives, which may take the form of tax credits, rebates, fee exemptions, and more, for the electric vehicle and/or electric bike selected in the state. The default electric vehicle value is sourced from the <a href="https://afdc.energy.gov/laws/search" target="__blank">US Department of Energy.</a> The default electric bike value is sourced from <a href="https://docs.google.com/spreadsheets/d/1C-sYcwLrQFsr8r2A6RiAP2RwGsBNwr1BKOF_HJvCsVU/edit#gid=0" target="__blank">Portland State University’s Transportation Research and Education Center.</a>',
                                },
                                {
                                    title: 'Local or Utility Up-front Incentives',
                                    tippyText: 'This is the total value of incentives from the local government or electric utility for the electric vehicle and/or electric bike selected. While the default value is conservatively set to $0 given the lack of a national database of local electric vehicle incentives to reference, users should check with local utility and other resources to identify additional incentives to enter here. ',
                                }
                            ]
                        },
                        {
                            type: 'row',
                            inputs: [
                                {
                                    title: '[phevmake] [phevmodel] Incentives ($)'
                                },
                                {
                                    input: {
                                        name: 'evincent_fed',
                                        type: 'number',
                                        rounding: 0,
                                        min: 0,
                                        max: 25000,
                                        title: "Federal Up-front Incentives",
                                        hideifoptions: {
                                            "buy-electric-car": {
                                                type: 'notin',
                                                values: [
                                                    'Yes'
                                                ]
                                            }
                                        }
                                    }
                                },
                                {
                                    input: {
                                        name: 'evincent_state',
                                        type: 'number',
                                        rounding: 0,
                                        min: 0,
                                        max: 25000,
                                        title: "State or Local Government Up-front Incentives",
                                        hideifoptions: {
                                            "buy-electric-car": {
                                                type: 'notin',
                                                values: [
                                                    'Yes'
                                                ]
                                            }
                                        }
                                    }
                                },
                                {
                                    input: {
                                        name: 'evincent_localorutility',
                                        type: 'number',
                                        rounding: 0,
                                        min: 0,
                                        max: 25000,
                                        title: "Utility Up-front Incentives",
                                        hideifoptions: {
                                            "buy-electric-car": {
                                                type: 'notin',
                                                values: [
                                                    'Yes'
                                                ]
                                            }
                                        }
                                    }
                                }
                            ]
                        },
                        {
                            type: 'row',
                            inputs: [
                                {
                                    title: 'Electric Bike Incentives ($)'
                                },
                                {
                                },
                                {
                                    input: {
                                        name: 'ebikeincent_state',
                                        type: 'number',
                                        rounding: 0,
                                        min: 0,
                                        max: 10000,
                                        title: "State/Local Government Up-front Incentives",
                                        hideifoptions: {
                                            "ebike": {
                                                type: 'notin',
                                                values: [
                                                    'Yes'
                                                ]
                                            }
                                        }
                                    }
                                },
                                {
                                    input: {
                                        name: 'ebikeincent_localorutility',
                                        type: 'number',
                                        min: 0,
                                        max: 10000,
                                        title: 'Local or Utility Up-front Incentives',
                                        hideifoptions: {
                                            "ebike": {
                                                type: 'notin',
                                                values: [
                                                    'Yes'
                                                ]
                                            }
                                        }
                                    }
                                }
                            ]
                        }
                    ]
                }
            ]
        },
        {
            title: "Non-Technology-Based Incentives",
            image: otherIncentiveInfo,
            inputs: [
                {
                    title: "Utility/Other: Ongoing ($/year)",
                    tippyText: 'These are any additional incentives relevant to the technologies selected that do not fit within the other incentive inputs (e.g., demand response revenue). The default value assumes $0 in additional ongoing incentives.',
                    input: {
                        name: 'otherincent',
                        type: 'number',
                        rounding: 0,
                        min: 0,
                        max: 500,
                        title: "Utility/Other: Ongoing ($/year)",
                        hideifoptions: {
                            'mode': {
                                type: 'in',
                                values: [
                                    'Transportation (TEMPEST-T)'
                                ]
                            }
                        }
                    }
                },
                {
                    title: "Utility/Other: Ongoing (years)",
                    tippyText: 'This is the number of years the user receives the additional ongoing incentives.',
                    input: {
                        name: 'otherincent_yrs',
                        type: 'number',
                        rounding: 0,
                        min: 0,
                        max: 25,
                        title: "Utility/Other: Ongoing (years)",
                        hideifoptions: {
                            'mode': {
                                type: 'in',
                                values: [
                                    'Transportation (TEMPEST-T)'
                                ]
                            }
                        }
                    }
                }
            ]
        }
    ]
}

export default UpFrontCostsAndInCentives;