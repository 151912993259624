import zipcode from './../../images/basic-information-zip-code.png';
import homeType from './../../images/basic-information-home-type.png';
import homeYr from './../../images/basic-information-built-year.png';
import homeArea from './../../images/basic-information-home-size.png';
import carModel from './../../images/basic-information-vehicle-type.png';

const BasicInformation = {
    pageName: 'Get Started',
    inputs: [
        {
            title: 'What is the zip code?',
            image: zipcode,
            input: {
                name: 'zipcode',
                type: 'number',
                title: "Five digit zip code",
                min: 0,
                max: 99929
            },
        },
        {
            title: 'What is the type of home?',
            tippyText: 'This calculator analyzes home types where the resident owns the appliance or related systems they would like to upgrade, such as rooftop solar, air conditioning, and water heaters. There are no options for larger multi-family buildings, such as condos and apartments, because of their centralized systems shared across multiple units and different system ownership.',
            image: homeType,
            input: {
                name: 'hometype',
                type: 'select',
                values: [
                    {
                        title: 'Single-Family Detached Home',
                        value: 'Single-Family Detached Home'
                    },
                    {
                        title: 'Townhome',
                        value: 'Townhome'
                    },
                    {
                        title: 'Mobile Home',
                        value: 'Mobile Home'
                    }
                ],
                hideifoptions: {
                    'mode': {
                        type: 'in',
                        values: [
                            'Transportation (TEMPEST-T)'
                        ]
                    }
                }
            }
        },
        {
            title: 'When was the home built?',
            image: homeYr,
            input: {
                name: 'homeyr',
                type: 'select',
                values: [
                    {
                        title: 'pre-1980',
                        value: 'pre-1980'
                    },
                    {
                        title: '1980-2000',
                        value: '1980-2000'
                    },
                    {
                        title: '2000-present',
                        value: '2000-present'
                    }
                ],
                hideifoptions: {
                    'mode': {
                        type: 'in',
                        values: [
                            'Transportation (TEMPEST-T)'
                        ]
                    }
                }
            }
        },
        {
            title: 'What is the size of the home?',
            image: homeArea,
            input: {
                name: 'homearea',
                type: 'select',
                values: [
                    {
                        title: '0sqft - 500sqft',
                        value: 250
                    },
                    {
                        title: '500sqft - 1,000sqft',
                        value: 750
                    },
                    {
                        title: '1,000sqft - 1,500sqft',
                        value: 1250
                    },
                    {
                        title: '1,500sqft - 2,000sqft',
                        value: 1750
                    },
                    {
                        title: '2,000sqft - 2,500sqft',
                        value: 2250
                    },
                    {
                        title: '2,500sqft - 3,000sqft',
                        value: 2750
                    },
                    {
                        title: '3,000sqft - 3,500sqft',
                        value: 3250
                    },
                    {
                        title: '3,500sqft - 4,000sqft',
                        value: 3750
                    },
                    {
                        title: 'Greater than 4,000sqft',
                        value: 4250
                    },
                ],
                hideifoptions: {
                    'mode': {
                        type: 'in',
                        values: [
                            'Transportation (TEMPEST-T)'
                        ]
                    }
                }
            }
        },
        {
            title: 'What is the primary type of vehicle currently driven?', 
            image: carModel,
            input: {
                name: 'carmodel',
                type: 'select',
                values: [
                    {
                        title: 'Hatchback',
                        value: 'Hatchback'
                    },
                    {
                        title: 'Sedan/Coupe',
                        value: 'Sedan/Coupe'
                    },
                    {
                        title: 'SUV',
                        value: 'SUV'
                    },
                    {
                        title: 'Pickup Truck',
                        value: 'Pickup Truck'
                    }
                ],
                hideifoptions: {
                    'mode': {
                        type: 'in',
                        values: [
                            'Home (TEMPEST-H)'
                        ]
                    }
                }
            }
        }
    ]
};

export default BasicInformation;