// gatsby-browser.js
import React from 'react';
import FormDataProvider from './src/context/FormDataProvider';
import * as Sentry from "@sentry/gatsby";

let originWindow;

if(typeof window !== 'undefined') {
  originWindow = window.location.origin;
}

Sentry.init({
  dsn: process.env.GATSBY_SENTRY_DNS,
  environment: process.env.GATSBY_SENTRY_ENV,
  integrations: [
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
      maskAllInputs: false,
      networkDetailAllowUrls: [
        originWindow,
        "https://staging.rmi-tempest.flauntdigital.com",
        "https://rmi-tempest.flauntdigital.com"
      ],
      networkRequestHeaders: ["Cache-Control"],
      networkResponseHeaders: ["Referrer-Policy"],
    }),
  ],
  replaysSessionSampleRate: 0,
  replaysOnErrorSampleRate: 1.0
});

export const wrapRootElement = ({ element }) => {
  return <FormDataProvider>{element}</FormDataProvider>;
};
