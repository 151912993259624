import React, { useContext, useState, Suspense } from 'react';

import FormDataContext from '../../context/FormDataContext';
import ResultsContext from '../../context/ResultsContext';
import ResultsOne from './../../context/samples/results-one';
import ResultsTwo from './../../context/samples/results-two';
import ResultsThree from './../../context/samples/results-three';
import ResultsFour from './../../context/samples/results-four';
import ResultsFive from './../../context/samples/results-five';
import ResultsSix from './../../context/samples/results-six';

const ReactJson = React.lazy(() => import('react-json-view'));

const DebugView = () => {
    const { formData, addOrUpdateFormData, overwriteFormData } = useContext(FormDataContext);
    const { results, getResults } = useContext(ResultsContext);

    const [view, setView] = useState('inputs');

    if (typeof window === "undefined")
        return null;

    return (
        <Suspense fallback={<p>Loading...</p>} >
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                height: '100vh'
            }}>
                <div style={{
                    background: '#333',
                    display: 'flex',
                    flexWrap: 'wrap',
                    gap: '1em',
                    left: 0,
                    padding: '1em',
                    position: 'sticky',
                    top: 0
                }}>
                    <button onClick={e => setView('inputs')}>View Inputs</button>
                    <button onClick={e => {
                        getResults(formData);
                        setView('results');
                    }}>View Results</button>
                    <button onClick={e => {
                        getResults(ResultsOne());
                        setView('results');
                    }}>View Results</button>
                    <button onClick={e => {
                        overwriteFormData(ResultsTwo());
                    }}>Overwrite Form Data One</button>
                    <button onClick={e => {
                        overwriteFormData(ResultsThree());
                    }}>Overwrite Form Data Two</button>
                    <button onClick={e => {
                        overwriteFormData(ResultsFour());
                    }}>Overwrite Form Data Three</button>
                    <button onClick={e => {
                        overwriteFormData(ResultsFive());
                    }}>Overwrite Form Data Four</button>
                    <button onClick={e => {
                        overwriteFormData(ResultsSix());
                    }}>Overwrite Form Data Five</button>
                </div>
                <div style={{
                    flex: 1,
                    overflow: 'auto'
                }}>
                    <ReactJson src={(view === 'inputs') ? formData : results} theme="railscasts" />
                </div>
            </div>
        </Suspense>
    );
}

export default DebugView;