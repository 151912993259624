const economicImpact = {
    pageName: 'Cost Impact',
    inputs: [
        {
            input: {
                name: 'timeframe_h',
                type: 'number',
                min: 1,
                timelinegetresults: 1,
                max: 25
            }
        },
        {
            input: {
                name: 'timeframe_t',
                type: 'number',
                min: 1,
                timelinegetresults: 1,
                maxfnc: (formData) => {
                    return formData?.vehiclelease_yrs ?? 25
                },
            }
        },
    ]
};

export default economicImpact;