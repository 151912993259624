import * as React from "react";
import { useState } from 'react';

import * as styles from './modal.module.scss';

const demoVideoData = [
  {
    id: 'OXf74LyTMPw',
    title: '1. Green Upgrade Calculator Orientation'
  },
  {
    id: 'SMce7wJ8l9o',
    title: '2. How to Enter User Info & Green Upgrades'
  },
  {
    id: 'xShjheiu3tY',
    title: '3. How to Enter Optional Technical Details'
  },
  {
    id: 'tQR9_mIX9w8',
    title: '4. How to Enter Optional Energy Rates, Usage, and Maintenance'
  },
  {
    id: 'TgLEFVBk1es',
    title: '5. How to Enter Optional Upfront Costs and Incentives'
  },
  {
    id: 'xK2GVJF1iPU',
    title: '6. How to Include Financing'
  },
  {
    id: 'ZSGpa9vvqR0',
    title: '7. Understanding the Cost and Climate Results'
  }
];

const DemoModal = ({ modalDemoOpen, setDemoModalOpen }) => {
  return (
    <div className={styles.modal + (modalDemoOpen ? ` ` + styles.hidden : '')}>
      <div className={`${styles.modalMain} ${styles.modalMainDemo}`}>
        <section>
          <div className={styles.modalHeader}>
            <h2 className={styles.contactHeader}>Demonstration Videos</h2>
            <button className={styles.modalClose} onClick={e => setDemoModalOpen(!modalDemoOpen)}>Close</button>
          </div>
          <div className={`${styles.modalContent} ${styles.modalContentDemo}`}>
            {demoVideoData.map((video, index) => (
                <div key={`demo-video-${index}`} className={styles.videoContainer}>
                  <h3>{video.title}</h3>
                  <iframe
                    loading="lazy"
                    aria-label={`Embedded Video: ${video.title}`}
                    src={`https://www.youtube.com/embed/${video.id}`}
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  ></iframe>
                </div>
              ))}
          </div>
        </section>
      </div>
    </div>
  );
};

export default DemoModal