import React, { useEffect, useState, useRef } from 'react';

import _ from 'lodash';

import { InputListIcon } from "../icon";
import InputList from '../inputs/list';

import * as styles from '../icon/icon.module.scss';
import { dropDown } from './index.module.scss';

const DropDown = ({
    title,
    image,
    children,
    onClick,
    classes,
    open = false
}) => {
    const [openState, setOpenState] = useState(open);
    const contentEl = useRef(null);
    const dropdownRef = useRef(null);

    useEffect(() => {
        if (open && !openState)
            setOpenState(true);

    }, [open])

    const updateVisibility = () => {
        if (!contentEl?.current)
            return;

        window.requestAnimationFrame(() => {  // Give js time to run everything else before
            let visible = false;

            contentEl.current?.childNodes.forEach(el => {
                if (getComputedStyle(el).display !== 'none')
                    visible = true;
            });

            if(visible) {
                dropdownRef.current.style.display = '';
            } else {
                dropdownRef.current.style.display = 'none';
            }

            // Create the event
            let event = new CustomEvent("dropdowns-hidden");
    
            // Dispatch/Trigger/Fire the event
            document.dispatchEvent(event);
        });
    };

    const debouncedUpdate = _.debounce(updateVisibility, 300);  // debounce the function, 1000ms = 1 second

    useEffect(() => {
        debouncedUpdate();  // call the debounced function immediately
        document.addEventListener('input-hidden', debouncedUpdate);

        // Clean up function
        return () => {
            document.removeEventListener('input-hidden', debouncedUpdate);
        };
    }, []);  // Only run at mount and unmount.

    const handleClick = id => { 
        let root = id.target.parentNode.parentNode.parentNode;

        root.querySelectorAll('.dropdown').forEach((el) => {
            if(el.querySelector('header').classList.contains('open'))
                el.querySelector('header').click();
        });

        if (onClick)
            onClick()
    }

    return (
        <section ref={dropdownRef} className={`dropdown ${classes} ${openState ? "open" : "closed"}`}>
            <div>
                { image ?
                    <img
                    className={styles.icon}
                    src={image}
                    alt={title} /> 
                : 
                    null
                }
                <header className={(openState ? 'open' : '') + ` ${dropDown}`} onClick={e => {handleClick(e); setOpenState(!openState)}}>{title}</header>
            </div>
            <article ref={contentEl} style={{
                display: (openState) ? '' : 'none'
            }}>
                {children}
            </article>
        </section>
    )
}

export const OptionalDropdown = ({
    section,
    open = false
}) => {  

    const [openState, setOpenState] = useState(open);

    useEffect(() => {
        if (open && !openState)
            setOpenState(true);

    }, [open])

    let elId = `option-${section.title.split(' ').join('-')}`;
  
    return (
      <div id={elId} className={`parent-div ` + (openState ? 'open' : '')}> 
        <DynamicStyles elId={elId} title={section.title} image={section.image}  openState={ openState } setOpenState={ setOpenState } />
        {section.inputs && <InputList inputs={section?.inputs} />}
        {section?.dropdowns?.map(
            (dropdown, index) => (
              <React.Fragment key={`optional-dropdown-${dropdown.title}-${index}`}>
                <DropDown {...dropdown}>
                  <InputList inputs={dropdown?.inputs} />
                </DropDown>
              </React.Fragment>
            )
        )}
      </div>
    );
}

const DynamicStyles = ({ elId, title, image, openState, setOpenState }) => {

    useEffect(() => {
        window.requestAnimationFrame(() => {
            // Create the event
            var event = new CustomEvent("input-hidden");
    
            // Dispatch/Trigger/Fire the event
            document.dispatchEvent(event);
        })
    }, [])

    return(
        <React.Fragment>
            <InputListIcon title={title} image={image} onClick={() => setOpenState(!openState)} />
            <style dangerouslySetInnerHTML={{__html: `
            #${elId} .input-wrap, #${elId} .dropdown { height: ${openState ? '' : '0px !important'}; overflow: ${openState ? '' : 'hidden'}; padding: ${openState ? '' : '0px !important'}; border: ${openState ? '' : '0px !important;'}; margin: ${openState ? '' : '0px !important;'} }
            `}} />
        </React.Fragment>
    );
};

export default DropDown;