const sideMenu = [
    {
        title: "Required Steps",
        urls: [
            {
                url: '/basic-information',
                text: 'Basic Information',
                disabled: 'mode'
            },
            {
                url: '/green-upgrades',
                text: 'Green Upgrades',
                disabled: "basic-information"
            },
            {
                url: '/traditional-systems',
                text: 'Traditional Systems',
                disabled: "green-upgrades"
            }
        ]
    },
    {
        title: "Optional Steps",
        urls: [
            {
                url: '/technical-details',
                text: 'Technical Details',
                disabled: "traditional-systems"
            },
            {
                url: '/operation-and-maintenance',
                text: 'Operation & Maintenance',
                disabled: "traditional-systems"
            },
            {
                url: '/upfront-costs-and-incentives',
                text: 'Upfront Costs & Incentives',
                disabled: "traditional-systems"
            },
            {
                url: '/financing',
                text: 'Financing',
                disabled: "traditional-systems"
            }
        ]
    },
    {
        title: "Results",
        urls: [
            {
                url: '/cost-impact',
                text: 'Cost Impact',
                disabled: "traditional-systems"
            },
            {
                url: '/climate-impact',
                text: 'Climate Impact',
                disabled: "traditional-systems"
            },
            // {
            //     url: '/take-action',
            //     text: 'Take Action',
            //     disabled: "traditional-systems"
            // }
        ]
    },
];

export default sideMenu;