import React, { useRef, useEffect } from 'react';
import _ from 'lodash'; // import lodash

import Input from './input';
import HideIf from '../../context/functions/HideIf';

const InputList = ({
    inputs,
    defaults = true
}) => {

    const styleRules = useRef(''); 
    const styleRef = useRef(null);

    const updateVisibility = () => {
        let newStyleRules = "";
        window.requestAnimationFrame(() => {
            let labelWraps = Array.from(document.querySelectorAll('.label-wrap'));
            labelWraps.forEach((labelWrap, index) => {
                let nextLabelWrap = labelWraps[index + 1];
                let siblings = [];
                let sibling = labelWrap.nextElementSibling;
                while (sibling && sibling !== nextLabelWrap) {
                    // Skip over 'style' elements
                    if (sibling.tagName === 'STYLE') {
                        sibling = sibling.nextElementSibling;
                        continue;
                    }
                    // Add check for 'table-wrap' class
                    if (!sibling.classList.contains('input-div') && !sibling.classList.contains('table-wrap')) {
                        sibling = sibling.nextElementSibling;
                        continue;
                    }
                    siblings.push(sibling);
                    sibling = sibling.nextElementSibling;
                }
                // Filter `siblings` to keep only those not 'display: none'
                let visibleDivs = siblings.filter(sib => 
                    window.getComputedStyle(sib).display !== 'none'
                );
                let thisId = labelWrap.getAttribute('id');
                // Set the display of `labelWrap` based on the count of visible divs
                if (visibleDivs.length >= 1) {
                    // labelWrap.style.display = 'block';
                } else {
                    newStyleRules += `.${thisId} { display: none !important; }`
                    // labelWrap.style.display = 'none';
                }
            });
    
            // if (newStyleRules != styleRules.current)
                styleRules.current = newStyleRules

                if (styleRef?.current)
                    styleRef.current.innerHTML = newStyleRules;

        })
    };
    

    const debouncedUpdate = _.debounce(updateVisibility, 200); // debounce updateVisibility function, 1000ms = 1 second

    useEffect(() => {
        debouncedUpdate(); // Use the debounced function
        document.addEventListener('input-hidden', debouncedUpdate);
        return () => {
            document.removeEventListener('input-hidden', debouncedUpdate);
        }
    }, []) // Empty array as the second parameter to run only at mount and unmount.

    return inputs && inputs.map((input, index) => (
        <React.Fragment key={`input-item-${input?.input?.name ? input?.input?.name + index : index}`}>
            <InputItem input={input}  defaults={defaults} />
            <style className='input-list-styles' ref={styleRef} dangerouslySetInnerHTML={{__html: styleRules.current}} />
        </React.Fragment>
    ))
}

const InputItem = ({input, defaults = true}) => {
    const visible = useRef(true);
    const inputsInListRef = useRef({});
    const inputEl = useRef(null);

    const setVisible = (v) => {
        visible.current = v
    }

    const updateInputsInList = (key, value) => {
        let inputsVisible = 0;

        inputsInListRef.current[key] = value; // Update the ref value

        for (let inputKey in inputsInListRef.current) {
            if (inputsInListRef.current[inputKey] === true) {
                inputsVisible++;
            }
        }

        const show = inputsVisible > 0;

        if (show !== visible) {
            visible.current = show;
            if (typeof window !== 'undefined')
                window.requestAnimationFrame(() => {
                    if (inputEl.current)
                        inputEl.current.style.display = visible.current ? '' : 'none';

                    // Create the event
                    var event = new CustomEvent("input-hidden");
            
                    // Dispatch/Trigger/Fire the event
                    document.dispatchEvent(event);
                })
        }
    };

    let inputClasses = ['input-wrap'];

    if (input?.textStart)
        inputClasses.push('text-start')

    if (input.table)
        inputClasses.push('table-wrap')
    else if (!input?.input)
        inputClasses.push('label-wrap')
    else
        inputClasses.push('input-div')

    let elId = input.title ?? ''
    
    elId = elId.split(' ').join('-').split('[').join('').split(']').join('').split('&').join('');

    elId = elId.toLowerCase();

    if( typeof window !== 'undefined' && window.location.pathname === "/basic-information/") {
        return (
            visible.current &&
                <div
                    id={elId}
                    ref={inputEl}
                    style={{
                        display: visible.current ? '' : 'none'
                    }}
                    className={inputClasses.join(' ') + ' ' + elId}
                    data-input={input?.input?.name} >
                    <Input key={input.value} {...input} updateInputsInList={updateInputsInList} defaults={defaults} />
                    {input.table && <Table key={`table-${input?.input?.name}`} rows={input.table} updateInputsInList={updateInputsInList} setVisible={setVisible} defaults={defaults} />}
                </div>
        );
    }else{
        return (
            <div
                id={elId}
                ref={inputEl}
                style={{
                    display: visible.current ? '' : 'none'
                }}
                className={inputClasses.join(' ') + ' ' + elId}
                data-input={input?.input?.name} >
                <Input key={input.value} {...input} updateInputsInList={updateInputsInList} defaults={defaults} />
                {input.table && <Table key={`table-${input?.input?.name}`} rows={input.table} updateInputsInList={updateInputsInList} setVisible={setVisible} defaults={defaults} />}
            </div>
        );
    }
};

/**
 * Still not happy about this implementation, but I am struggling to think of a better version.
 * Maybe the solution is to rename list to something else? 
 * 
 * Anyway, rant over. Problem for another day...Sorry! Please ignore this weird code :) 
 * 
 * This function loops through the array and generates a table.
 * 
 * Note: This has little validation of the JSON, if it becomes a problem we can have a think.
 * 
 */

const Table = ({ rows, updateInputsInList, setVisible, defaults = true }) => {
    const tableRef = useRef(null);

    const updateVisibility = () => {
        window.requestAnimationFrame(() => {
            if (tableRef.current) {
                const hasVisibleRows = tableRef.current.querySelectorAll('input, select').length > 0;
                if (hasVisibleRows) {
                    tableRef.current.closest(".input-wrap.table-wrap").style.display = '';
                    setVisible(true);
                } else {
                    tableRef.current.closest(".input-wrap.table-wrap").style.display = 'none';
                    setVisible(false);
                }
            }
        });
    };

    const debouncedUpdate = _.debounce(updateVisibility, 200);  // debounce the function, 1000ms = 1 second

    useEffect(() => {
        // debouncedUpdate();  // call the debounced function immediately
        document.addEventListener('input-hidden', debouncedUpdate);

        // Clean up function
        return () => {
            document.removeEventListener('input-hidden', debouncedUpdate);
        };
    }, []);  // Only run at mount and unmount.

    if (typeof window != 'undefined')
        updateVisibility();

    return (
        <table ref={tableRef}>
            <tbody>
                {rows.map(({ type, inputs }, index) => {

                    //Hacky idea - loop through all the inputs against hideifnocontext
                    //If all are hidden then just return null

                    let hiddenEls = 0;

                    inputs.forEach(input => {
                        if (HideIf(input?.input?.hideifoptions) || !input.input)
                            hiddenEls++;
                    })

                    if (type !== 'header' && hiddenEls === inputs.length)
                        return null;

                    return (
                        <tr key={`row-${index}`}>
                            {inputs && inputs.map((input, idx) => (
                                <TableCell key={`cell-${idx}`} type={type}>
                                    <Input {...input} updateInputsInList={updateInputsInList} defaults={defaults} />
                                </TableCell>
                            ))}
                        </tr>
                    )
                })}
            </tbody>
        </table>
    );
};


const TableCell = ({
    type,
    children
}) => {
    if (type === 'header')
        return <th>{children}</th>

    return <td>{children}</td>
}

export default InputList;